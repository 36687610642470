import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private configUrl = '../assets/appConfig.properties.json';
  private configUrlProd = '../assets/appConfig-prod.properties.json';
  private configSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  getConfig(): Observable<any> {
    let production = environment.production;

    let subdominio = localStorage.getItem('subdominio');

    // if (subdominio == 'risecorp') {
    //   return this.http.get(this.configUrlRise);
    // } else {
    //   return this.http.get(this.configUrlProd);
    // }

    if (!production) {
      return this.http.get(this.configUrl);
    } else {
      return this.http.get(this.configUrlProd);
    }
  }

  initializeConfig(): Promise<void> {
    return new Promise((resolve) => {
      this.getConfig().subscribe((config) => {
        environment.urlBase = config.versaoSistema == '1' ? config.baseUrl : config.baseUrlv2; // Assumindo que 'urlBase' está no seu arquivo JSON.
        environment.urlS3 = config.urlS3;
        environment.urlMultiUpload = config.urlMultiUpload;
        environment.urlAuthRdStation = config.urlAuthRdStation; 
        environment.urlAplicacao = config.urlAplicacao; 
        this.configSubject.next(config);
        resolve();
      });
    });
  }
}
