<p-table #dt1 [value]="dataSource">
  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>
      </div>

      <div class="p-toolbar-group-right d-flex">
        <button type="button" class="btn btn-primary btn mr-2" (click)="add()" ngbTooltip="Adicionar"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th>Descrição</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{item.descricao}}</td>
      <td></td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Configuração" [(visible)]="modal" [style]="{width: '35vw'}">
  <form [formGroup]="form" class="row g-2 p-3">

    <div class="col-sm-12">
      <label for="lastname2">Descrição no sallesforce</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="descricao_sallesforce" />
    </div>

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">Departamento</label>
      <p-dropdown appendTo="body" [filter]="true" [options]="dropdownDepartamento" optionLabel="nome" class="p-inputtext-sm"
        formControlName="departamento">
      </p-dropdown>
    </div>

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">Campo personalizado Polo</label>
      <p-dropdown [filter]="true"  appendTo="body" [options]="dropdownCampoOpcao" optionLabel="label" class="p-inputtext-sm"
        formControlName="campo_personalizado_opcao">
      </p-dropdown>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Whatsapp polo</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="whatsapp_polo" />
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Whatsapp qg</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="whatsapp_qg" />
    </div>

  </form>

</p-dialog>