<p-tabView>
    <p-tabPanel *ngIf="direitoService.can('RELATORIO_TODOS_ATENDIMENTOS')" header="Atendimentos">
        <app-report-atendimento></app-report-atendimento>
    </p-tabPanel>

    <p-tabPanel *ngIf="direitoService.can('RELATORIO_CONTATOS')" header="Contatos">
        <app-report-contatos></app-report-contatos>
    </p-tabPanel>

    <p-tabPanel *ngIf="direitoService.can('RELATORIO_CONVERSAS')" header="Conversas">
        <app-report-conversa></app-report-conversa>
    </p-tabPanel>
</p-tabView>