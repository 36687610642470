<div class="col-lg-12" style="padding: 0px;">
    <div class="card">

        <p-toolbar>
            <div class="p-toolbar-group-left">
            </div>

            <div class="p-toolbar-group-right d-flex">

                <button type="button" class="btn btn-success waves-effect waves-light me-1"
                    [disabled]="(form.get('usuario.id').value == null && form.get('grupo.id').value == null)"
                    (click)="saveVinculo()"><i class="fa fa-check-circle"></i> Salvar</button>
            </div>
        </p-toolbar>
    </div>

</div>


<input class="form-check-input mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="user" checked
    [(ngModel)]="filterType" (change)="changeType()"> Usuário
<!-- <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="group"
    [(ngModel)]="filterType" (change)="changeType()"> Grupo -->

<form [formGroup]="form" class="mt-4">

    <div *ngIf="filterType == 'user'" class="col-md-12 mb-6" formGroupName="usuario" style="padding-bottom: 10px;">
        <span class="p-float-label">
            <p-dropdown [options]="dropDownUsuarios" class="p-inputtext-sm" [filter]="true" appendTo="body"
                formControlName="id" (onChange)="selecionaUsuario()">
            </p-dropdown>
            <label for="inputtext">Usuarios</label>
        </span>
    </div>

    <div *ngIf="filterType == 'group'" class="col-md-12 mb-6" formGroupName="grupo" style="padding-bottom: 10px;">
        <span class="p-float-label">
            <p-dropdown [options]="dropdownGrupos" class="p-inputtext-sm" [filter]="true" appendTo="body"
                formControlName="id" (onChange)="selecionaGrupo()">
            </p-dropdown>
            <label for="inputtext">Grupos</label>
        </span>
    </div>

</form>


<p-treeTable *ngIf="!loading" [value]="direitos" scrollHeight="250px" [scrollable]="true" [columns]="cols"
    selectionMode="checkbox" [(selection)]="selectedDireitos">
    <ng-template pTemplate="caption" dataKey="direito_id">
        <div *ngIf="(form.get('usuario.id').value != null || form.get('grupo.id').value != null) && direitos.length > 0 "
            class="p-d-flex">
            <p-treeTableHeaderCheckbox (click)="selectedDireito()"></p-treeTableHeaderCheckbox>
            <span class="p-ml-2"> Selecionar Todos</span>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <p-treeTableCheckbox
                    [disabled]="(form.get('usuario.id').value == null && form.get('grupo.id').value == null) || direitos.length < 1 "
                    [value]="rowNode" (click)="selectedDireito()" *ngIf="i == 0">
                </p-treeTableCheckbox>
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
</p-treeTable>

<div *ngIf="loading" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
        fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div>