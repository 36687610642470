<p-toast></p-toast>



<div class="layout-wrapper d-lg-flex">
  <div class="side-menu flex-lg-column me-lg-1" style="overflow: auto;">
    <!-- <perfect-scrollbar class="chat-message-list chat-group-list" data-simplebar> -->

    <!-- LOGO -->
    <div class="navbar-brand-box">
      <a routerLink="/" class="logo logo-dark">
        <span class="logo-sm">
          <img src="assets/images/logo.svg" alt="" height="30">
        </span>
      </a>

      <a routerLink="/" class="logo logo-light">
        <span class="logo-sm">
          <img src="assets/images/logo.svg" alt="" height="30">
        </span>
      </a>
    </div>
    <!-- end navbar-brand-box -->

    <!-- <perfect-scrollbar class="p-0 chat-message-list chat-group-list" data-simplebar> -->

    <!-- Start side-menu nav -->
    <div id="left-menu" class="flex-lg-column my-auto">
      <!-- <perfect-scrollbar class="p-0 chat-message-list chat-group-list" data-simplebar> -->
      <ul class="nav nav-pills side-menu-nav justify-content-center" role="tablist">

        <!-- <li class="nav-item" ngbTooltip="Aguardando atendimento" placement="right">
          <a class="nav-link" id="pills-setting-tab" (click)="showAbaChat(6)" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 6}">
            <i class="ri-time-fill" pBadge value="{{countNewMessagesNotStarted}}" severity="danger"></i>
          </a>
        </li> -->

        <li class="nav-item" ngbTooltip="Chats" placement="right">
          <a class="nav-link active" id="pills-chat-tab" (click)="showAbaChat(2)" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 2}">
            <i class="ri-message-3-line" pBadge value="{{countNewMessagesChat}}" severity="success"></i>
          </a>
        </li>


        <!-- <li class="nav-item" ngbTooltip="Finalizados" placement="right">
          <a class="nav-link active" id="pills-chat-tab" (click)="showAbaChat(14)" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 14}">
            <i class="pi pi-check" pBadge value="∞" severity="info" size="xlarge"></i>
          </a>
        </li> -->

        <li *ngIf="configPlataforma['chat_interno'] == true" class="nav-item" ngbTooltip="Interno" id="tabGroups"
          placement="right">
          <a class="nav-link pills-groups-tab" id="pills-groups-tab" (click)="showAbaChat(12)"
            href="javascript: void(0);" [ngClass]="{'active': activetab === 12}">
            <i class="ri-slack-line" pBadge value="{{countNewMessagesGroups}}" severity="warning"></i>
          </a>
        </li>

        <li *ngIf="direitoService.can('FUNIL_INSERIR')" class="nav-item" ngbTooltip="Funil de atendimento"
          placement="right">
          <a class="nav-link active" id="pills-kanban-tab" (click)="showAbaChat(9, 'kanban')"
            href="javascript: void(0);" [ngClass]="{'active': activetab === 9}">
            <i class="ri-filter-3-line"></i> </a>
        </li>

        <li class="nav-item" ngbTooltip="Instancias de whatsapp" placement="right">
          <a class="nav-link" id="pills-contacts-tab" (click)="showAbaChat(8, 'instance')" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 8}">
            <i class="ri-whatsapp-line"></i>
          </a>
        </li>



        <!-- <li class="nav-item" ngbTooltip="Agenda" placement="right">
          <a class="nav-link active" id="pills-calendar-tab" (click)="showAbaChat(13, 'agenda')"
            href="javascript: void(0);" [ngClass]="{'active': activetab === 13}">
            <i class="ri-calendar-check-line"></i> </a>
        </li> -->

        <li class="nav-item" ngbTooltip="Cadastros" placement="right">
          <a class="nav-link" id="pills-setting-tab" (click)="showAbaChat(7, 'operation')" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 7}">
            <i class="ri-pencil-line"></i>
          </a>
        </li>


        <!-- <li class="nav-item" ngbTooltip="Profile" placement="right">
            <a class="nav-link" id="pills-user-tab" (click)="showAbaChat(1, 'chat')" href="javascript: void(0);"
              [ngClass]="{'active': activetab === 1}">
              <i class="ri-user-2-line"></i>
            </a>
          </li> -->









        <!-- <li class="nav-item" ngbTooltip="Grupos" id="tabGroups" placement="right">
          <a class="nav-link pills-groups-tab" id="pills-groups-tab" (click)="showAbaChat(3)"
            href="javascript: void(0);" [ngClass]="{'active': activetab === 3}">
            <i class="ri-group-line" pBadge value="{{countNewMessagesGroups}}"></i>
          </a>
        </li> -->



        <li class="nav-item" ngbTooltip="Contatos" placement="right">
          <a class="nav-link" id="pills-contacts-tab" (click)="showAbaChat(4, 'chat')" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 4}">
            <i class="ri-contacts-line"></i>
          </a>
        </li>

        <li *ngIf="showModuloAgenda" class="nav-item" ngbTooltip="Agenda" placement="right">
          <a class="nav-link" id="pills-setting-tab" (click)="showAbaChat(13, 'schedule')" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 13}">
            <i *ngIf="countAgendaDia > 0" class="ri-calendar-line" pBadge value="{{countAgendaDia}}" severity="danger"></i>
            <i *ngIf="countAgendaDia == 0" class="ri-calendar-line"></i>
          </a>
        </li>



        <li *ngIf="direitoService.can('DASHBOARD')" class="nav-item" ngbTooltip="Dashboard" placement="right">
          <a class="nav-link active" id="pills-dashboard-tab" (click)="showAbaChat(11, 'dashboard')"
            href="javascript: void(0);" [ngClass]="{'active': activetab === 11}">
            <i class="ri-bar-chart-line"></i> </a>
        </li>

        <li *ngIf="direitoService.can('RELATORIOS')" class="nav-item" ngbTooltip="Relatórios" placement="right">
          <a class="nav-link active" id="pills-kanban-tab" (click)="showAbaChat(10, 'report')"
            href="javascript: void(0);" [ngClass]="{'active': activetab === 10}">
            <i class="ri-file-chart-line"></i> </a>
        </li>

        <li *ngIf="direitoService.can('CONFIGURACAO_INSERIR') || direitoService.can('DIREITO_USUARIO_INSERIR')"
          class="nav-item" ngbTooltip="Configurações">
          <a class="nav-link" id="pills-setting-tab" (click)="showAbaChat(5, 'configs')" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 5}">
            <i class="ri-settings-2-line"></i>
          </a>
        </li>




        <!-- <li class="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none" ngbDropdown placement="top">
            <a class="nav-link dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <img src="assets/images/users/avatar-1.jpg" alt="" class="profile-user rounded-circle">
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript:void(0);">{{'chat.profiledropdown.profile' | translate}} <i
                  class="ri-profile-line float-end text-muted"></i></a>
              <a class="dropdown-item" href="javascript:void(0);">{{'chat.profiledropdown.setting' | translate}} <i
                  class="ri-settings-3-line float-end text-muted"></i></a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">{{'chat.profiledropdown.logout' |
                translate}} <i class="ri-logout-circle-r-line float-end text-muted"></i></a>
            </div>
          </li> -->
      </ul>
      <!-- </perfect-scrollbar> -->
    </div>
    <!-- end side-menu nav -->

    <div class="flex-lg-column d-none d-lg-block">
      <ul class="nav side-menu-nav justify-content-center">

        <li *ngIf="false" class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left">
          <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ri-global-line"></i>
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a class="dropdown-item" *ngFor="let item of listLang" (click)="setLanguage(item.lang)"
              [ngClass]="{'active': lang === item.lang}" href="javascript: void(0);">
              <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
                class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link" id="light-dark" href="javascript:void(0);" ngbTooltip="Light Mode">
            <i class="ri-sun-line theme-mode-icon layout-mode-dark" (click)="changeMode('dark')"></i>
            <i class="ri-sun-line theme-mode-icon layout-mode-light" (click)="changeMode('light')"></i>
          </a>
        </li>

        <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left"
          style="z-index: 4;">
          <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/users/perfil.png" alt="" class="profile-user rounded-circle">
          </a>


          <div class="dropdown-menu2" id="teste-drop" ngbDropdownMenu
            style="background-color: linen; position: fixed !important; inset: auto auto 0px 0px; margin: 0px; transform: translate(0px, -58px);">
            <!-- <  a class="dropdown-item" href="javascript:void(0);">{{'chat.profiledropdown.profile' | translate}} <i
                  class="ri-profile-line float-end text-muted"></i></a>
              <a class="dropdown-item" href="javascript:void(0);">{{'chat.profiledropdown.setting' | translate}} <i
                  class="ri-settings-3-line float-end text-muted"></i></a> -->
            <!-- <div class="dropdown-divider"></div> -->
            <a style="color: red;" class="dropdown-item" href="javascript: void(0);" (click)="logout()">Sair do sistema
              <i class="ri-logout-circle-r-line float-end text-muted" style="color: red;"></i></a>
          </div>
        </li>
      </ul>
    </div>

    <!-- </perfect-scrollbar> -->

    <!-- </perfect-scrollbar> -->
    <!-- Side menu user -->
  </div>

  <div *ngIf="section == 'kanban'" class="main-content">
    <app-kanban-quadro></app-kanban-quadro>
  </div>

  <div *ngIf="section == 'operation'" class="main-content">
    <!-- <app-departamentos></app-departamentos> -->
    <app-cadastro-page></app-cadastro-page>
  </div>

  <div *ngIf="section == 'configs'" class="main-content">
    <!-- <app-departamentos></app-departamentos> -->
    <app-settings-page></app-settings-page>
  </div>

  <div *ngIf="section == 'instance'" class="main-content">
    <!-- <app-instancia-whatsapp></app-instancia-whatsapp> -->
    <app-instancia-list></app-instancia-list>
  </div>

  <div *ngIf="section == 'report'" class="main-content">
    <app-report-page></app-report-page>
  </div>

  <div *ngIf="section == 'dashboard'" class="main-content" style="overflow: auto;">
    <app-dashboard-page></app-dashboard-page>
  </div>

  <div *ngIf="section == 'schedule' && showModuloAgenda" class="main-content">
    <app-agenda></app-agenda>
  </div>


  <!-- start chat-leftsidebar -->
  <div *ngIf="section == 'chat'" class="chat-leftsidebar me-lg-1">

    <div class="tab-content">
      <!-- Start Profile tab-pane -->
      <div id="pills-user" role="tabpanel" aria-labelledby="pills-user-tab" *ngIf="activetab === 1">
        <app-profile></app-profile>
      </div>
      <!-- End Profile tab-pane-->

      <!-- Start chats tab-pane -->
      <div id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab"
        *ngIf="activetab === 2 || activetab === 6 || activetab == 12 || activetab == 14">
        <!-- Start chats content -->
        <div>



          <div class="px-2 pt-4">
            <div *ngIf="false">
              <h4 class="mb-4">


                <button *ngIf="activetab != 12" type="button" class="btn btn-primary btn-sm" style="margin-right: 5px;"
                  (click)="refreshListAtendimento('+')">+10</button>


                <button *ngIf="activetab != 12" type="button" class="btn btn-danger btn-sm "
                  (click)="refreshListAtendimento('-')" style="margin-right: 5px;">-10</button>

                <input *ngIf="activetab == 2" ngbTooltip="Número de chats" class="numeroConversa"
                  [(ngModel)]="rowsListAtendimento" (keypress)="rowsListChat($event)" type="number" pInputText />

                <button *ngIf="activetab == 12" type="button" class="btn btn-primary"
                  (click)="showUsersChatInterno()">Novo
                  chat</button>





                <!-- <p-overlayPanel #op (onHide)="closeFilterAvancado()">
                  <ng-template pTemplate>
                    <div class="row p-fluid">
                      <div class="mb-3 col-md-12">
                        <label for="inputtext">Nome</label>
                        <input id="firstname2" type="text" class="form-control" pInputText [(ngModel)]="nomeFilter">
                      </div>

                      <div class="mb-3 col-md-12">
                        <label for="inputtext">Celular/Whatsapp</label>
                        <p-inputMask mask="+55 (99) 99999-9999" [(ngModel)]="whatsappCelular" class="p-inputtext-sm">
                        </p-inputMask>
                      </div>

                      <div class="mb-3 col-md-12">
                        <label for="inputtext">Telefone/Whatsapp</label>
                        <p-inputMask mask="+55 (99) 9999-9999" [(ngModel)]="whatsappFixo" class="p-inputtext-sm">
                        </p-inputMask>
                      </div>

                      <div class="mb-3 col-md-12">
                        <label for="inputtext">Funil de atendimento</label>
                        <p-dropdown placeholder="Selecione um funil de atendimento" [(ngModel)]="funilFilter"
                          class="p-inputtext-sm" appendTo="body" [options]="dropdownFunilEtapaFilter"></p-dropdown>
                      </div>

                      <div class="mb-3 col-md-12">
                        <label for="inputtext">Mensagem</label>
                        <textarea rows="5" cols="5" pInputTextarea [(ngModel)]="mensagemFilter"
                          class="p-inputtext-sm"></textarea>
                      </div>

                      <div class="mb-3 col-md-12">
                        <label for="inputtext">Situação</label>
                        <p-dropdown [options]="dropdownStatusFilter" [(ngModel)]="statusFilter"></p-dropdown>
                      </div>

                      <div class="mb-3 col-md-12">
                        <label for="inputtext">Tag/Etiqueta</label>
                        <p-dropdown [options]="dropdownTags" filter="true" [(ngModel)]="tagFilter"></p-dropdown>
                      </div>

                      <div class="mb-3 col-md-12">
                        <label for="inputtext">Atendente</label>
                        <p-dropdown [options]="dropdownUsuarioFilter" filter="true" [(ngModel)]="userFilter">
                        </p-dropdown>
                      </div>

                      <div class="mb-3 col-md-12">
                        <button type="button" class="btn btn-success btn-sm " (click)="filterAvancado()"><i
                            class="pi pi-search"></i></button>

                        <button type="button" class="btn btn-danger btn-sm " (click)="clearFilterAvancado()"><i
                            class="pi pi-refresh"></i></button>
                      </div>
                    </div>
                  </ng-template>
                </p-overlayPanel> -->


              </h4>

            </div>

            <div class="search-box chat-search-box d-flex">
              <div class="input-group rounded-3">
                <!-- <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                  <i class="ri-search-line search-icon font-size-18"></i>
                </span> -->
                <!-- <input type="text" class="form-control bg-light" placeholder="Pesquisar..."
                  aria-label="Search messages or users" aria-describedby="basic-addon1" (keyup)="ContactSearch()"
                  id="searchContact" autocomplete="off"> -->
                <span class="input-group-text text-muted bg-light btn-nova-conversa" (click)="showModalNovaConversa()"
                  title="Nova conversa" id="basic-addon1">
                  <i class="pi pi-user-plus font-size-18"></i>
                </span>
                <!-- <span class="input-group-text text-muted bg-light btn-nova-conversa" (click)="showConversas()"
                  title="Mostrar mais conversas" id="basic-addon1">
                  <i class="pi pi-user-plus font-size-18"></i>
                </span> -->
              </div>
            </div>

            <div *ngIf="exibir_filtro_avancado == true" class="row d-flex" style="font-size: 12px;">
              <div class="col-md-12 ml-1 mt-1">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="customCheck2" [(ngModel)]="semDepartamentoFilter">
                  <label class="form-check-label" for="customCheck2">Filtrar quem não esta delegado a um
                    departamento</label>
                </div>
              </div>

              <div class="col-md-12 ml-1">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="customCheck2" [(ngModel)]="semRespostaFilter">
                  <label class="form-check-label" for="customCheck2">Filtrar contatos sem resposta</label>
                </div>
              </div>

              <div class="col-md-6">
                <label for="nome">Nome</label>
                <input id="nome" type="text" class="form-control input-filtro p-inputtext-sm" pInputText
                  [(ngModel)]="nomeFilter">
              </div>

              <div class="col-md-6">
                <label for="celular_whatsapp">Celular/Whatsapp</label>
                <input id="nome" type="text" class="form-control input-filtro p-inputtext-sm" pInputText
                  [(ngModel)]="whatsappCelular">
                <!-- <p-inputMask mask="+55 (99) 99999-9999" [(ngModel)]="whatsappCelular" class="p-inputtext-sm">
                </p-inputMask> -->
              </div>

              <div class="col-md-6">
                <label for="canal_atendimento">Situação</label>
                <p-multiSelect styleClass="multiselect-filtro input-filtro" [options]="dropdownSituacaoFiltro"
                  [(ngModel)]="situacaoFiltroSelected" selectedItemsLabel="{0} Itens selecionados"></p-multiSelect>
              </div>



              <div class="col-md-6">
                <label for="canal_atendimento">Aparelho</label>
                <p-dropdown appendTo="body" filter="true" placeholder="Aparelho" class="dropdown-filtro p-inputtext-sm"
                  [options]="dropdownCanalAtendimentoFiltroAvancado" [(ngModel)]="aparelhoFilter"></p-dropdown>
              </div>


              <div class="col-md-6">
                <label for="inputtext">Atendente</label>
                <p-dropdown [options]="dropdownUsuarioFilter" class="p-inputtext-sm dropdown-filtro" filter="true"
                  [(ngModel)]="userFilter">
                </p-dropdown>
              </div>

              <div class="col-md-6">
                <label for="departamento">Departamento</label>
                <p-dropdown [disabled]="semDepartamentoFilter" filter="true" [(ngModel)]="departamentoFilter"
                  [options]="dropdownDepartamentoFiltroAvancado" placeholder="Departamento"
                  class="dropdown-filtro p-inputtext-sm">
                </p-dropdown>
              </div>

              <!-- <div class="col-md-6">
                <label for="tel_whatsapp">Telefone/Whatsapp</label>
                <p-inputMask mask="+55 (99) 9999-9999" [(ngModel)]="whatsappFixo" class="p-inputtext-sm">
                </p-inputMask>
              </div> -->

              <div class="col-md-6">
                <label for="inputtext">Tag</label>
                <p-dropdown [options]="dropdownTags" class="dropdown-filtro p-inputtext-sm" filter="true"
                  [(ngModel)]="tagFilter">
                </p-dropdown>
              </div>


              <div class="col-md-6">
                <label for="inputtext">Etapa do funil</label>
                <p-dropdown placeholder="Etapa do funil" [(ngModel)]="funilFilter"
                  class="dropdown-filtro p-inputtext-sm" appendTo="body"
                  [options]="dropdownFunilEtapaFilter"></p-dropdown>
              </div>

              <div class="col-md-12">
                <label for="inputtext">Ordenar por</label>
                <p-dropdown placeholder="Etapa do funil" [(ngModel)]="ordemFIlter"
                  class="dropdown-filtro p-inputtext-sm" appendTo="body" [options]="dropdownOrdem"></p-dropdown>
              </div>

              <!-- <div class="col-md-6">
                <label for="status">Status</label>
                <p-dropdown [options]="dropdownStatusFilter" class="p-inputtext-sm" [(ngModel)]="statusFilter">
                </p-dropdown>
              </div> -->

              <div *ngIf="loadSearch == false" class="col-md-12" style="text-align: end;">
                <button type="button" label="Filtrar" class="btn btn-success btn-sm " style="margin: 5px;"
                  (click)="filterAvancado()">Filtrar<i class="pi pi-search"></i></button>



                <button label="Limpar filtro" type="button" class="btn btn-danger btn-sm"
                  (click)="clearFilterAvancado()">Limpar filtro<i class="pi pi-refresh"></i></button>

              </div>

              <div *ngIf="loadSearch == true" class="col-md-12" style="text-align: center; margin-top: 5px;">
                <p-progressSpinner [style]="{width: '20px', height: '20px'}" styleClass="custom-spinner" strokeWidth="8"
                  fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
              </div>
            </div>


            <div *ngIf="!exibir_filtro_avancado" (click)="openFilterAvancado()"
              class="justify-content-center align-items-center div-filtro">
              <span>
                Exibir filtros <i class="pi pi-chevron-down"></i>
              </span>
            </div>


            <div *ngIf="exibir_filtro_avancado" (click)="closeFilterAvancado()"
              class="justify-content-center align-items-center div-filtro">

              <span *ngIf="exibir_filtro_avancado">
                Ocultar filtros <i class="pi pi-chevron-up"></i>
              </span>
            </div>





          </div> <!-- .p-4 -->

          <!-- <div class="px-2 pt-1">
            <button *ngIf="activetab == 2" type="button" [ngClass]="classBtnTypeTicket(0)" (click)="typeTicket(0)"
              style="margin-left: 5px;">Todas</button>

            <button *ngIf="activetab == 2" type="button" [ngClass]="classBtnTypeTicket(1)" (click)="typeTicket(1)"
              style="margin-left: 5px;">Não lidas</button>

              <p-checkbox [binary]="true" inputId="binary"> Aguardando</p-checkbox>
          </div> -->

          <!-- <div class="d-flex justify-content-start">
            <div class="field-checkbox px-2 pt-1">
              <p-checkbox [binary]="true" [(ngModel)]="atendimentoFilter" inputId="binary"></p-checkbox>
              <label for="binary">Em antendimento</label>
            </div>
  
            <div class="field-checkbox px-2 pt-1">
              <p-checkbox [binary]="true" inputId="binary" [(ngModel)]="aguardandoFilter"></p-checkbox>
              <label for="binary">Aguardando</label>
            </div>
          </div> -->


          <!-- Start user status -->
          <div class="px-4 pb-4" dir="ltr">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <img src="assets/images/users/avatar-2.jpg" alt="user-img" class="img-fluid rounded-circle">
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.1' | translate}}</h5>
                  </a>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <img src="assets/images/users/avatar-4.jpg" alt="user-img" class="img-fluid rounded-circle">
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.2' | translate}}</h5>
                  </a>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <img src="assets/images/users/avatar-5.jpg" alt="user-img" class="img-fluid rounded-circle">
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.3' | translate}}</h5>
                  </a>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <img src="assets/images/users/avatar-6.jpg" alt="user-img" class="img-fluid rounded-circle">
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.4' | translate}}</h5>
                  </a>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                        T
                      </span>
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.5' | translate}}</h5>
                  </a>
                </div>
              </ng-template>

            </owl-carousel-o>

            <!-- end user status carousel -->
          </div>
          <!-- end user status -->

          <!-- Start chat-message-list -->
          <div class="px-2" [ngStyle]="styleScrollChats()">

            <div *ngIf="loadChats" class="text-center">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>

            <!-- <h5 *ngIf="!loadChats && activetab == 2" class="mb-3 px-3 font-size-16">{{showLabelStatus()}}</h5> -->
            <h5 *ngIf="!loadChats && activetab == 6" class="mb-3 px-3 font-size-16">Aguardando atendimento</h5>
            <h5 *ngIf="!activetab == 3" class="mb-3 px-3 font-size-16">Grupos</h5>

            <!-- <div>
              <button type="button" class="btn btn-danger btn-sm " (click)="refreshListAtendimento('-')">Aguardando</button>
              <button type="button" class="btn btn-success btn-sm " (click)="refreshListAtendimento('-')">Em andamento</button>
            </div> -->

            <perfect-scrollbar *ngIf="!loadChats && activetab == 2" (psYReachEnd)="onScrollEnd()"
              [config]="{ suppressScrollX: true }" class="chat-message-list">
              <ul class="list-unstyled chat-list chat-user-list">

                <li *ngFor="let user of chat" id="li-chat" [ngClass]="{'typing': user.isTyping}"
                  (click)="showChat($event,user.id)"
                  style="margin-right: 5px;     border-bottom: 1px solid rgba(0, 0, 0, 0.1)">
                  <a href="javascript:void(0);"
                    [ngClass]="user.countNewMessage > 0 || user.notRead ? 'bar-right' : 'not-bar-right'">
                    <div class="d-block">
                      <div class="d-flex">
                        <div class="chat-user-img online align-self-center me-3 ms-0"
                          [ngClass]="{'online': user.status === 'online','away': user.status === 'away'}">
                          <img *ngIf="user.profilePicture" src="{{user.profilePicture}}"
                            class="rounded-circle avatar-xs" alt="">
                          <div class="avatar-xs" *ngIf="!user.profilePicture">
                            <span *ngIf="user.name != null"
                              class="avatar-title rounded-circle bg-soft-primary text-primary">
                              {{(user.name | translate).charAt(0)}}
                            </span>
                            <span *ngIf="user.name == null"
                              class="avatar-title rounded-circle bg-soft-primary text-primary">
                              {{user.name}}
                            </span>
                          </div>
                          <span *ngIf="user.status" class="user-status"></span>
                        </div>

                        <div class="flex-grow-1 overflow-hidden">

                          <div class="row">
                            <div class="col-md-8">
                              <h5 class="text-truncate font-size-15 mb-1">
                                {{user.name | translate}}
                              </h5>
                            </div>

                            <div class="col-md-4 text-end">
                              <span *ngIf="user.situacao == 0"
                                class="badge badge-soft-danger me-1 rounded-pill">Aguardando</span>
                              <span *ngIf="user.situacao == 1" class="badge badge-soft-success me-1 rounded-pill"
                                style="color: #4c8677;">Em andamento</span>
                              <span *ngIf="user.situacao == 2"
                                class="badge badge-soft-primary me-1 rounded-pill">Finalizado</span>
                            </div>

                          </div>

                          <h5 *ngIf="false" class="text-truncate font-size-15 mb-1">{{user.name | translate}}
                            <i *ngIf="user.situacao == '0'" ngbTooltip="Atendimento em andamento"
                              class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-success"
                              style="margin-right: 10px;">Aberto</i>

                            <i *ngIf="user.situacao == '1'" ngbTooltip="Aguardando atendimento"
                              class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-danger"
                              style="margin-right: 10px;">Em andamento</i>

                            <i *ngIf="user.situacao == '2'" ngbTooltip="Finalizado"
                              class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-primary"
                              style="margin-right: 10px;">Finalizado</i>

                            <i *ngIf="user.empresa_fechada == true"
                              ngbTooltip="Atendimento recebido fora do horário de funcionamento da empresa"
                              class="ri-timer-line font-size-20 d-inline-block ms-1 mr-4 text-info"
                              style="margin-right: 10px;"></i>
                          </h5>


                          <p *ngIf="user.isTyping" class="chat-user-message text-truncate mb-0">typing
                            <span class="animate-typing">
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                            </span>
                          </p>

                          <div class="row">
                            <div class="col-md-5">

                              <div class="ellipsis">

                                <i *ngIf="user.typeLastMessage == 'text'" class="pi pi-envelope font-size-10"></i>
                                <small *ngIf="user.typeLastMessage == 'text'">
                                  {{user.lastMessage}}</small>


                                <i *ngIf="user.typeLastMessage == 'contact'" class="pi pi-user font-size-10"></i>
                                <small *ngIf="user.typeLastMessage == 'contact'" style="font-style: italic;">
                                  Contato</small>

                                <i *ngIf="user.typeLastMessage == 'location'" class="pi pi-map-marker font-size-10"></i>
                                <small *ngIf="user.typeLastMessage == 'location'" style="font-style: italic;">
                                  Localização</small>

                                <i *ngIf="user.typeLastMessage == 'image'" class="pi pi-image font-size-10"></i> <small
                                  *ngIf="user.typeLastMessage == 'image'" style="font-style: italic;"> Imagem</small>

                                <i *ngIf="user.typeLastMessage == 'video'" class="pi pi-video font-size-10"></i> <small
                                  *ngIf="user.typeLastMessage == 'video'" style="font-style: italic;"> Video</small>

                                <i *ngIf="user.typeLastMessage == 'audio'"
                                  class="pi pi-microphone font-size-10"></i><small
                                  *ngIf="user.typeLastMessage == 'audio'" style="font-style: italic;"> Audio</small>

                                <i *ngIf="user.typeLastMessage == 'document'" class="pi pi-file font-size-10"></i>
                                <small *ngIf="user.typeLastMessage == 'document'" style="font-style: italic;">
                                  Documento</small>
                              </div>




                            </div>

                            <!-- <div class="col-md-4">
                              <div *ngIf="user.departamento"
                                class="text-start departamento ellipsis-departamento font-size-10" title="Departamento">
                                <i class="pi pi-building font-size-10 ic"></i>
                                <small>{{user.departamento}}</small>
                              </div>

                            </div> -->


                            <div class="col-md-7 text-end d-flex justify-content-end align-items-center">


                              <span class="font-size-10" style="margin-right: 10px;">{{user.time_exibir |
                                translate}}</span>

                              <i id="infoSemDepartamento" *ngIf="user.departamento" pTooltip=" {{user.departamento}}"
                                class="pi pi-building font-size-10" style="color: blue; margin-left: 10px;"></i>

                              <i id="infoDepartamento" *ngIf="!user.departamento" pTooltip="Não definido"
                                class="pi pi-building font-size-10" style="color: red; margin-left: 10px;"></i>

                              <i id="infoAtendente" *ngIf="user.atendente" pTooltip=" {{user.atendente}}"
                                class="pi pi-user font-size-10" style="color: blue; margin-left: 10px;"></i>

                              <i id="infoSemAtendente" *ngIf="!user.atendente" pTooltip="Não definido"
                                class="pi pi-user font-size-10" style="color: red; margin-left: 10px;"></i>
                            </div>
                          </div>

                          <div *ngIf="false" class="d-flex justify-content-between">
                            <div class="ellipsis">
                              <i *ngIf="user.typeLastMessage == 'text'" class="pi pi-envelope font-size-10"></i> <small>
                                {{user.lastMessage}}</small>

                              <i *ngIf="user.typeLastMessage == 'contact'" class="pi pi-map-user font-size-10"></i>
                              <small *ngIf="user.typeLastMessage == 'contact'" style="font-style: italic;">
                                Contato</small>

                              <i *ngIf="user.typeLastMessage == 'location'" class="pi pi-map-marker font-size-10"></i>
                              <small *ngIf="user.typeLastMessage == 'location'" style="font-style: italic;">
                                Localização</small>

                              <i *ngIf="user.typeLastMessage == 'image'" class="pi pi-image font-size-10"></i> <small
                                *ngIf="user.typeLastMessage == 'image'" style="font-style: italic;"> Imagem</small>

                              <i *ngIf="user.typeLastMessage == 'video'" class="pi pi-video font-size-10"></i> <small
                                *ngIf="user.typeLastMessage == 'video'" style="font-style: italic;"> Video</small>

                              <i *ngIf="user.typeLastMessage == 'audio'"
                                class="pi pi-microphone font-size-10"></i><small *ngIf="user.typeLastMessage == 'audio'"
                                style="font-style: italic;"> Audio</small>

                              <i *ngIf="user.typeLastMessage == 'document'" class="pi pi-file font-size-10"></i> <small
                                *ngIf="user.typeLastMessage == 'document'" style="font-style: italic;">
                                Documento</small>
                            </div>



                            <div *ngIf="user.departamento" class="departamento ellipsis-departamento font-size-10"
                              title="Departamento">
                              <i class="pi pi-building font-size-10 ic"></i>
                              <small>{{user.departamento}}</small>
                            </div>

                            <div>
                              <i *ngIf="user.atendente" pTooltip=" {{user.atendente}}" class="pi pi-user font-size-10"
                                style="color: blue;"></i> <small>
                              </small>

                              <i *ngIf="!user.atendente" pTooltip="Não definido" class="pi pi-user font-size-10"
                                style="color: red;"></i> <small>
                              </small>
                            </div>


                          </div>



                          <!-- <div *ngIf="visualizarTodosChat" style="display: table-caption;">
                          <small
                            style="font-style: italic; color: chocolate; margin-right: 10px;"><b>#{{user.atendente}}</b></small>


                          <i class="pi pi-building font-size-10" style="color: #4d8ef3fa"></i><small
                            style="color: #4d8ef3fa" class="font-size-10">{{user.departamento}}</small>
                        </div> -->



                        </div>

                        <!-- <div *ngIf="user.situacao == 0" class="font-size-11">Aguardando</div>
                        <div *ngIf="user.situacao == 1" class="font-size-11">Andamento</div>
                        <div *ngIf="user.situacao == 2" class="font-size-11">Finalizado</div> -->
                        <!-- <div *ngIf="user.situacao == 2" class="font-size-11">Finalizado {{user.data_finalizado_format |
                          translate}}</div> -->

                        <div class="unread-message">
                          <span class="badge badge-soft-danger rounded-pill">{{user.unRead}}</span>
                        </div>
                      </div>
                      <div class="dep-aten">

                        <!-- <div *ngIf="user.departamento" class="departamento" title="Departamento">
                          <i class="pi pi-building font-size-10 ic"></i>
                          <small>{{user.departamento}}</small>
                        </div> -->
                        <!-- <div *ngIf="user.atendente" class="atendente" title="Atendente">
                          <small>
                            {{user.atendente}}
                          </small>
                        </div> -->
                      </div>
                      <!-- {{user.countNewMessage}} -->
                      <span *ngIf="(user.countNewMessage > 0 && user.countNewMessage <= 9) && !user.notRead"
                        class="bar-right-number">{{user.countNewMessage}}</span>
                      <span *ngIf="(user.countNewMessage > 0 && user.countNewMessage > 9) && !user.notRead"
                        class="bar-right-number">9+</span>
                      <span *ngIf="user.notRead" class="bar-right-number"></span>
                    </div>
                    <!-- <hr> -->
                  </a>
                </li>

              </ul>
            </perfect-scrollbar>

            <perfect-scrollbar *ngIf="!loadChats && activetab == 6" class="chat-message-list">
              <ul class="list-unstyled chat-list chat-user-list">

                <li *ngFor="let user of chatNotStarted" [ngClass]="{'typing': user.isTyping}"
                  (click)="showChat($event,user.id)">
                  <a href="javascript:void(0);" [ngClass]="{'bar-right': user.newMessage}">

                    <div class="d-block">
                      <div class="d-flex">
                        <div class="chat-user-img online align-self-center me-3 ms-0" [ngClass]="{'online': user.status === 'online',
                  'away': user.status === 'away'}">
                          <img *ngIf="user.profilePicture" src="{{user.profilePicture}}"
                            class="rounded-circle avatar-xs" alt="">
                          <div class="avatar-xs" *ngIf="!user.profilePicture">
                            <span *ngIf="user.name != null"
                              class="avatar-title rounded-circle bg-soft-primary text-primary">
                              {{(user.name | translate).charAt(0)}}
                            </span>
                            <span *ngIf="user.name == null"
                              class="avatar-title rounded-circle bg-soft-primary text-primary">
                              {{user.name}}
                            </span>
                          </div>
                          <span *ngIf="user.status" class="user-status"></span>
                        </div>

                        <div class="flex-grow-1 overflow-hidden">

                          <h5 class="text-truncate font-size-15 mb-1">{{user.name | translate}}



                            <i *ngIf="user.situacao == '1'" ngbTooltip="Atendimento em andamento"
                              class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-success"
                              style="margin-right: 10px;"></i>

                            <i *ngIf="user.situacao == '0'" ngbTooltip="Aguardando atendimento"
                              class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-danger"
                              style="margin-right: 10px;"></i>

                            <i *ngIf="user.empresa_fechada == true"
                              ngbTooltip="Atendimento recebido fora do horário de funcionamento da empresa"
                              class="ri-timer-line font-size-20 d-inline-block ms-1 mr-4 text-info"
                              style="margin-right: 10px;"></i>
                          </h5>


                          <p *ngIf="user.isTyping" class="chat-user-message text-truncate mb-0">typing<span
                              class="animate-typing">
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                            </span></p>

                          <div *ngIf="user.typeLastMessage == 'text'" class="d-flex justify-content-between">
                            <div class="ellipsis">
                              <i class="pi pi-envelope font-size-10"></i> <small>
                                {{user.lastMessage}}</small>
                            </div>
                          </div>

                          <i *ngIf="user.typeLastMessage == 'contact'" class="pi pi-map-user font-size-10"></i>
                          <small *ngIf="user.typeLastMessage == 'contact'" style="font-style: italic;">
                            Contato</small>

                          <i *ngIf="user.typeLastMessage == 'location'" class="pi pi-map-marker font-size-10"></i>
                          <small *ngIf="user.typeLastMessage == 'location'" style="font-style: italic;">
                            Localização</small>

                          <i *ngIf="user.typeLastMessage == 'image'" class="pi pi-image font-size-10"></i> <small
                            *ngIf="user.typeLastMessage == 'image'" style="font-style: italic;"> Imagem</small>

                          <i *ngIf="user.typeLastMessage == 'video'" class="pi pi-video font-size-10"></i> <small
                            *ngIf="user.typeLastMessage == 'video'" style="font-style: italic;"> Video</small>

                          <i *ngIf="user.typeLastMessage == 'audio'" class="pi pi-microphone font-size-10"></i><small
                            *ngIf="user.typeLastMessage == 'audio'" style="font-style: italic;"> Audio</small>

                          <i *ngIf="user.typeLastMessage == 'document'" class="pi pi-file font-size-10"></i> <small
                            *ngIf="user.typeLastMessage == 'document'" style="font-style: italic;"> Documento</small>

                          <!-- <div *ngIf="visualizarTodosChat" style="display: table-caption;">
                          <small
                            style="font-style: italic; color: chocolate; margin-right: 10px;"><b>#{{user.atendente}}</b></small>


                          <i class="pi pi-building font-size-10" style="color: #4d8ef3fa"></i><small
                            style="color: #4d8ef3fa" class="font-size-10">{{user.departamento}}</small>
                        </div> -->



                        </div>

                        <div *ngIf="user.situacao != 2" class="font-size-11">{{user.time | translate}}</div>
                        <div *ngIf="user.situacao == 2" class="font-size-11">Finalizado {{user.data_finalizado_format |
                          translate}}</div>

                        <div class="unread-message">
                          <span class="badge badge-soft-danger rounded-pill">{{user.unRead}}</span>
                        </div>
                      </div>
                      <div *ngIf="visualizarTodosChat" class="dep-aten">
                        <div *ngIf="user.departamento" class="departamento" title="Departamento">
                          <i class="pi pi-building font-size-10 ic"></i>
                          <small>{{user.departamento}}</small>
                        </div>
                        <div *ngIf="user.atendente" class="atendente" title="Atendente">
                          <small>
                            {{user.atendente}}
                          </small>
                        </div>
                      </div>
                      <span *ngIf="user.countNewMessage > 0" class="bar-right-number">{{user.countNewMessage}}</span>
                    </div>

                  </a>
                </li>
              </ul>
            </perfect-scrollbar>

            <perfect-scrollbar *ngIf="!loadChats && activetab == 14" class="chat-message-list">
              <ul class="list-unstyled chat-list chat-user-list">

                <li *ngFor="let user of conversasFinalizadas" [ngClass]="{'typing': user.isTyping}"
                  (click)="showChat($event,user.id)">
                  <a href="javascript:void(0);" [ngClass]="{'bar-right': user.newMessage}">

                    <div class="d-block">
                      <div class="d-flex">
                        <div class="chat-user-img online align-self-center me-3 ms-0" [ngClass]="{'online': user.status === 'online',
                  'away': user.status === 'away'}">
                          <img *ngIf="user.profilePicture" src="{{user.profilePicture}}"
                            class="rounded-circle avatar-xs" alt="">
                          <div class="avatar-xs" *ngIf="!user.profilePicture">
                            <span *ngIf="user.name != null"
                              class="avatar-title rounded-circle bg-soft-primary text-primary">
                              {{(user.name | translate).charAt(0)}}
                            </span>
                            <span *ngIf="user.name == null"
                              class="avatar-title rounded-circle bg-soft-primary text-primary">
                              {{user.name}}
                            </span>
                          </div>
                          <span *ngIf="user.status" class="user-status"></span>
                        </div>

                        <div class="flex-grow-1 overflow-hidden">

                          <h5 class="text-truncate font-size-15 mb-1">{{user.name | translate}}



                            <i *ngIf="user.situacao == '1'" ngbTooltip="Atendimento em andamento"
                              class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-success"
                              style="margin-right: 10px;"></i>

                            <i *ngIf="user.situacao == '0'" ngbTooltip="Aguardando atendimento"
                              class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-danger"
                              style="margin-right: 10px;"></i>

                            <i *ngIf="user.empresa_fechada == true"
                              ngbTooltip="Atendimento recebido fora do horário de funcionamento da empresa"
                              class="ri-timer-line font-size-20 d-inline-block ms-1 mr-4 text-info"
                              style="margin-right: 10px;"></i>
                          </h5>


                          <p *ngIf="user.isTyping" class="chat-user-message text-truncate mb-0">typing<span
                              class="animate-typing">
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                            </span></p>

                          <div *ngIf="user.typeLastMessage == 'text'" class="d-flex justify-content-between">
                            <div class="ellipsis">
                              <i class="pi pi-envelope font-size-10"></i> <small>
                                {{user.lastMessage}}</small>
                            </div>
                          </div>

                          <i *ngIf="user.typeLastMessage == 'contact'" class="pi pi-map-user font-size-10"></i>
                          <small *ngIf="user.typeLastMessage == 'contact'" style="font-style: italic;">
                            Contato</small>

                          <i *ngIf="user.typeLastMessage == 'location'" class="pi pi-map-marker font-size-10"></i>
                          <small *ngIf="user.typeLastMessage == 'location'" style="font-style: italic;">
                            Localização</small>

                          <i *ngIf="user.typeLastMessage == 'image'" class="pi pi-image font-size-10"></i> <small
                            *ngIf="user.typeLastMessage == 'image'" style="font-style: italic;"> Imagem</small>

                          <i *ngIf="user.typeLastMessage == 'video'" class="pi pi-video font-size-10"></i> <small
                            *ngIf="user.typeLastMessage == 'video'" style="font-style: italic;"> Video</small>

                          <i *ngIf="user.typeLastMessage == 'audio'" class="pi pi-microphone font-size-10"></i><small
                            *ngIf="user.typeLastMessage == 'audio'" style="font-style: italic;"> Audio</small>

                          <i *ngIf="user.typeLastMessage == 'document'" class="pi pi-file font-size-10"></i> <small
                            *ngIf="user.typeLastMessage == 'document'" style="font-style: italic;"> Documento</small>

                          <!-- <div *ngIf="visualizarTodosChat" style="display: table-caption;">
                          <small
                            style="font-style: italic; color: chocolate; margin-right: 10px;"><b>#{{user.atendente}}</b></small>


                          <i class="pi pi-building font-size-10" style="color: #4d8ef3fa"></i><small
                            style="color: #4d8ef3fa" class="font-size-10">{{user.departamento}}</small>
                        </div> -->



                        </div>

                        <div *ngIf="user.situacao != 2" class="font-size-11">{{user.time | translate}}</div>
                        <div *ngIf="user.situacao == 2" class="font-size-11">Finalizado {{user.data_finalizado_format |
                          translate}}</div>

                        <div class="unread-message">
                          <span class="badge badge-soft-danger rounded-pill">{{user.unRead}}</span>
                        </div>
                      </div>
                      <div *ngIf="visualizarTodosChat" class="dep-aten">
                        <div *ngIf="user.departamento" class="departamento" title="Departamento">
                          <i class="pi pi-building font-size-10 ic"></i>
                          <small>{{user.departamento}}</small>
                        </div>
                        <div *ngIf="user.atendente" class="atendente" title="Atendente">
                          <small>
                            {{user.atendente}}
                          </small>
                        </div>
                      </div>
                      <span *ngIf="user.countNewMessage > 0 && user.countNewMessage <= 9"
                        class="bar-right-number">{{user.countNewMessage}}</span>
                      <span *ngIf="user.countNewMessage > 0 && user.countNewMessage > 9"
                        class="bar-right-number">9+</span>
                    </div>

                  </a>
                </li>
              </ul>
            </perfect-scrollbar>

            <perfect-scrollbar *ngIf="!loadChats && activetab == 12" class="chat-message-list">
              <ul class="list-unstyled chat-list chat-user-list">

                <li *ngFor="let user of chatInternoList" [ngClass]="{'typing': user.isTyping}"
                  (click)="showChat($event,user.id)">
                  <a href="javascript:void(0);"
                    [ngStyle]="{'background-color': user.newMessage ? '#59fb95' : '', 'color': user.newMessage ? 'red' : 'black'}">
                    <div class="d-flex">
                      <div class="chat-user-img online align-self-center me-3 ms-0" [ngClass]="{'online': user.status === 'online',
                  'away': user.status === 'away'}">
                        <img *ngIf="user.profilePicture" src="{{user.profilePicture}}" class="rounded-circle avatar-xs"
                          alt="">
                        <div class="avatar-xs" *ngIf="!user.profilePicture">
                          <span *ngIf="user.name != null"
                            class="avatar-title rounded-circle bg-soft-primary text-primary">
                            {{(user.name | translate).charAt(0)}}
                          </span>
                          <span *ngIf="user.name == null"
                            class="avatar-title rounded-circle bg-soft-primary text-primary">
                            {{user.name}}
                          </span>
                        </div>
                        <span *ngIf="user.status" class="user-status"></span>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">

                        <h5 class="text-truncate font-size-15 mb-1">{{user.name | translate}}



                          <i *ngIf="user.situacao == '1'" ngbTooltip="Atendimento em andamento"
                            class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-success"
                            style="margin-right: 10px;"></i>

                          <i *ngIf="user.situacao == '0'" ngbTooltip="Aguardando atendimento"
                            class="ri-record-circle-fill font-size-10 d-inline-block ms-1 mr-4 text-danger"
                            style="margin-right: 10px;"></i>

                          <i *ngIf="user.empresa_fechada == true"
                            ngbTooltip="Atendimento recebido fora do horário de funcionamento da empresa"
                            class="ri-timer-line font-size-20 d-inline-block ms-1 mr-4 text-info"
                            style="margin-right: 10px;"></i>


                        </h5>


                        <p *ngIf="user.isTyping" class="chat-user-message text-truncate mb-0">Digitando...<span
                            class="animate-typing">
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                          </span></p>

                        <div *ngIf="user.typeLastMessage == 'text'" class="d-flex justify-content-between">
                          <div class="ellipsis">
                            <i class="pi pi-envelope font-size-10"></i> <small>
                              {{user.lastMessage}}</small>
                          </div>
                        </div>

                        <i *ngIf="user.typeLastMessage == 'contact'" class="pi pi-map-user font-size-10"></i>
                        <small *ngIf="user.typeLastMessage == 'contact'" style="font-style: italic;">
                          Contato</small>

                        <i *ngIf="user.typeLastMessage == 'location'" class="pi pi-map-marker font-size-10"></i> <small
                          *ngIf="user.typeLastMessage == 'location'" style="font-style: italic;"> Localização</small>

                        <i *ngIf="user.typeLastMessage == 'image'" class="pi pi-image font-size-10"></i> <small
                          *ngIf="user.typeLastMessage == 'image'" style="font-style: italic;"> Imagem</small>

                        <i *ngIf="user.typeLastMessage == 'video'" class="pi pi-video font-size-10"></i> <small
                          *ngIf="user.typeLastMessage == 'video'" style="font-style: italic;"> Video</small>

                        <i *ngIf="user.typeLastMessage == 'audio'" class="pi pi-microphone font-size-10"></i><small
                          *ngIf="user.typeLastMessage == 'audio'" style="font-style: italic;"> Audio</small>

                        <i *ngIf="user.typeLastMessage == 'document'" class="pi pi-file font-size-10"></i> <small
                          *ngIf="user.typeLastMessage == 'document'" style="font-style: italic;"> Documento</small>

                        <div *ngIf="visualizarTodosChat">
                          <small
                            style="font-style: italic; color: chocolate; margin-right: 10px;"><b>#{{user.atendente}}</b></small>

                          <!-- <i class="pi pi-whatsapp font-size-12"
                            style="color: #4d8ef3fa"></i><small style="color: #4d8ef3fa"><b> {{user.canal_atendimento}}</b></small> -->

                          <i class="pi pi-building font-size-10" style="color: #4d8ef3fa"></i><small
                            style="color: #4d8ef3fa" class="font-size-10">{{user.departamento}}</small>
                        </div>
                      </div>

                      <div *ngIf="user.situacao != 2" class="font-size-11">{{user.time | translate}}</div>
                      <div *ngIf="user.situacao == 2" class="font-size-11">Finalizado {{user.data_finalizado_format |
                        translate}}</div>

                      <div class="unread-message">
                        <span class="badge badge-soft-danger rounded-pill">{{user.unRead}}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </perfect-scrollbar>

            <div *ngIf="carregandoConversasScrool" class="button-float1 text-end"
              style="margin-top: -37px; margin-right: 30%;">
              <p-button label="Carregando aguarde..." styleClass="p-button-sm"></p-button>
            </div>


          </div>


          <!-- End chat-message-list -->
        </div>
        <!-- Start chats content -->
      </div>
      <!-- End chats tab-pane -->

      <!-- Start groups tab-pane -->
      <div id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab" *ngIf="activetab === 3">
        <!-- Start Groups content -->
        <div style="height: calc(100vh - 200px);">
          <div class="p-4">
            <div class="user-chat-nav float-end">

              <div ngbTooltip="Importar grupo">
                <!-- Button trigger modal -->
                <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0"
                  data-toggle="modal" data-target="#addgroup-exampleModal" (click)="openGroupModal(content)">
                  <i class="ri-group-line me-1"></i>
                </button>
              </div>
            </div>
            <h4 class="mb-4">Grupos</h4>

            <!-- Start add group Modal -->
            <ng-template #content let-modal>
              <div class="p-4 text-center">
                <p-progressSpinner *ngIf="loadingImportGroup" [style]="{width: '50px', height: '50px'}"
                  styleClass="custom-spinner" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s">
                </p-progressSpinner>
              </div>

              <div class="modal-content" *ngIf="!loadingImportGroup">



                <div class="modal-header">
                  <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
                    Importação de grupo</h5>
                  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                  </button>
                </div>


                <div class="modal-body p-4">
                  <div class="mb-4 p-fluid">
                    <label class="form-label" for="addgroupname-input">Nome do grupo</label>
                    <p-dropdown class="p-inputtext-sm" filter="true" [(ngModel)]="groupImport"
                      [options]="dropdownGroupsWhatsapp" placeholder="Selecione"></p-dropdown>

                    <!-- <input type="text" class="form-control" id="addgroupname-input"
                      placeholder="{{'chat.tabs.groups.modal.form.name.placeholder' | translate}}"> -->
                  </div>

                  <div class="mb-4 p-fluid">
                    <label class="form-label" for="addgroupname-input">Departamento</label>
                    <p-dropdown class="p-inputtext-sm" filter="true" [(ngModel)]="departamentoGroup"
                      [options]="dropdownDepartamentos" placeholder="Selecione" (onChange)="changeDepartamentoGrupo()">
                    </p-dropdown>
                  </div>

                  <div class="mb-4 p-fluid">
                    <label class="form-label" for="addgroupname-input">Usuarios</label>
                    <p-multiSelect [options]="dropDownUsuariosDepartamentoGrupo" [(ngModel)]="usuariosGroupParticipant"
                      optionLabel="label" class="p-inputtext-sm">
                    </p-multiSelect>
                  </div>
                </div>


                <div class="modal-footer">
                  <button type="button" class="btn btn-link" data-dismiss="modal"
                    (click)="modal.dismiss('Cross click')">Cancelar</button>
                  <button type="button" class="btn btn-primary" (click)="importGroup()">Importar grupo</button>
                </div>
              </div>
            </ng-template>
            <!-- End add group Modal -->

            <div class="search-box chat-search-box">
              <div class="input-group rounded-3">
                <button class="input-group-text text-muted bg-light pe-1 ps-3" type="button">
                  <i class="ri-search-line search-icon font-size-18"></i>
                </button>
                <input type="text" class="form-control bg-light"
                  placeholder="{{'chat.tabs.groups.search.placeholder' | translate}}" (keyup)="GroupSearch()"
                  id="searchGroup" autocomplete="off">
              </div>
            </div>

            <div *ngIf="loadChats" class="text-center" style="margin-top: 50px;">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>
            <!-- end search-box -->
          </div>

          <!-- Start chat-group-list -->
          <perfect-scrollbar *ngIf="!loadChats" class="p-4 chat-message-list chat-group-list" data-simplebar>
            <ul class="list-unstyled chat-list group-list">
              <li *ngFor="let item of chat" (click)="showChat($event,item.id)" id="chat-{{item.id}}">
                <a href="javascript:void(0);"
                  [ngStyle]="{'background-color': item.newMessage ? '#59fb95' : '', 'color': item.newMessage ? 'red' : 'black'}">
                  <div class="d-flex align-items-center">
                    <div class="chat-user-img me-3 ms-0">
                      <div class="avatar-xs">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                          {{(item.name | translate).charAt(0)}}
                        </span>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="text-truncate font-size-14 mb-0">#{{item.name | translate}}
                        <span class="badge badge-soft-danger rounded-pill float-end">{{item.unread}}</span>
                      </h5>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </perfect-scrollbar>
          <!-- End chat-group-list -->
        </div>
        <!-- End Groups content -->
      </div>
      <!-- End groups tab-pane -->

      <!-- Start contacts tab-pane -->
      <div id="pills-contacts" role="tabpanel" aria-labelledby="pills-contacts-tab" *ngIf="activetab === 4">
        <app-contacts (onSelect)="selectContact($event)"></app-contacts>
      </div>


      <!-- End contacts tab-pane -->

      <!-- Start settings tab-pane -->
      <div id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab" *ngIf="activetab === 5">
        <app-settings></app-settings>
      </div>
      <!-- End settings tab-pane -->
    </div>

  </div>

  <div *ngIf="section == 'chat' && activetab != 4" class="chat-welcome-section">
    <div class="row w-100 justify-content-center">
      <div class="col-xxl-5 col-md-7">
        <div class="p-4 text-center">
          <div class="avatar-xl mx-auto mb-4">
            <div class="avatar-title bg-secondary rounded-circle">
              <svg data-v-5e8ea5c2="" xmlns="http://www.w3.org/2000/svg" width="70px" height="65px" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-message-square">
                <path data-v-5e8ea5c2="" d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                </path>
              </svg>
            </div>
          </div>
          <h4 class="d-inline px-3 py-2 rounded-pill bg-secondary text-white fs-4"> Nenhuma conversa selecionada </h4>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="section == 'chat' && activetab != 4" class="load-messages d-none ">
    <div class="row w-100 justify-content-center">
      <div class="col-xxl-5 col-md-7">
        <div class="p-4 text-center">
          <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
          <!-- <h4 class="d-inline px-3 py-2 rounded-pill bg-secondary text-white fs-4"> Nenhuma conversa selecionada </h4> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Start User chat -->
  <div *ngIf="section == 'chat' && activetab != 4" class="user-chat w-100 d-none background-img" id="chat-room">

    <div class="d-lg-flex">

      <!-- start chat conversation section -->
      <div class="w-100 position-relative">
        <div class="p-3 p-lg-3 border-bottom">
          <div class="row align-items-center">
            <div class="col-sm-4 col-8">
              <div class="d-flex align-items-center">
                <div class="d-block d-lg-none me-2 ms-0">
                  <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"
                    (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
                </div>
                <div id="header-profile" class="me-3 ms-0">
                  <img *ngIf="userProfile" src="{{userProfile}}" class="rounded-circle avatar-xs" alt="">
                  <div class="avatar-xs" *ngIf="!userProfile">
                    <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                      {{(userName | translate).charAt(0)}}
                    </span>

                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden" style="margin-top: -10px;">
                  <h5 class="font-size-16 mb-0 text-truncate">

                    <div class="d-flex col-md-12 justify-content-start" style="overflow: auto">
                      <div style="margin-top: 5px; width: 0px;">

                        <a href="javascript:void(0);" class="text-reset user-profile-show username"
                          style="margin-right: 10px;" (click)="onChatInfoClicked(userInfo)">{{userName | translate}}
                        </a>

                        <!-- <span *ngIf="chatSelected?.tagsCliente">
                          <small *ngFor="let tag of chatSelected?.tagsCliente"
                            class="text-muted fw-normal font-10 badge badge-soft-danger me-1 rounded-pill tag">{{tag.descricao}}
                          </small>
                        </span> -->

                        <br />
                        <small style="font-size: 10.85px;;">{{chatSelected?.whatsapp_contato | celular}} </small>

                        <span ngbTooltip="{{chatSelected?.canal_atendimento}}"
                          class="text-muted fw-normal font-10 badge badge-soft-success me-1 rounded-pill canal-atendimento">
                          <i class="pi pi-whatsapp"></i>
                        </span>
                      </div>

                      <!-- <div style="margin-left: 10px;margin-top: 10px;">
                        <button type="button" class="btn btn-success btn-sm mr-2" (click)="showForm(item.id)"><i
                            class="pi pi-whatsapp"></i> Canal: <b>{{chatSelected?.canal_atendimento}}</b></button>
                      </div> -->

                      <!-- <div style="width: 170px;">
                        <p-dropdown *ngIf="chatSelected" class="p-inputtext-sm" appendTo="body" [options]="dropdownStatus" (onChange)="updateSituacaoAtendimento()" [(ngModel)]="chatSelected.situacao" ></p-dropdown>
                      </div> -->
                    </div>


                  </h5>


                </div>
              </div>
            </div>



            <div class="col-sm-8 col-4" style="margin-top: -10px;">
              <ul class="list-inline user-chat-nav text-end mb-0">



                <!-- <li *ngIf="(activetab == 2 || activetab  == 3) && statusFilter != 'FINALIZADO'"
                  class="list-inline-item">
                  <p-dropdown *ngIf="chatSelected" placeholder="Selecione um funil de atendimento"
                    [(ngModel)]="etapaFunilSelected" class="p-inputtext-sm" appendTo="body"
                    [options]="dropdownFunilEtapa" (onChange)="updateEtapaNegociacao()"></p-dropdown>
                </li> -->

                <li *ngIf="chatSelected != null" class="list-inline-item">
                  <label for="binary">Não lido </label>
                  <p-checkbox [(ngModel)]="chatSelected.notRead" [binary]="true" inputId="binary"
                    (onChange)="setNaoLido()"></p-checkbox>
                </li>

                <li *ngIf="chatSelected != null && chatSelected.situacao != 2" class="list-inline-item">
                  <button id="btn-finalizar" type="button" class="btn btn-danger btn-sm mr-2"
                    (click)="finalizarAtendimentoShow()"><i class="pi pi-check"></i> Finalizar</button>
                  <!-- <p-dropdown *ngIf="chatSelected" class="p-inputtext-sm" appendTo="body" [options]="dropdownStatus"
                    (onChange)="updateSituacaoAtendimento()" [(ngModel)]="chatSelected.situacao"></p-dropdown> -->
                </li>

                <li *ngIf="chatSelected != null" class="list-inline-item d-none d-lg-inline-block">
                  <button type="button" class="btn nav-btn user-profile-show" (click)="getFunils()" ngbTooltip="Funil">
                    <i class="ri-filter-3-line"></i>
                  </button>
                </li>

                <li *ngIf="chatSelected != null" class="list-inline-item d-none d-lg-inline-block">
                  <button type="button" class="btn nav-btn user-profile-show" (click)="getDialogos()"
                    ngbTooltip="Dialogos">
                    <i class="ri-chat-2-line"></i>
                  </button>
                </li>

                <li class="list-inline-item">
                  <div class="dropdown" ngbDropdown>
                    <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="ri-search-line"></i>
                    </button>
                    <div class="dropdown-menu p-0 dropdown-menu-right dropdown-menu-md" ngbDropdownMenu>
                      <div class="search-box p-2">
                        <input type="text" class="form-control bg-light border-0" placeholder="Pesquisar mensagem"
                          (keyup)="MessageSearch()" id="searchMessage">
                      </div>
                    </div>
                  </div>
                </li>

                <!-- <li *ngIf="(activetab == 2 || activetab  == 3) && statusFilter != 'FINALIZADO'"
                  class="list-inline-item d-none d-lg-inline-block">
                  <button type="button" class="btn nav-btn user-profile-show" (click)="showNotes()"
                    ngbTooltip="Anotações">
                    <i class="ri-file-line"></i>
                  </button>
                </li> -->

                <li class="list-inline-item d-none d-lg-inline-block">
                  <button type="button" class="btn nav-btn user-profile-show" id="btn-infochat"
                    (click)="onChatInfoClicked(userInfo)" ngbTooltip="Informações do contato">
                    <i class="ri-user-2-line"></i>
                  </button>
                </li>

                <li class="list-inline-item d-none d-lg-inline-block">
                  <button type="button" class="btn nav-btn user-profile-show" id="btn-historicoChat"
                    (click)="historicoChat()" ngbTooltip="Histórico de atendimento">
                    <i class="ri-git-commit-line"></i>
                  </button>
                </li>

                <!-- <li *ngIf="(activetab == 2 || activetab  == 3) && statusFilter != 'FINALIZADO'"
                  class="list-inline-item">
                  <div class="dropdown" ngbDropdown>
                    <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="ri-more-fill"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                      <a class="dropdown-item d-block d-lg-none user-profile-show" (click)="showUserProfile()"
                        href="javascript: void(0);">{{'chat.chatpanelheader.dropdown.viewprofile' | translate}} <i
                          class="ri-user-2-line float-end text-muted"></i></a>
                      <a class="dropdown-item" href="javascript:void(0);">{{'chat.chatpanelheader.dropdown.archive' |
                        translate}} <i class="ri-archive-line float-end text-muted"></i></a>
                      <a class="dropdown-item" href="javascript:void(0);">{{'chat.chatpanelheader.dropdown.muted' |
                        translate}} <i class="ri-volume-mute-line float-end text-muted"></i></a>
                      <a class="dropdown-item" href="javascript:void(0);"
                        (click)="deleteAllMessage($event)">{{'chat.chatpanelheader.dropdown.delete' | translate}}<i
                          class="ri-delete-bin-line float-end text-muted"></i></a>
                    </div>
                  </div>
                </li> -->
              </ul>
            </div>

            <div *ngIf="(activetab == 2)" class="col-sm-7 col-4" style="margin-top: -10px;">
              <ul class="list-inline user-chat-nav text-end mb-0">

                <li *ngIf="chatSelected != null && chatSelected.situacao == 2" class="list-inline-item">
                  <button id="btn-finalizar" type="button" class="btn btn-success btn-sm mr-2"
                    (click)="openConversa()"><i class="pi pi-check"></i> Abrir novamente</button>
                  <!-- <p-dropdown *ngIf="chatSelected" class="p-inputtext-sm" appendTo="body" [options]="dropdownStatus"
                    (onChange)="updateSituacaoAtendimento()" [(ngModel)]="chatSelected.situacao"></p-dropdown> -->
                </li>
              </ul>
            </div>

            <div *ngIf="activetab == 12" class="col-sm-7 col-4">
              <ul class="list-inline user-chat-nav text-end mb-0">
                <li class="list-inline-item">
                  <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;"
                    (click)="closeChat()">Fechar conversa</button>
                </li>
              </ul>

            </div>

            <!-- <div *ngIf="activetab == 12" class="col-sm-8 col-4">
              <ul class="list-inline user-chat-nav text-end mb-0">
                <li class="list-inline-item">
                  <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;"
                    (click)="closeChat()">Fechar conversa</button>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
        <!-- end chat user head -->
        <div id="users-chat" class="position-relative"
          style="background-image: url(https://4sells-storage.s3.sa-east-1.amazonaws.com/chatbg.png);">
          <!-- start chat conversation -->
          <ngx-simplebar class="chat-conversation p-3 p-lg-4" #scrollRef>
            <ul class="row list-unstyled mb-0" id="users-conversation">

              <!-- <div class="chat-day-title" *ngIf="data.isToday">
                <span class="title">Hoje</span>
              </div> -->

              <div *ngFor="let message of getDates()">

                <div class="chat-day-title">
                  <span class="title">{{ message }}</span>
                </div>

                <li class="chats" id="messages" *ngFor="let data of messagesAgrupada[message];let i =index;"
                  [ngClass]="{'right': data.align === 'right'}">



                  <div class="conversation-list p-d-grid">

                    <span
                      *ngIf="!data.excluida && data.origem == 'PLATAFORMA' && data.appSend == 0 && data?.name != chatSelected.name">
                      {{data.name}}</span>

                    <span *ngIf="!data.excluida && data.origem == 'PLATAFORMA' && data.appSend == 1"> Enviado fora da
                      plataforma</span>
                    <!-- <span *ngIf="data.origem == 'PLATAFORMA' && data.appSend == 0 && data.name == chatSelected.name">
                      Enviado fora da plataforma</span> -->

                    <div *ngIf="!data.observacao_interna && data.status_agendamento != 3" class="user-chat-content"
                      id="{{data.id}}">
                      <div class="ctext-wrap">
                        <div class="ctext-wrap-content" [ngClass]="{'msg-excluida': data.excluida == true}">
                          <div class="replymessage-block mb-0 d-flex align-items-start" *ngIf="data.replayName">
                            <div class="flex-grow-1">
                              <h5 class="conversation-name">{{data.replayName}}</h5>
                              <p class="mb-0">{{data.replaymsg | translate}}</p>
                            </div>
                            <div class="flex-shrink-0">
                              <button type="button" class="btn btn-sm btn-link mt-n2 me-n3 font-size-18">
                              </button>
                            </div>
                          </div>

                          <!-- <app-resposta-mensagem class="ellipsis" *ngIf="data.mensagem_resposta"
                            (onScroll)="scroll($event)" [response]="data.mensagem_resposta" [align]="data.align">
                          </app-resposta-mensagem> -->



                          <div [title]="mensagem_a_responder" class="d-flex ellipsis" *ngIf="data.mensagem_resposta">
                            <div class="barra-responder"></div>
                            <div class="ellipsis area-responder">
                              <div class="d-grid">
                                <small *ngIf="data.name !== nome_usuario" class="remetente">
                                  {{data.name}}
                                </small>
                                <small *ngIf="data.name == nome_usuario" class="remetente">
                                  Você
                                </small>

                                <small class="ellipsis text-responder">{{data.mensagem_resposta_conteudo}}</small>
                              </div>

                            </div>
                          </div>


                          <div *ngIf="data.isText" class="p-2 mb-2">
                            <div class="d-flex align-items-center">

                              <div class="d-flex-body">

                                <p-image *ngIf="data.url_campanha != null" src="{{data.url_campanha}}"
                                  previewImageSrc="{{data.url_campanha}}" alt="Image" width="250" [preview]="true">
                                </p-image>

                                <p *ngIf="data.descricao_campanha != null"><b
                                    style="font-size: 20px;">{{data.descricao_campanha}}</b></p>

                                <div class="text-start">


                                  <span class="text-chat" [innerHTML]="convertUrlsToLinks(data.conteudo)">

                                    <!-- {{data.conteudo | translate}} -->
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="data.isLink" class="p-2 mb-2">
                            <div class="d-flex align-items-center">

                              <div class="d-flex-body ellipsis">


                                <div class="text-start ellipsis">


                                  <span [ngStyle]="colorLabelMessageLeft(data)">

                                    <a target="_blank" [href]="data.conteudo">{{data.conteudo}}</a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div *ngIf="data.isContact" class="p-2 mb-2">
                            <div class="d-flex align-items-center">

                              <div class="d-flex align-items-start">
                                <div class="text-start "
                                  style="    border: 1px solid; border-radius: 9px;padding: 5px;margin: 5px; cursor: pointer;"
                                  ngbTooltip="Conversar">
                                  <i class="pi pi-whatsapp" style="font-size: 23px;"></i>
                                </div>
                                <div class="text-start">
                                  {{data.conteudo.displayName | translate}}
                                  <!-- <h5 *ngIf="!data.conteudo?.phones" [ngStyle]="colorLabelMessageLeft(data)"> {{data.conteudo?.phones[0] | translate}}
                                  </h5> -->
                                  <h5 *ngIf="data.conteudo?.phones">
                                    {{data.conteudo?.phones[0] | translate}}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="data.isContacts" class="p-2 mb-2">
                            <div class="d-flex align-items-center" *ngFor="let item of data.conteudo">

                              <div class="d-flex align-items-start">
                                <div class="text-start "
                                  style="    border: 1px solid; border-radius: 9px;padding: 5px;margin: 5px; cursor: pointer;"
                                  ngbTooltip="Conversar">
                                  <i class="pi pi-whatsapp" style="font-size: 23px;"></i>
                                </div>
                                <div class="text-start">
                                  {{item.displayName | translate}}
                                  <!-- <h5 *ngIf="!data.conteudo?.phones" [ngStyle]="colorLabelMessageLeft(data)"> {{data.conteudo?.phones[0] | translate}}
                                  </h5> -->
                                  <h5 *ngIf="item?.phones">
                                    {{item?.phones[0] | translate}}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="data.isLista" class="d-flex align-items-between">
                            <div id="formatted">
                              <button type="button" class="btn btn-primary font-size-16 btn-lg waves-effect">
                                lista de opções
                              </button>
                            </div>
                          </div>

                          <ul class="list-inline message-img mb-0" *ngIf="data.isImage">
                            <li class="list-inline-item message-img-list"
                              *ngFor="let item of data.imageContent;let x=index;">
                              <div>
                                <p-image src="{{item.conteudo}}" previewImageSrc="{{item.conteudo}}" alt="Image"
                                  width="250" [preview]="true"></p-image>
                              </div>
                              <div>
                                {{item.caption}}
                              </div>
                            </li>
                          </ul>

                          <div class="card p-2 mb-2" *ngIf="data.isfile">
                            <div class="d-flex align-items-center">
                              <div class="avatar-sm me-3 ms-0">
                                <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                  <i class="ri-file-text-fill"></i>
                                </div>
                              </div>
                              <div class="d-flex-body">
                                <div class="text-start">
                                  <a href="{{data.conteudo}}" target="_blank">
                                    <h5 class="font-size-14 mb-1">{{data.fileContent}}</h5>
                                  </a>
                                  <!-- <p class="text-muted font-size-13 mb-0">{{data.fileSize}}</p> -->
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="card p-2 mb-2" *ngIf="data.isAudio">
                            <div class="d-flex align-items-center">
                              <div class="d-flex-body">
                                <div class="text-start">
                                  <audio controls style="width: 250px; height: 50px;">
                                    <source src="{{data.conteudo}}" type="audio/ogg">
                                  </audio>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="card p-2 mb-2" *ngIf="data.isVideo">
                            <div class="d-flex align-items-center">
                              <div class="d-flex-body">
                                <div class="text-start">
                                  <video controls style="width: 250px; height: 250px;">
                                    <source src="{{data.conteudo}}" type="audio/ogg">
                                  </video>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="card p-2 mb-2" *ngIf="data.isLocation">
                            <a target="_blank" href="{{data.linkLocation}}">
                              <p-gmap [options]="data.locationOption" [style]="{'width':'250px','height':'250px'}">
                              </p-gmap>
                            </a>
                          </div>

                          <div class="d-flex justify-content-between">
                            <div *ngIf="data.waiting_message" class="mr-2">
                              <i class="ri-time-line align-middle"></i>
                              <!-- <span title="Envio da mensagem" class="align-middle dateTime">{{data.time |
                                translate}}</span> -->
                            </div>

                            <div *ngIf="data.waiting_message" class="mr-2">
                              <i class="ml-2 ri-download-line align-middle" style="cursor: pointer;"
                                pTooltip="Baixar novamente" (click)="downloadMedia(data)"></i>
                              <!-- <span title="Envio da mensagem" class="align-middle dateTime">{{data.time |
                                translate}}</span> -->
                            </div>

                            <div>
                              <i *ngIf="data.delivery == true && data.read == null && data.align == 'right'"
                                class="ri-check-double-line ml-2" style="margin-left: 10px;"></i>

                              <i *ngIf="data.read != null  && data.align == 'right'" class="ri-check-double-line"
                                style="margin-left: 10px; color: #53abd8; font-size: 17px;"></i>

                              <span title="Envio da mensagem" class="align-middle dateTime">{{data.time |
                                translate}}</span>
                            </div>



                          </div>


                        </div>

                        <div *ngIf="statusFilter != 'FINALIZADO'" class="dropdown align-self-start" ngbDropdown>
                          <a class="dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="response(data)">Responder <i
                                class="ri-reply-line float-end text-muted"></i></a>


                            <a id="btn-share" class="dropdown-item" href="javascript:void(0);"
                              (click)="openModalShared(data)">Compartilhar <i
                                class="ri-chat-forward-line float-end text-muted"></i></a>

                            <a *ngIf="direitoService.can('APAGAR_MENSAGEM')" id="btn-share" class="dropdown-item"
                              href="javascript:void(0);" (click)="deleteMessage(data)">Apagar <i
                                class="ri-chat-delete-line float-end text-muted"></i></a>
                          </div>
                        </div>

                      </div>

                      <div class="ctext-wrap" *ngIf="data.message2">
                        <div class="ctext-wrap-content">
                          <p class="mb-0">
                            {{data.message2 | translate}}
                          </p>
                          <p class="chat-time mb-0"><i class="ri-time-line align-middle"></i> <span
                              class="align-middle">{{data.time | translate}}</span></p>
                        </div>
                        <div class="dropdown align-self-start" ngbDropdown>
                          <a class="dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="response(data)">Responder <i
                                class="ri-reply-line float-end text-muted"></i></a>

                            <a class="dropdown-item" href="javascript:void(0);"
                              (click)="centerModal(centerDataModal)">Compartilhar <i
                                class="ri-chat-forward-line float-end text-muted"></i></a>

                          </div>
                        </div>
                      </div>

                      <!-- NOME DO REMETENTE -->
                      <!-- <div class="conversation-name"><span *ngIf="data.align === 'right'">{{senderName |
                          translate}}</span><span *ngIf="data.align !== 'right'">{{data.name | translate}}</span></div> -->
                    </div>

                    <div title="Anotação" *ngIf="data.observacao_interna == true && !data.excluida"
                      class="user-chat-content anotacao-interna text-start" id="{{data.id}}">
                      <div class="title">

                        <i class="ri-file-edit-fill font-size-10 ic"></i>
                        <small>Anotação</small>
                      </div>
                      <div *ngIf="data.isText" class="p-2 mb-2">
                        <div class="d-flex align-items-center">

                          <div class="d-flex-body">
                            <div class="text-start">
                              <h5 class="conteudo">
                                {{data.conteudo | translate}}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="conversation-name"><span *ngIf="data.align === 'right'">{{senderName |
                          translate}}</span><span *ngIf="data.align !== 'right'">{{data.name | translate}}</span></div>

                      <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger "
                        (click)="deleteMensagemSistema(data)"></button>

                    </div>


                    <div title="Agenda"
                      *ngIf="data.agendamento == true && !data.excluida && data.status_agendamento == 3"
                      class="user-chat-content agendamento text-start" id="{{data.id}}">
                      <div class="title">

                        <i class="ri-calendar-fill font-size-10 ic"></i>

                        <small>Agendamento {{data.data_hora_agenda}}</small>

                      </div>
                      <div *ngIf="data.isText" class="p-2 mb-2">
                        <div class="d-flex align-items-center">

                          <div class="d-flex-body">
                            <div class="text-start">
                              <h5 class="conteudo">
                                {{data.conteudo | translate}}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger "
                        (click)="deleteMensagemSistema(data)"></button>

                    </div>

                    <div title="Dialogo agendado"
                      *ngIf="data.type == 'dialog' && data.agendamento == true && !data.excluida"
                      class="user-chat-content agendamento-dialogo text-start" id="{{data.id}}">
                      <div class="title">

                        <i class="ri-robot-fill font-size-10 ic"></i>


                        <small>Agendamento de dialogo {{data.data_hora_agenda}}</small>

                        <i *ngIf="data.status_agendamento == 1" style="margin-left: 5px; color: green;"
                          class="ri-check-line font-size-20 ic"></i>
                        <i *ngIf="data.status_agendamento == 3" style="margin-left: 5px;"
                          class="ri-time-fill font-size-20 ic"></i>
                      </div>
                      <div *ngIf="data.isText" class="p-2 mb-2">
                        <div class="d-flex align-items-center">

                          <div class="d-flex-body">
                            <div class="text-start">
                              <h5 class="conteudo">
                                {{data.conteudo | translate}}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="conversation-name"><span *ngIf="data.align === 'right'">{{senderName |
                          translate}}</span><span *ngIf="data.align !== 'right'">{{data.name | translate}}</span></div>

                      <button [disabled]="data.status_agendamento == 1" pButton pRipple type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-danger " style="margin-top: 5px;"
                        (click)="deleteMensagemSistema(data)"></button>

                    </div>
                  </div>

                  <!-- <div class="chat-day-title">
                    <span class="title">{{message}}</span>
                  </div> -->
                </li>
              </div>

            </ul>
          </ngx-simplebar>
          <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade" id="copyClipBoard"
            role="alert">
            Message copied
          </div>

          <div class="button-float1 text-end" style="margin-top: -47px;
          margin-right: 10px;">
            <button pButton pRipple type="button" icon="ri-arrow-down-line" class="p-button-rounded"
              (click)="onListScroll()"></button>
          </div>
        </div>


        <div *ngIf="showResponse" id="dvResponse">
          <div class="responder">
            <small class="title-responder">Responder:</small>
            <div [title]="mensagem_a_responder" class="d-flex" style="flex-grow: 1;">
              <div class="d-flex ellipsis">
                <div class="barra-responder"></div>
                <div class="ellipsis area-responder">
                  <div class="d-grid">

                    <small *ngIf="remetente_a_responder !== nome_usuario" class="remetente">
                      {{remetente_a_responder}}
                    </small>
                    <small *ngIf="remetente_a_responder == nome_usuario" class="remetente">
                      Você
                    </small>

                    <small [title]="mensagem_a_responder"
                      class="ellipsis text-responder">{{mensagem_a_responder}}</small>
                  </div>

                </div>
              </div>


              <div class="p-2" (click)="showResponse = false" style="cursor: pointer;">
                <button (click)="showResponse = false" class="btn btn-danger">X</button>
              </div>
            </div>

          </div>

        </div>
        <!-- end chat conversation end -->





        <div class="col resposta-rapida" *ngIf="respostaRapida">
          <div class="p-fluid row col-12 pt-4">
            <div class="col-2" (click)="respostaRapida = false">
              <span class="btn btn-sm btn-danger float-right cancel_reply_msg"><i class="pi pi-times"></i></span>
            </div>
          </div>

          <ul *ngFor="let resposta of fastResponseList" class="item-response-fast">

            <div class="row col-10 d-block mt-3" style="cursor: pointer;" (click)="copiaRespostaRapida(resposta)">
              <article>
                <h4 style="font-weight: bolder;">{{resposta.atalho}}</h4>
                <p>{{resposta.texto}}</p>
              </article>
            </div>

          </ul>
        </div><!-- end col-->




        <!-- start chat input section -->
        <div class="p-1 p-lg-3 mb-0 send-msg" style="margin-bottom: 10px !important;">

          <!-- <div class="d-flex justify-content-center">
            <div *ngIf="chatSelected != null && chatSelected.situacao == 0 && chatSelected.usuario_atendimento_atual_id == null" class="p-fluid">
              <button pButton pRipple type="button" label="Atender" class="p-button-rounded p-button-success"
                (click)="linkAtendimentoUsuario()"></button>
            </div>
          </div> -->



          <form *ngIf="chatSelected != null " (ngSubmit)="messageSave()" [formGroup]="formData" id="chatinput-form"
            enctype="multipart/form-data">
            <div class="row no-gutters">

              <div *ngIf="!recorder" class="col">
                <div>
                  <textarea [readonly]="modalMensagemAgendada"
                    class="form-control form-control-lg bg-light border-light" id="message" placeholder="Digite aqui..."
                    formControlName="message" [(ngModel)]="emoji" rows="1" cols="60" (paste)="onPaste($event)"
                    (input)="onBlur()" (focus)="onFocus()" (keypress)="eventKey($event)">
                  </textarea>

                  <!-- <p-editor #boxMensagemEditor placeholder="Digite aqui..." formControlName="message" 
                    (paste)="onPaste($event)" (input)="onBlur()" (focus)="onFocus()" (keypress)="eventKey($event)" [style]="{'height':'50px'}">
                    <ng-template pTemplate="header">
                    </ng-template>
                  </p-editor> -->

                  <!-- <input type="text" class="form-control form-control-lg bg-light border-light"
                    placeholder="Digite aqui..." formControlName="message" [(ngModel)]="emoji" (input)="onBlur()"
                    (focus)="onFocus()"> -->
                </div>
              </div>

              <div *ngIf="recorder" class="col">


                <!-- <div *ngIf="recorder && !showAudio" class="col-auto">

                  <div class="d-flex justify-content-start">
                    <div style="padding: 10px;">
                      <cd-timer format="hms" #basicTimer></cd-timer>
                    </div>

                    <div>
                      <button class="mr-2 btn btn-danger chat-send w-100" (click)="stopRecord()"><i
                          class='ri-pause-line'></i></button>
                    </div>
                  </div>
                  
                </div> -->
              </div>



              <div class="col-auto">
                <div class="chat-input-links ms-md-2">

                  <ul *ngIf="recorder" class="list-inline mb-0">
                    <li *ngIf="!showAudio" class="list-inline-item">
                      <cd-timer format="hms" #basicTimer></cd-timer>
                    </li>

                    <li *ngIf="!showAudio" class="list-inline-item ml-2">
                      <button class="mr-2 btn btn-danger chat-send w-100" (click)="stopRecord()"><i
                          class='ri-pause-line'></i></button>
                    </li>

                    <li *ngIf="showAudio" id="myAudio" class="list-inline-item">

                    </li>

                    <li *ngIf="showAudio" class="list-inline-item mr-2 ml-2">
                      <button (click)="cancelAudio()"
                        class="btn btn-danger font-size-16 btn-lg chat-send waves-effect waves-light mb-4 mr-2 ml-2">
                        <i class="ri-close-circle-line"></i>
                      </button>
                    </li>

                    <li *ngIf="showAudio" class="list-inline-item">
                      <button (click)="sendAudio()"
                        class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light mb-4">
                        <i class="ri-send-plane-2-fill"></i>
                      </button>
                    </li>
                  </ul>

                  <ul *ngIf="!recorder" class="list-inline mb-0">

                    <li class="list-inline-item">
                      <button ngbTooltip="Anotação interna" (click)="showModalAnotacao()"
                        class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light mr-2">
                        <i class="ri-file-edit-fill"></i>
                      </button>
                    </li>

                    <li class="list-inline-item ml-1" style="margin-left: 5px;">
                      <button ngbTooltip="Agendar mensagem" (click)="showModalAgendarMensagem()"
                        class="btn btn-warning font-size-16 btn-lg chat-send waves-effect waves-light">
                        <i class="ri-calendar-line"></i>
                      </button>
                    </li>

                    <li class="list-inline-item">
                      <label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                        ngbTooltip="Gravar audio">
                        <i class="ri-mic-line" (click)="initiateRecording()"></i>
                      </label>
                    </li>

                    <li class="list-inline-item">
                      <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                        data-toggle="tooltip" data-placement="top" ngbTooltip="Emoji" (click)="toggleEmojiPicker()"
                        id="emoji-btn">
                        <i class="ri-emotion-happy-line"></i>
                      </button>
                      <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
                        title="Pick your emoji…"></emoji-mart>
                    </li>

                    <li class="list-inline-item">
                      <label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                        ngbTooltip="Imagens e vídeos">
                        <i class="ri-image-line"></i>
                        <input name="fileInput" accept=".jpg, .png, .mp4" size="60" multiple type="file"
                          class="form-control-file d-none" (change)="uploadImagem($event)">
                      </label>
                    </li>

                    <li class="list-inline-item">
                      <label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                        ngbTooltip="Documentos">
                        <i class="ri-attachment-line"></i>
                        <input #fileInput name="fileInput" accept=".pdf" size="60" multiple type="file"
                          class="form-control-file d-none" (change)="uploadFiles($event)">
                      </label>
                    </li>

                    <li class="list-inline-item">
                      <label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                        ngbTooltip="Enviar contato">
                        <i class="ri-phone-line" (click)="showModalContatos()"></i>
                      </label>
                    </li>

                    <li class="list-inline-item">
                      <button ngbTooltip="Enviar" (click)="sendMessage()"
                        class="btn btn-success font-size-16 btn-lg chat-send waves-effect waves-light">
                        <i class="ri-send-plane-2-fill"></i>
                      </button>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </form>
        </div>
        <!-- end chat input section -->

        <div _ngcontent-hes-c189="" class="replyCard">
          <div _ngcontent-hes-c189="" class="card mb-0">
            <div _ngcontent-hes-c189="" class="card-body py-3">
              <div _ngcontent-hes-c189="" class="replymessage-block mb-0 d-flex align-items-start">
                <div _ngcontent-hes-c189="" class="flex-grow-1">
                  <h5 _ngcontent-hes-c189="" class="conversation-name">You</h5>
                  <p _ngcontent-hes-c189="" class="mb-0">Wow that's great</p>
                </div>
                <div _ngcontent-hes-c189="" class="flex-shrink-0">
                  <button _ngcontent-hes-c189="" type="button" id="close_toggle"
                    class="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none" (click)="closeReplay()">
                    <i _ngcontent-xeu-c127="" class="ri-close-line float-end fs-5"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      <!-- end chat conversation section -->



      <!-- <app-profile-detail></app-profile-detail> -->
    </div>


  </div>

  <div *ngIf="activetab == 4" class="main-content card">
    <app-contact-detail [contactSelected]="contactSelected" (onNewChat)="onNewChat($event)"></app-contact-detail>
  </div>

  <div *ngIf="chatSelected != null && section == 'chat' && showDadosContato" class="user-chat w-50">
    <div class="d-flex justify-content-end">
      <div class="p-2">
        <button type="button" class="btn-close" aria-label="Close" (click)="showDadosContato = false"></button>
      </div>
    </div>

    <div class="row p-2">

      <app-info-chat [chatSelected]="chatSelected" [camposPessoaChat]="camposPessoaChat" (onTagUpdate)="getTagLinked()"
        (onUsuarioUpdate)="filterAvancado()" (onDepartamentoUpdate)="filterAvancado()"
        (onSaveDadoPessoa)="saveDadoPessoa($event)"></app-info-chat>

    </div>
  </div>

</div>

<!-- Chat Info Sidebar -->
<ng-template #userInfo id="userInfo" let-offcanvas style="width: 40vw;">
  <div class="offcanvas-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>

  <div class="offcanvas-body profile-offcanvas p-0">

    <app-info-chat [chatSelected]="chatSelected" [camposPessoaChat]="camposPessoaChat" (onTagUpdate)="getTagLinked()"
      (onUsuarioUpdate)="filterAvancado()" (onDepartamentoUpdate)="filterAvancado()"
      (onSaveDadoPessoa)="saveDadoPessoa($event)"></app-info-chat>

    <div *ngIf="false" class="text-center p-4 border-bottom">
      <div class="mb-4">
        <img src="{{userProfile}}" class="rounded-circle avatar-lg img-thumbnail" alt="">
      </div>
      <h5 class="font-size-16 mb-1 text-truncate">{{userName | translate}}</h5>
    </div>
    <perfect-scrollbar *ngIf="false" class="p-4 user-profile-desc">

      <app-info-chat [chatSelected]="chatSelected" (onTagUpdate)="getTagLinked()" (onUsuarioUpdate)="filterAvancado()"
        (onDepartamentoUpdate)="filterAvancado()"></app-info-chat>




      <div id="profile-user-accordion" class="custom-accordion mt-4">
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
          <ngb-panel cardClass="card shadow-none border mb-2" id="others">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                <i class="ri-plus-2-line me-2 align-middle d-inline-block"></i>
                Outros
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>

              <div class="p-fluid mt-4 row">

                <div class="col-sm-12">
                  <label>Departamentos</label>
                  <p-multiSelect class="p-inputtext-sm" filter="true" [options]="dropdownDepartamentos"
                    [(ngModel)]="outros_departamentos" (onPanelHide)="updateDepartamentosChat()"></p-multiSelect>
                </div>

                <div class="col-sm-12">
                  <label>Responsáveis</label>

                  <p-multiSelect class="p-inputtext-sm" filter="true" [options]="dropdownUsers"
                    [(ngModel)]="outros_responsaveis" (onPanelHide)="updateResponsaveisChat()"></p-multiSelect>
                </div>


              </div>

            </ng-template>
          </ngb-panel>

          <ngb-panel cardClass="card shadow-none border mb-2" id="about">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
                Dados do contato
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              <div *ngFor="let item of camposPessoaChat" class="mb-2">
                <p class="text-muted mb-1">{{item['campo']}}</p>

                <input [disabled]="!item.editavel" *ngIf="item.tipo == 'STRING'" pInputText
                  class="form-control p-inputtext-sm" [(ngModel)]="item.value" (blur)="saveDadoPessoa(item)" />

                <p-dropdown *ngIf="item.tipo == 'DROPDOWN'" [disabled]="!item.editavel" [filter]="true" class="p-fluid"
                  placeholder="Selecione" [options]="item.opcoes" [(ngModel)]="item.value"
                  (onChange)="saveDadoPessoa(item)"></p-dropdown>

                <p-calendar *ngIf="item.tipo == 'DATE'" [disabled]="!item.editavel" class="p-inputtext-sm p-fluid"
                  dateFormat="dd/mm/yy" [(ngModel)]="item.value" (onChange)="saveDadoPessoa(item)"></p-calendar>

                <p-inputNumber *ngIf="item.tipo == 'CURRENCY'" disabled="!item.editavel" class="p-inputtext-sm p-fluid"
                  [(ngModel)]="item.value" inputId="value" mode="currency" currency="BRL"
                  (onChange)="saveDadoPessoa(item)">
                </p-inputNumber>

                <p-inputNumber *ngIf="item.tipo == 'NUMBER'" disabled="!item.editavel" class="p-inputtext-sm p-fluid"
                  inputId="integeronly" [(ngModel)]="item.value" (onChange)="saveDadoPessoa(item)">
                </p-inputNumber>

              </div>

              <!-- <div>
                <p class="text-muted mb-1">{{'chat.rightpanel.about.name.text' | translate}}</p>
                <h5 class="font-size-14">{{userName | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.email.text' | translate}}</p>
                <h5 class="font-size-14">{{'chat.rightpanel.about.email.value' | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.time.text' | translate}}</p>
                <h5 class="font-size-14">{{'chat.rightpanel.about.time.value' | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.location.text' | translate}}</p>
                <h5 class="font-size-14 mb-0">{{'chat.rightpanel.about.location.value' | translate}}</h5>
              </div> -->
            </ng-template>
          </ngb-panel>

          <ngb-panel cardClass="card shadow-none border mb-2" id="file">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>{{'Arquivos compartilhados'
                | translate}}
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="card p-2 border mb-2">


                <div *ngFor="let file of sharedFilesHistoricList" class="d-flex align-items-center mb-4">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-file-text-fill"></i>
                    </div>
                  </div>

                  <div class="flex-grow-1">
                    <div class="text-start text-truncate ">
                      <h5 class="font-size-14 mb-1 titulo">{{file.conteudo | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0 mr-4">{{file.conteudo | translate}}
                        <b>{{file.created_at
                          | date:'dd/MM/yyyy'}}</b>
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a [href]="file.src" target="_blank" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->


              <!-- end card -->
            </ng-template>
          </ngb-panel>



        </ngb-accordion>
        <!-- End Attached Files card -->
      </div>
      <!-- end profile-user-accordion -->
    </perfect-scrollbar>
    <!-- end user-profile-desc -->

  </div>
  <!--end offcanvas-body-->
</ng-template>

<!-- Center Modal -->
<!-- <ng-template #centerDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Forward to...</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="p-2 card-body">
      <div data-simplebar="init" style="max-height: 200px;">
        <div class="simplebar-wrapper" style="margin: 0px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
              <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
                style="height: auto; overflow: hidden scroll;">
                <div class="simplebar-content" style="padding: 0px;">
                  <div>
                    <div class="p-3 font-weight-bold text-primary">A</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck1" type="checkbox" class="form-check-input form-check-input"
                            value="Albert Rodarte">
                          <label for="memberCheck1" class="form-check-label">Albert Rodarte</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck2" type="checkbox" class="form-check-input form-check-input"
                            value="Allison Etter">
                          <label for="memberCheck2" class="form-check-label">Allison Etter</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">C</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck3" type="checkbox" class="form-check-input form-check-input"
                            value="Craig Smiley">
                          <label for="memberCheck3" class="form-check-label">Craig Smiley</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">D</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck4" type="checkbox" class="form-check-input form-check-input"
                            value="Daniel Clay">
                          <label for="memberCheck4" class="form-check-label">Daniel Clay</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck5" type="checkbox" class="form-check-input form-check-input"
                            value="Doris Brown">
                          <label for="memberCheck5" class="form-check-label">Doris Brown</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">I</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck6" type="checkbox" class="form-check-input form-check-input"
                            value="Iris Wells">
                          <label for="memberCheck6" class="form-check-label">Iris Wells</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">J</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck7" type="checkbox" class="form-check-input form-check-input"
                            value="Juan Flakes">
                          <label for="memberCheck7" class="form-check-label">Juan Flakes</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck8" type="checkbox" class="form-check-input form-check-input"
                            value="John Hall">
                          <label for="memberCheck8" class="form-check-label">John Hall</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck9" type="checkbox" class="form-check-input form-check-input"
                            value="Joy Southern">
                          <label for="memberCheck9" class="form-check-label">Joy Southern</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">M</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck10" type="checkbox" class="form-check-input form-check-input"
                            value="Mary Farmer">
                          <label for="memberCheck10" class="form-check-label">Mary Farmer</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck11" type="checkbox" class="form-check-input form-check-input"
                            value="Mark Messer">
                          <label for="memberCheck11" class="form-check-label">Mark Messer</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck12" type="checkbox" class="form-check-input form-check-input"
                            value="Michael Hinton">
                          <label for="memberCheck12" class="form-check-label">Michael Hinton</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">O</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck13" type="checkbox" class="form-check-input form-check-input"
                            value="Ossie Wilson">
                          <label for="memberCheck13" class="form-check-label">Ossie Wilson</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">P</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck14" type="checkbox" class="form-check-input form-check-input"
                            value="Phillis Griffin">
                          <label for="memberCheck14" class="form-check-label">Phillis Griffin</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck15" type="checkbox" class="form-check-input form-check-input"
                            value="Paul Haynes">
                          <label for="memberCheck15" class="form-check-label">Paul Haynes</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">R</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck16" type="checkbox" class="form-check-input form-check-input"
                            value="Rocky Jackson">
                          <label for="memberCheck16" class="form-check-label">Rocky Jackson</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">S</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck17" type="checkbox" class="form-check-input form-check-input"
                            value="Sara Muller">
                          <label for="memberCheck17" class="form-check-label">Sara Muller</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck18" type="checkbox" class="form-check-input form-check-input"
                            value="Simon Velez">
                          <label for="memberCheck18" class="form-check-label">Simon Velez</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck19" type="checkbox" class="form-check-input form-check-input"
                            value="Steve Walker">
                          <label for="memberCheck19" class="form-check-label">Steve Walker</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">H</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck20" type="checkbox" class="form-check-input form-check-input"
                            value="Hanah Mile">
                          <label for="memberCheck20" class="form-check-label">Hanah Mile</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: auto; height: 1666px;"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
          <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
          <div class="simplebar-scrollbar" style="height: 25px; transform: translate3d(0px, 0px, 0px); display: block;">
          </div>
        </div>
      </div>
      <div class="border-0 modal-footer"><button class="btn btn-primary">Forward</button></div>
    </div>
  </div>
</ng-template> -->





<!-- <p-dialog header="Imagens e vídeos" [(visible)]="modalGallery" [style]="{width: '50vw'}">
  <div class="row">
      <div *ngFor="let item of galleryList" class="col-auto">

          <img *ngIf="item.file.type == 'image/png' || item.file.type == 'image/jpg' || item.file.type == 'image/jpeg'  "
              [src]="item.url" style="width: 250px; height: auto;"> <br />

          <video *ngIf="item.file.type == 'video/mp4'" controls style="width: 250px; height: 250px;">
              <source [src]="item.url" type="audio/ogg">
          </video>

          <input
              *ngIf="item.file.type == 'image/png' || item.file.type == 'image/jpg' || item.file.type == 'image/jpeg'  "
              pInputText class="d-flex mt-2" style="width: 250px;" [(ngModel)]="item.caption" />
      </div>
  </div>

  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="modalGallery=false" label="Cancelar"
          styleClass="p-button-danger"></p-button>

      <p-button icon="pi pi-check" (click)="uploadHandlerGallery()" label="Enviar"
          styleClass="p-button-success"></p-button>
  </ng-template>
</p-dialog> -->


<p-dialog header="Imagens e vídeos" [(visible)]="modalGallery" [style]="{width: '50vw'}">
  <div class="row">
    <div *ngFor="let item of galleryList" class="col-auto">

      <img *ngIf="item.file.type == 'image/png' || item.file.type == 'image/jpg' || item.file.type == 'image/jpeg'  "
        [src]="item.url" style="width: 250px; height: auto;"> <br />

      <video *ngIf="item.file.type == 'video/mp4'" controls style="width: 250px; height: 250px;">
        <source [src]="item.url" type="audio/ogg">
      </video>

      <input *ngIf="item.file.type == 'image/png' || item.file.type == 'image/jpg' || item.file.type == 'image/jpeg'  "
        pInputText class="d-flex mt-2" style="width: 250px;" [(ngModel)]="item.caption" />
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="modalGallery=false" label="Cancelar" styleClass="p-button-danger"></p-button>

    <p-button icon="pi pi-check" (click)="uploadHandlerGallery()" label="Enviar" styleClass="p-button-success">
    </p-button>
  </ng-template>
</p-dialog>

<app-compartilhar-mensagem [modal]="modalShare" (onCancel)="closeModalShared()" (onShared)="shared($event)">
</app-compartilhar-mensagem>

<!-- <ng-template #galleryContent let-modal>
  <div class="modal-body row">
    <div *ngFor="let item of galleryList" class="col-auto">

      <img *ngIf="item.file.type == 'image/png' || item.file.type == 'image/jpg' || item.file.type == 'image/jpeg'  "
        [src]="item.url" style="width: 250px; height: auto;"> <br />

      <video *ngIf="item.file.type == 'video/mp4'" controls style="width: 250px; height: 250px;">
        <source [src]="item.url" type="audio/ogg">
      </video>

      <input
        *ngIf="item.file.type == 'image/png' || item.file.type == 'image/jpg' || item.file.type == 'image/jpeg'  "
        pInputText class="d-flex mt-2" style="width: 250px;" [(ngModel)]="item.caption" />
    </div>
  </div>
</ng-template>


<p-dialog header="Title" [(visible)]="modalGallery">
  Content
</p-dialog> -->

<!-- <p-confirmDialog header="Confirmar" icon="pi pi-exclamation-triangle"></p-confirmDialog> -->

<p-confirmDialog>
  <ng-template pTemplate="header">
    <div *ngIf="confirmationLinkAtendimentoUsuario" class="p-fluid">
      <label>Mensagem a ser enviada</label>
      <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="mensagemLinkAtendimento"></textarea>
    </div>
  </ng-template>
</p-confirmDialog>



<p-dialog header="Anotações" [(visible)]="modalNotepad" [style]="{width: '50vw'}">
  <div class="row">
    <div class="col-md-12">

    </div>

    <div class="col-md-12">
      <app-notepad [chat]="chatSelected"></app-notepad>
    </div>

  </div>

  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modalNotepad = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>
  </ng-template>
</p-dialog>




<p-dialog header="Novo chat interno" [(visible)]="modalChatInterno" [style]="{width: '20vw'}" [modal]="true">
  <div class="row">
    <div class="col-md-12">
      <label>Selecione o usuário</label>
      <p-dropdown appendTo="body" filter="true" [(ngModel)]="userChatInterno" class="p-inputtext-sm"
        [options]="dropdownUsuario" placeholder="Selecione"></p-dropdown>
    </div>

  </div>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modalChatInterno = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;" (click)="newChatInterno()"
      ngbTooltip="Iniciar chat"><i class="pi pi-check"></i></button>
  </ng-template>
</p-dialog>

<p-dialog header="Finalizar atendimento" [(visible)]="modalFinalizarAtendimento" [style]="{width: '50vw'}"
  [modal]="true">
  <div class="row m-1">
    <ul class="flex items-center gap-6">
      <li class="flex items-center text-lg"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
          viewBox="0 0 16 16" class="mr-2 text-primary dark:text-gray-ultra-light" height="18" width="18"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.25 3l1.166-.624 8 5.333v1.248l-8 5.334-1.166-.624V3zm1.5 1.401v7.864l5.898-3.932L5.75 4.401z"></path>
        </svg><b class="mr-2 font-semibold">Iniciou em:</b><span> {{objetoFinalizar.inicioAtendimento | date:'dd/MM/yyyy
          HH:mm'}}</span></li>
      <li class="flex items-center text-lg"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
          viewBox="0 0 24 24" class="mr-2 text-accent dark:text-gray-ultra-light" height="18" width="18"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z">
          </path>
          <path d="M13 7h-2v5.414l3.293 3.293 1.414-1.414L13 11.586z"></path>
        </svg><b class="mr-2 font-semibold">Duração:</b><span> {{objetoFinalizar.tempoAtendimento}}</span></li>
      <li class="flex items-center text-lg"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
          viewBox="0 0 24 24" class="mr-2 text-error dark:text-gray-ultra-light" height="1em" width="1em"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 4C7.58172 4 4 7.58172 4 12H7C8.10457 12 9 12.8954 9 14V19C9 20.1046 8.10457 21 7 21H4C2.89543 21 2 20.1046 2 19V12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12V19C22 20.1046 21.1046 21 20 21H17C15.8954 21 15 20.1046 15 19V14C15 12.8954 15.8954 12 17 12H20C20 7.58172 16.4183 4 12 4ZM4 14V19H7V14H4ZM17 14V19H20V14H17Z">
          </path>
        </svg><b class="mr-2 font-semibold">Responsável:</b><span>{{objetoFinalizar.atendente}}</span></li>
    </ul>
  </div>

  <!-- <div *ngIf="false" class="row mt-4">
    <div class="col-sm-4">
      <div *ngIf="dropDownQualificacao.length > 0" class="col-md-12 mb-3 p-fluid">
        <label>Qualificação</label>
        <p-dropdown appendTo="body" [options]="dropDownQualificacao" placeholder="Selecione"
          [(ngModel)]="objetoFinalizar.qualificacao"></p-dropdown>
      </div>

      <div *ngIf="dropdownOrigemLead.length > 0" class="col-md-12 mb-3 p-fluid">
        <label>Origem</label>
        <p-dropdown appendTo="body" [options]="dropdownOrigemLead" placeholder="Selecione"
          [(ngModel)]="objetoFinalizar.origem"></p-dropdown>
      </div>

      <div *ngIf="dropDownInteresse.length > 0" class="col-md-12 mb-3 p-fluid">
        <label>Interesse</label>
        <p-dropdown appendTo="body" [options]="dropDownInteresse" placeholder="Selecione"
          [(ngModel)]="objetoFinalizar.interesse"></p-dropdown>
      </div>
    </div>

    <div class="col-auto">
      <div class="col-md-12 mb-3 p-fluid">
        <label>Relato</label>
        <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="objetoFinalizar.relato"></textarea>
      </div>
    </div>

    <div class="col-auto">
      <div class="col-md-12 mb-3 p-fluid">
        <label>Valor total da negociação</label>
        <p-inputNumber [(ngModel)]="objetoFinalizar.valor" inputId="value" mode="currency" currency="BRL">
        </p-inputNumber>
      </div>
    </div>
  </div> -->


  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;"
      (click)="modalFinalizarAtendimento = false" ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;" (click)="finalizarAtendimento()"
      ngbTooltip="Finalizar atendimento"><i class="pi pi-check"></i></button>
  </ng-template>
</p-dialog>

<p-dialog header="Contatos" [(visible)]="modalContatos" [style]="{width: '50vw'}" [modal]="true">

  <div class="col-sm-12">
    <label for="lastname2">Canal de atendimento</label>
    <p-dropdown appendTo="body" placeholder="Selecione um canal" [options]="dropdownCanalAtendimento"
      [(ngModel)]="canal_atendimento_id"></p-dropdown>
    <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
  </div>

  <div class="d-flex justify-content-between">

    <div class="p-2">
      <p-paginator pageLinkSize="0" class="custom-paginator" [rows]="100" [totalRecords]="totalRecordsContatos"
        (onPageChange)="lazyLoadContacts($event)"></p-paginator>
    </div>

    <div class="p-2">
      <button type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;" ngbTooltip="Filtro"
        (click)="opContato.toggle($event)"><i class="pi pi-search"></i></button>
    </div>
  </div>

  <p-overlayPanel #opContato>
    <ng-template pTemplate>
      <div class="row p-fluid">
        <div class="mb-3 col-md-12">
          <label for="inputtext">Nome</label>
          <input id="firstname2" type="text" class="form-control" pInputText [(ngModel)]="nomeFilterContato">
        </div>



        <div class="mb-3 col-md-12">
          <button type="button" class="btn btn-success btn-sm " (click)="lazyLoadContacts(null)"><i
              class="pi pi-search"></i></button>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>

  <div class="text-center">
    <p-progressSpinner *ngIf="loadingContatos" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
      strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>

  <p-orderList *ngIf="!loadingContatos" [value]="contacts" [(selection)]="selectedContatos"
    [listStyle]="{'max-height':'30rem'}" header="Lista de contatos" filterPlaceholder="Filtrar por nome"
    [dragdrop]="true">
    <ng-template let-contato pTemplate="item">
      <div class="product-item">

        <div *ngIf="contato.profilePicture" class="image-container">
          <img src="{{urlStorage}}/{{contato.profilePicture}}" [alt]="contato.name" class="product-image" />
        </div>

        <div class="avatar-xs" *ngIf="!contato.profilePicture">
          <span class="avatar-title rounded-circle bg-soft-primary text-primary">
            {{(contato.name | translate).charAt(0)}}
          </span>
        </div>


        <div class="product-list-detail">
          <h5 class="mb-2">{{contato.name}}</h5>
          <i class="pi pi-phone product-category-icon"></i>
          <span class="product-category">{{contato.whatsapp| celular}}</span>
        </div>

      </div>
    </ng-template>
  </p-orderList>

  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modalContatos = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;" (click)="sendContato()"
      ngbTooltip="Enviar contato"><i class="pi pi-check"></i></button>
  </ng-template>
</p-dialog>


<p-dialog header="Nova conversa" [(visible)]="modalNewChat" [style]="{width: '50vw'}">


  <div *ngIf="loadingNovaConversa == true" class="col-md-12" style="text-align: center; margin-top: 5px;">

    <p-progressSpinner [style]="{width: '20px', height: '20px'}" styleClass="custom-spinner" strokeWidth="8"
      fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

  </div>

  <form *ngIf="!loadingNovaConversa" [formGroup]="formAtendimento" class="row">


    <div class="col-sm-12">
      <label for="lastname2">Nome</label>
      <input pInputText class="form-control" formControlName="nome" />
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div>

    <!-- <div class="col-sm-1 p-fluid">
      <label for="lastname2">DDD</label>
      <p-inputMask mask="(99)" formControlName="ddd" [unmask]="true" class="p-inputtext-sm">
      </p-inputMask>
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div> -->

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">WhatsApp</label>
      <input pInputText class="form-control" formControlName="whatsapp" />
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório (Informe o numero com o DDD)</small>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Canal de atendimento</label>
      <p-dropdown appendTo="body" placeholder="Selecione um canal" [options]="dropdownCanalAtendimento"
        formControlName="canal_atendimento_id"></p-dropdown>
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Resposta rapida</label>
      <input pInputText class="form-control" formControlName="resposta_rapida" (input)="onGetResposta()" />
    </div>

    <div class="col resposta-rapida" *ngIf="respostaRapidaNovaConversa">
      <div class="p-fluid row col-12 pt-4">
        <div class="col-2" (click)="respostaRapidaNovaConversa = false">
          <span class="btn btn-sm btn-danger float-right cancel_reply_msg"><i class="pi pi-times"></i></span>
        </div>
      </div>

      <ul *ngFor="let resposta of fastResponseListNovaConversa" class="item-response-fast">

        <div class="row col-10 d-block mt-3" style="cursor: pointer;"
          (click)="copiaRespostaRapidaNovaConversa(resposta)">
          <article>
            <h4 style="font-weight: bolder;">{{resposta.atalho}}</h4>
            <p>{{resposta.texto}}</p>
          </article>
        </div>

      </ul>
    </div><!-- end col-->

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">Anotação interna</label>
      <textarea rows="5" cols="5" pInputTextarea formControlName="anotacaoInterna" class="p-inputtext-sm"></textarea>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Dialogo</label>
      <p-dropdown appendTo="body" placeholder="Selecione um dialogo" [options]="dropdownGatilho"
        formControlName="gatilho_id" (onChange)="changeGatilho()"></p-dropdown>
    </div>
  </form>


  <ng-template pTemplate="footer">
    <p-button *ngIf="!loadingNovaConversa" [disabled]="formAtendimento.invalid" icon="pi pi-check"
      (click)="startConversa()" label="Iniciar conversa" styleClass="p-button-primary"></p-button>
  </ng-template>
</p-dialog>


<p-dialog header="Agendar mensagem" [(visible)]="modalMensagemAgendada" [style]="{width: '40vw', height: '50vh'}">
  <form [formGroup]="formData">

    <div class="row p-fluid">
      <div class="col-md-12">
        <label>Digite aqui a mensagem que deseja agendar</label>
        <textarea rows="5" cols="5" pInputTextarea formControlName="message" class="p-inputtext-sm"></textarea>
      </div>

      <div class="col-md-12">
        <label>Informe a data e hora do agendamento</label>
        <p-calendar appendTo="body" class="p-inputtext-sm p-fluid" [showTime]="true" dateFormat="dd/mm/yy"
          formControlName="agenda"></p-calendar>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="modalMensagemAgendada=false" label="Cancelar" styleClass="p-button-danger">
    </p-button>

    <p-button [disabled]="formData.get('agenda').value == null" icon="pi pi-check" (click)="agendarMensagem()"
      label="Agendar" styleClass="p-button-primary"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Anotação interna" [(visible)]="modalAnotacaoInterna" [style]="{width: '40vw', height: '70vh'}">
  <form [formGroup]="formData">

    <div class="row p-fluid">
      <div class="col-md-12">
        <label>Digite a anotação</label>
        <textarea rows="5" cols="5" pInputTextarea formControlName="anotacaoInterna" class="p-inputtext-sm"></textarea>
      </div>
    </div>

    <div *ngIf="configPlataforma.evento_anotacao_interna == true" class="col-md-12">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="criarEvento"
          (change)="getTipoAgenda()">
        <label class="form-check-label" for="customCheck2">Criar compromisso no calendário</label>
      </div>
    </div>

    <div *ngIf="formData.get('criarEvento').value == true" class="col-md-12">
      <label>Titulo</label>
      <input pInputText class="form-control" formControlName="tituloEvento" />
    </div>

    <div *ngIf="formData.get('criarEvento').value == true" class="col-md-12">
      <label for="lastname2">Tipo</label>
      <p-dropdown appendTo="body" optionLabel="descricao" placeholder="Selecione" [options]="dropdownTipoAgenda"
        formControlName="tipoEvento"></p-dropdown>
    </div>

    <div *ngIf="formData.get('criarEvento').value == true" class="col-md-12">
      <label>Data</label>
      <p-calendar appendTo="body" class="p-inputtext-sm p-fluid" [showTime]="true" dateFormat="dd/mm/yy"
        formControlName="dataEvento"></p-calendar>
    </div>


  </form>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="modalAnotacaoInterna=false" label="Cancelar" styleClass="p-button-danger">
    </p-button>

    <p-button [disabled]="formData.get('anotacaoInterna').value == null" icon="pi pi-check" (click)="anotacaoInterna()"
      label="Salvar" styleClass="p-button-primary"></p-button>
  </ng-template>
</p-dialog>





<p-overlayPanel #opAgDialogo [showCloseIcon]="true" [style]="{width: '450px'}">
  <ng-template pTemplate>

    <form [formGroup]="formData">
      <p-calendar appendTo="body" class="p-inputtext-sm p-fluid" [showTime]="true" dateFormat="dd/mm/yy"
        formControlName="agenda"></p-calendar>
    </form>

    <div style="margin-top: 5px;">
      <p-button [disabled]="formDialogo.invalid" icon="pi pi-calendar" (click)="saveAgendamentoDialogo()"
        label="Criar agendamento" styleClass="p-button-primary"></p-button>
    </div>
  </ng-template>

</p-overlayPanel>

<p-dialog header="Dialogos/Gatilhos" [(visible)]="modalDialogo" [style]="{width: '50vw', height: '50vh'}">
  <form [formGroup]="formDialogo">
    <div *ngIf="!loadingDialogos" class="col-sm-12">
      <p-dropdown filter="true" appendTo="body" placeholder="Selecione um dialogo" [options]="dropdownGatilho"
        formControlName="id"></p-dropdown>
    </div>

    <div *ngIf="loadingDialogos == true" class="col-md-12" style="text-align: center; margin-top: 5px;">

      <p-progressSpinner [style]="{width: '20px', height: '20px'}" styleClass="custom-spinner" strokeWidth="8"
        fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="modalDialogo=false" label="Cancelar" styleClass="p-button-danger">
    </p-button>

    <p-button [disabled]="formDialogo.invalid" icon="pi pi-check" (click)="sendDialogo()" label="Disparar"
      styleClass="p-button-primary"></p-button>

    <p-button [disabled]="formDialogo.invalid" icon="pi pi-calendar" (click)="opAgDialogo.toggle($event)"
      label="Agendar" styleClass="p-button-primary"></p-button>
  </ng-template>
</p-dialog>




<p-dialog header="Funil de atendimento" [position]="positionModlFunil" [(visible)]="showFunil"
  [style]="{width: '30vw', height: '90vh'}">

  <div class="col-md-12 mb-4" *ngFor="let item of dropdownFunil">
    <h4>{{item.nome}}</h4>
    <div class="list-group">
      <a *ngFor="let etapa of item.etapas" href="#" [ngClass]="classListFunil(etapa.id)">

        <p-checkbox [binary]="true" [(ngModel)]="etapa.selected" inputId="binary"
          (onChange)="checkEtapaFunil($event, etapa.id, item.id)"></p-checkbox>

        {{etapa.nome}}
      </a>
    </div>
  </div>


  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="showFunil=false" label="Cancelar" styleClass="p-button-danger">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Histórico" [(visible)]="modalHistorico" [style]="{ height: '60vh', width: '50vw'}">
  <div class="card">

    <p-timeline *ngIf="array_historico.length > 0" [value]="array_historico">
      <ng-template pTemplate="content" let-event>
        <small class="p-text-secondary">{{event.date}} - {{event.user}}</small> <br />
      </ng-template>
      <ng-template pTemplate="opposite" let-event>
        <p>{{event.status}}</p>
      </ng-template>
    </p-timeline>
  </div>
</p-dialog>


<p-dialog header="{{labelDialogoAguardando}}" [(visible)]="modalAguardando" [style]="{width: '50vw'}">
  <div class="col-sm-1 col-md-1 col-lg-1" style="text-align: center;">
    <p-progressSpinner [style]="{width: '40px', height: '40px', padding: '10px'}" styleClass="custom-spinner"
      strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  </div>
</p-dialog>