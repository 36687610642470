import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CampoPersonalizadoServiceService } from 'src/app/core/services/campo-personalizado-service.service';
import { ConfiguracaoEstacioAutomacaoService } from 'src/app/core/services/configuracao-estacio-automacao.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';

@Component({
  selector: 'app-automacao-estacio',
  templateUrl: './automacao-estacio.component.html',
  styleUrls: ['./automacao-estacio.component.css']
})
export class AutomacaoEstacioComponent implements OnInit {
  dataSource: any[] = [];
  form: FormGroup;
  dropdownDepartamento = []
  modal: boolean = false;
  dropdownCampoOpcao: any[] = [];
  dropdownCamposPesonalizados: any[] = [];
  loadingForm = false;

  constructor(
    private formBuilder: FormBuilder,
    private departamentoService: DepartamentoService,
    private campoPersonalizadoService: CampoPersonalizadoServiceService,
    private configuracaoEstacioService: ConfiguracaoEstacioAutomacaoService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      descricao_sallesforce: [null],
      departamento_id: [null],
      campo_personalizado_id: [null],
      whatsapp_polo: [null],
      whatsapp_qg: [null],
      whatsapp_qg_agressivo: [null],
      whatsapp_polo_agressivo: [null],
      campo_personalizado_valor: [null],
      link_polo: [null],
      endereco_polo: [null],
    });

    this.listCampoPolo();
    this.listAll();
  }

  listAll() {
    this.configuracaoEstacioService.index().subscribe(json => {
      this.dataSource = json;
    })
  }

  show(id) {
    this.loadingForm = true;
    this.modal = true;
    this.listDepartamentos();
    this.form.reset();
    setTimeout(() => {
      this.configuracaoEstacioService.show(id).subscribe(json => {
        this.loadingForm = false;
        this.form.patchValue(json);       
      })
    }, 1000);
  }

  listDepartamentos() {
    this.departamentoService.index().subscribe(departamentos => {
      this.dropdownDepartamento = departamentos.map(item => { return { value: item.id, label: item.nome } });
    })
  }

  changeCampoPersonalizado() {
    let campoPersonalizado = this.dropdownCamposPesonalizados.find(campo => { return campo.value == this.form.get('campo_personalizado_id').value });
    if (campoPersonalizado.tipo == 'DROPDOWN') {
      this.dropdownCampoOpcao = campoPersonalizado.opcoes.map(item => { return { value: item.value, label: item.value } });
    } else {
      this.dropdownCampoOpcao = [];
    }
  }

  listCampoPolo() {
    this.campoPersonalizadoService.index().subscribe(json => {
      this.dropdownCamposPesonalizados = json.map(item => { return { value: item.id, label: item.campo, tipo: item.tipo, opcoes: item.opcoes } });
    })
  }

  add() {
    this.listDepartamentos();
    this.modal = true;
    this.form.reset();
  }

  save() {
    this.configuracaoEstacioService.save(this.form.value, this.form.get('id').value).subscribe(json => {
      this.modal = false;
      this.listAll();
    })
  }
}
