import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseConhecimentoService } from 'src/app/core/services/base-conhecimento.service';
import { CampoPersonalizadoServiceService } from 'src/app/core/services/campo-personalizado-service.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import { DireitoService } from 'src/app/core/services/direito.service';

@Component({
  selector: 'app-campo-personalizado-pessoa',
  templateUrl: './campo-personalizado-pessoa.component.html',
  styleUrls: ['./campo-personalizado-pessoa.component.scss']
})
export class CampoPersonalizadoPessoaComponent {

  dataSource = [];

  modal = false;

  form: FormGroup;

  formOpcao: FormGroup;

  loading = false;

  dropdownTipo = [
    { value: 'STRING', label: 'Texto' },
    { value: 'NUMBER', label: 'Numerico' },
    { value: 'CURRENCY', label: 'Moeda' },
    { value: 'DROPDOWN', label: 'Lista' },
    { value: 'DATE', label: 'Data' },
    { value: 'TABLE', label: 'Tabela' },
    // { value: 'DATETIME', label: 'Data e hora' },
  ]

  opcoesCampo = [];

  dropdownDepartamento = [];

  dropdownAssistentes = [];

  constructor(
    private formBuilder: FormBuilder,
    private campoPersonalizadoService: CampoPersonalizadoServiceService,
    private departamentoService: DepartamentoService,
    public direitoService: DireitoService,
    public baseConhecimentoService: BaseConhecimentoService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      campo: [null, Validators.required],
      tipo: [null, Validators.required],
      ativo: [null],
      localidade: [null],
      opcoes: [null],
      departamento_id: [null],
      editavel: [null],
      filtro_rapido: [null],
      departamentos: [null],
      assistentes: [null],
      enviar_campo_assistente: [false],
      instrucao_assistente: [null]
    })

    this.formOpcao = this.formBuilder.group({
      id: [null],
      label: [null, Validators.required],
      value: [null],
      ativo: [null]
    });

    this.index();
    
  }

  showModal(id = null) {
    this.listDepartamentos();
    this.listAssistentes();
    this.loading = true;
    this.modal = true;
    this.form.reset();

    this.form.get('ativo').setValue(true);
    this.form.get('localidade').setValue('PESSOA');
    this.form.get('editavel').setValue(true);

    this.opcoesCampo = [];
    if (id) {      
      setTimeout(() => {
        this.campoPersonalizadoService.show(id).subscribe(json => {
          this.form.patchValue(json);
          this.opcoesCampo = json.opcoes;
          if (json.assistentes.length > 0) {
            let assistentes = json.assistentes.map(item => {return item.base_conhecimento_id});
            this.form.get('assistentes').setValue(assistentes);
          }
          this.loading = false;

        })
      }, 1000);
    } else {
      this.loading = false;
    }
  }

  index() {
    this.dataSource = [];
    let query = 'localidade=PESSOA';
    this.campoPersonalizadoService.index(null, query).subscribe(json => {
      json.map(item => {
        this.dataSource.push({
          id: item.id,
          campo: item.campo,
          ativo: item.ativo,
          departamento: item?.departamento?.nome || 'Todos'
        })
      })
    })
  }

  listAssistentes() {
    this.baseConhecimentoService.index().subscribe(json => {
      this.dropdownAssistentes = json.map(item => {return {value: item.id, label: item.descricao}});
      console.log(this.dropdownAssistentes);
    })
  }

  listDepartamentos() {
    this.dropdownDepartamento = null;
    this.departamentoService.index().subscribe(json => {
      this.dropdownDepartamento = json;
    })
  }



  save() {

    this.form.get('opcoes').setValue(this.opcoesCampo);

    let form = this.form.value

    let data = {
      id: form.id,
      campo: form.campo,
      tipo: form.tipo,
      ativo: form.ativo,
      localidade: form.localidade,
      enviar_campo_assistente: form.enviar_campo_assistente,
      instrucao_assistente: form.instrucao_assistente,
      opcoes: form.opcoes,
      editavel: form.editavel,
      filtro_rapido: form.filtro_rapido,
      departamentos: form.departamentos,
      assistentes: form.assistentes,
    }

    this.campoPersonalizadoService.save(data, this.form.get('id').value).subscribe(json => {
      this.modal = false;
      this.index();
    })
  }

  addOpcao() {
    this.formOpcao.get('ativo').setValue(true);
    this.opcoesCampo.push(this.formOpcao.value);
    this.formOpcao.reset();
  }

}
