import { Component, ViewChild } from '@angular/core';
import { CampanhaService } from 'src/app/core/services/campanha.service';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';
import { environment } from 'src/environments/environment';
import { CdTimerComponent } from 'angular-cd-timer';
import { TagService } from 'src/app/core/services/tag.service';
import { ConfirmationService, MessageService, PrimeNGConfig, ConfirmEventType } from 'primeng/api';


@Component({
  selector: 'app-campanha',
  templateUrl: './campanha.component.html',
  styleUrls: ['./campanha.component.scss']
})
export class CampanhaComponent {
  @ViewChild('basicTimer') basicTimer!: CdTimerComponent


  dataSource = [];
  form: FormGroup;
  formCampanha: FormGroup;
  modal: boolean = false;
  dropdownTipofiltro: any[] = [
    { value: 0, label: 'Filtro configurável' },
    { value: 1, label: 'Importação csv' },
  ];
  dropDownIntegracaoWhatsapp: any[] = [];
  files: any[] = [];
  filesLink: any[] = [];
  urlUpload: string = environment.urlMultiUpload;
  recorder: boolean = false;
  visualizarAudio: boolean = false;
  loadingFilter: boolean = false;
  localStream: any;
  mediaRecord: any;
  navigatorDevice: any;
  srcAudioRecord: any;
  nomeAudio: any;
  countEnvios: any = 0;
  chunks = [];
  audioList = [];
  array_botao = [];
  dropdownTag = [];
  pessoasImportList = [];
  totalRecords = 0;
  loading = false;
  pt: any = {
    firstDayOfWeek: 0,
    dayNames: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
  };
  dropdownOrder = [
    { value: 'desc', label: 'Ordenar do mais novo' },
    { value: 'asc', label: 'Ordenar do mais antigo' },
  ];
  orderFilter = 'desc';

  multiploListConfig = [];
  modalMultiplo = false;


  constructor(
    private campanhaService: CampanhaService,
    private integracaWhatsapp: IntegracaoWhatsappService,
    private formBuilder: FormBuilder,
    private tagService: TagService,
    private config: PrimeNGConfig,
    private confirmationService: ConfirmationService,
     private messageService: MessageService
  ) {

  }

  ngOnInit(): void {

    this.config.setTranslation({
      dayNames: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
    });

    this.listAll();
    this.listAllCanalAtendimento();
    this.listAllTags();

    this.form = this.formBuilder.group({
      id: [null],
      titulo: [null, Validators.required],
      mensagem: [null],
      status: [null],
      filtro: [null],
      tipo_filtro: [null],
      integracao_whatsapp_id: [null, Validators.required],
      campanha_agendada: [false],
      data_disparo: [null],
      enviar_opacao_blacklist: [false],
      multiplo: [false],
      tag: [null],
      informativo_cadastro: [null],
      arquivo_cadastro_id: [null],
      gerar_link_cadastro: [false],
      caption: [null],
    });

    this.formCampanha = this.formBuilder.group({
      uf: [null],
      cidade: [null],
      bairro: [null],
      tag: [null],
      limit: [null],
      random: [false],
      iniciais: [null],
      mesAniversario: [null],
      diaAniversario: [null],
    })
  }


  addMultiplo() {
    let form = this.form.value;

    let canalAtendimento = this.dropDownIntegracaoWhatsapp.find(x => { return x.value == form.integracao_whatsapp_id });
    let tag = this.dropdownTag.find(x => { return x.value == form.tag });

    let obj = this.multiploListConfig.find(x => {
      return x.canal_atendimento_id == canalAtendimento['value'] && x.tag_id == tag['value']
    });

    if (obj != undefined) {
      return;
    }

    this.multiploListConfig.push({
      canal_atendimento_id: canalAtendimento['value'],
      canal_atendimento_label: canalAtendimento['label'],
      tag_id: tag['value'],
      tag_label: tag['label'],
    });
  }

  removeItemMultiplo(item) {
    let multiploListConfig = this.multiploListConfig.filter(x => {
      return x != item
    });

    this.multiploListConfig = multiploListConfig;
  }


  listAll(event = null) {
    this.loading = true;

    let page;

    if (event == null) {
      page = 1;
    } else {
      page = (event['first'] / event['rows']) + 1;
    }

    let request = 'orderBy=' + this.orderFilter;

    this.campanhaService.index(page, request).subscribe(json => {
      this.loading = false;
      this.totalRecords = json.total;
      this.dataSource = json.data;
    })
  }

  listAllCanalAtendimento() {
    this.dropDownIntegracaoWhatsapp = [];
    this.integracaWhatsapp.index().subscribe(json => {
      this.dropDownIntegracaoWhatsapp = json.map(i => { return { value: i.id, label: i.whatsapp + ' - ' + i.nome } })
    })
  }

  showForm(id = null) {
    this.multiploListConfig = [];

    this.form.reset();

    this.modal = true;

    if (id != null) {
      this.files = [];
      this.filesLink = [];
      this.array_botao = [];
      this.audioList = [];
      this.pessoasImportList = [];

      this.campanhaService.show(id).subscribe(json => {

        if (json.campanha_agendada == true) {
          let dataDisparo = new Date(json.data_disparo);
          let config = JSON.parse(localStorage.getItem('configuracao'));
          dataDisparo.setHours(dataDisparo.getHours() + parseInt(config.gmt));
          json.data_disparo = dataDisparo;
        }

        this.form.patchValue(json);

        this.countEnvios = json.count_envio;

        let filtro = JSON.parse(json.filtro);

        this.formCampanha.patchValue(filtro);


        json.arquivos?.map(item => {
          if (item?.arquivo.extensao !== 'ogg') {
            this.files.push({ arquivo_id: item?.arquivo.id, nome: item.arquivo?.original_name, caption: item.arquivo?.caption });
          }
        });

        json.arquivos?.map(item => {
          if (item?.arquivo.extensao == 'ogg') {
            this.audioList.push({ arquivo_id: item?.arquivo.id, nome: item.arquivo?.original_name });
          }
        });

        json.opcoes?.map(item => {
          this.array_botao.push({ valor: item?.valor, descricao: item?.descricao, });
        });

        json.contatos.map(contato => {

          let obj = {
            id: contato.id,
            nome: contato.nome,
            whatsapp: contato.celular_principal,
            tags: contato.tagNome
          }
          this.pessoasImportList.push(obj);
        })

      })
    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "campanhas");
    });
  }

  sendCancel(event){
let window = this


this.confirmationService.confirm({
  message: 'Deseja continuar com a ação ?',
  header: 'Confirmação',
  icon: 'pi pi-exclamation-triangle',
  acceptLabel: 'Sim',
  rejectLabel: 'Não',
  accept: () => {
    window.campanhaService.sendCancel(event).subscribe(json => {
      window.messageService.add({severity:'success', summary:'Confirmado', detail:'Os envios pendentes foram cancelados!'});
    }, err => {
      window.messageService.add({severity:'error', summary:'Erro', detail:'Ocorreu um erro. Entre em contato com o suporte!'});
    })
      
  },
  reject: (type) => {
      switch(type) {
          case ConfirmEventType.REJECT:
              this.messageService.add({severity:'error', summary:'Rejeitado', detail:'Você rejeitou a ação!'});
          break;
          case ConfirmEventType.CANCEL:
              this.messageService.add({severity:'warn', summary:'Cancelado', detail:'Você cancelou a ação!'});
          break;
      }
  }
});
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  onBasicUpload(event) {
    event.originalEvent.body.forEach(x => {
      this.files.push({ arquivo_id: x.id, nome: x.original_name, caption: null });
    });
  }
  
  onBasicUploadLink(event) {
    event.originalEvent.body.forEach(x => {
      this.filesLink.push({ arquivo_id: x.id, nome: x.original_name });
    });
  }

  initiateRecording() {
    this.recorder = true;

    this.visualizarAudio = false;

    let myPage = this;

    this.navigatorDevice = navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.localStream = stream;

      this.mediaRecord = new MediaRecorder(stream);

      this.chunks = [];
      this.mediaRecord.ondataavailable = (data: any) => {
        this.chunks.push(data.data);
      }

      this.mediaRecord.onstop = () => {
        const blob = new Blob(this.chunks, { type: 'audio/ogg; code=opus' });
        const reader = new window.FileReader();
        reader.readAsDataURL(blob);


        reader.onloadend = () => {
          this.srcAudioRecord = reader.result;
          myPage.montarAudio();
        }
      }

      this.mediaRecord.start();
    })
  }

  montarAudio() {
    if (this.srcAudioRecord) {
      const audio = document.createElement('audio');
      audio.src = this.srcAudioRecord;
      audio.id = 'myAudioElement'
      audio.controls = true;
      let divAudio = document.getElementById('myAudio') as HTMLElement;
      divAudio.append(audio);
    }
  }

  stopBothVideoAndAudio(stream: any) {
    stream.getTracks().forEach(function (track: any) {
      if (track.readyState == 'live') {
        track.stop();
      }
    });
  }

  stopRecord() {
    this.visualizarAudio = true;
    this.basicTimer.stop();

    this.stopBothVideoAndAudio(this.localStream);
  }

  cancelAudio() {
    this.nomeAudio = null;
    this.visualizarAudio = false;
    this.recorder = false;
    this.srcAudioRecord = null;
    let myAudioElement = document.getElementById('myAudioElement') as HTMLElement;
    myAudioElement.remove();
  }

  addAudio() {
    if (this.nomeAudio == null) {
      alert('Informe o nome do audio');
      return;
    }
    this.audioList.push({ nome: this.nomeAudio, content: this.srcAudioRecord });
    this.srcAudioRecord = null;
    this.cancelAudio();
  }

  addBotao() {
    this.array_botao.push({})
  }

  removeOpcaoBotao(item) {
    let arr = this.array_botao.filter(x => { return x !== item });

    this.array_botao = []

    this.array_botao = arr;
  }

  listAllTags() {
    this.tagService.index().subscribe(json => {
      json.forEach(item => {
        this.dropdownTag.push({ value: item.id, label: item.descricao });
      });
      
    });
  }

  showPessoas() {
    this.pessoasImportList = [];
    this.loadingFilter = true;

    let formCampanha = this.formCampanha.value;

    let body = {
      uf: formCampanha.uf,
      cidade: formCampanha.cidade,
      bairro: formCampanha.bairro,
      tag: formCampanha.tag,
      limit: formCampanha.limit || null,
      iniciais: formCampanha.iniciais || null,
      diaAniversario: formCampanha.diaAniversario || null,
      mesAniversario: formCampanha.mesAniversario || null
    }

    if (formCampanha.random) {
      body['orderBy'] = 'random';
    }

    this.campanhaService.showPessoas(this.formCampanha.value).subscribe(json => {
      this.loadingFilter = false;
      if (json.length > 0) {
        json.map(pessoa => {
          this.pessoasImportList.push({
            id: pessoa.id,
            nome: pessoa.nome,
            whatsapp: pessoa.celular_principal
          })
        })
      }
    })
  }

  save(dispatcher = false) {
    // alert('save...');

    if(this.filesLink.length > 0){
      this.form.get('arquivo_cadastro_id').setValue(this.filesLink[0].arquivo_id)
    }

    if (!this.form.get('multiplo').value) {
      let body: any = {
        campanha: this.form.value,
        arquivos: this.files,
        filtro: this.formCampanha.value,
        audios: this.audioList,
        opcoes: this.array_botao,
        contatos_importacao: this.pessoasImportList
      }


      if (body.campanha.id == null) {
        body.campanha = { ...body.campanha, pendente: true };
      }

      this.campanhaService.save(body, body.campanha_id).subscribe(json => {
        if (dispatcher) {
          this.sendMensagem();
          window.history.back();
        }

        this.listAll();

        this.modal = false;
      })
    } else {
      this.saveMultiple();
    }
  }

  saveMultiple() {  
    let count = this.multiploListConfig.length;
    this.modalMultiplo = true;
    this.multiploListConfig.map(item => {
      let tags = [{ value: item['tag_id'], label: item['tag_label'] }];
      this.formCampanha.get('tag').setValue(tags);

      this.pessoasImportList = [];

      this.campanhaService.showPessoas(this.formCampanha.value).subscribe(json => {
        let contatos = [];
        this.loadingFilter = false;
        if (json.length > 0) {
          json.map(pessoa => {
            contatos.push({
              id: pessoa.id,
              nome: pessoa.nome,
              whatsapp: pessoa.celular_principal
            })
          });

          this.form.get('integracao_whatsapp_id').setValue(item['canal_atendimento_id']);
          this.form.get('tipo_filtro').setValue(0);
  
          let body: any = {
            campanha: this.form.value,
            arquivos: this.files,
            filtro: this.formCampanha.value,
            audios: this.audioList,
            opcoes: this.array_botao,
            contatos_importacao: contatos
          }
  
  
          if (body.campanha.id == null) {
            body.campanha = { ...body.campanha, pendente: true };
          }
  
          this.campanhaService.save(body, body.campanha_id).subscribe(json => {
          });
        }
      });
    })


    setTimeout(() => {
      this.modalMultiplo = false;
      this.listAll();
      this.modal = false;
    }, count * 2000);

  }

  sendMensagem() {
    let body = { id: this.form.get('id').value };
    this.campanhaService.sendMensagem(body).subscribe(json => {
      this.countEnvios++;
    })
  }



}
