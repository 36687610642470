import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DepartamentoService } from 'src/app/core/services/departamento.service';

@Component({
  selector: 'app-automacao-estacio',
  templateUrl: './automacao-estacio.component.html',
  styleUrls: ['./automacao-estacio.component.css']
})
export class AutomacaoEstacioComponent implements OnInit {
  dataSource:any[] = [];
  form: FormGroup;
  dropdownDepartamento = []
  modal: boolean = false;
  dropdownCampoOpcao:any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private departamentoService: DepartamentoService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      descricao_sallesforce: [null],
      departamento: [null],
      campo_personalizado_opcao: [null],
      whatsapp_polo: [null],
      whatsapp_qg: [null]
    });
  }

  listDepartamentos() {
    this.departamentoService.index().subscribe(departamentos => {
      this.dropdownDepartamento = departamentos;
    })
  }

  add() {
    this.listDepartamentos();
    this.modal = true;
    this.form.reset();
  }

}
