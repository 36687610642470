<p-tabView>
    <p-tabPanel *ngIf="direitoService.can('DIREITO_USUARIO_INSERIR')" header="Permissões de acesso">
        <app-access-permissions></app-access-permissions>
    </p-tabPanel>
    <p-tabPanel *ngIf="direitoService.can('CONFIGURACAO_INSERIR')" header="Parametrizações">
        <app-parameterization></app-parameterization>
    </p-tabPanel>
    <p-tabPanel header="Campos personalizados">
        <p-tabView>
            <p-tabPanel header="Pessoa">
                <app-campo-personalizado-pessoa></app-campo-personalizado-pessoa>
            </p-tabPanel>
            <p-tabPanel *ngIf="direitoService.can('CADASTRO_PRODUTO')" header="Produtos">
                <app-campo-personalizado-produto></app-campo-personalizado-produto>
            </p-tabPanel>
        </p-tabView>
    </p-tabPanel>
    <p-tabPanel *ngIf="conf['upload_planilha_estacio_sem_tratamento'] == true" header="Planilha Estacio">
        <app-planilha-estacio></app-planilha-estacio>
    </p-tabPanel>
    
</p-tabView>