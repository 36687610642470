<div class="text-center border-bottom">

    <div *ngIf="chatSelected.profilePicture" class="mb-2">
        <img src="{{chatSelected.profilePicture}}" class="rounded-circle avatar-lg img-thumbnail" alt="">
    </div>

    <div *ngIf="!chatSelected.profilePicture">
        <img src="assets/images/semimagem.jpg" class="rounded-circle avatar-lg img-thumbnail" alt="">
    </div>

    <h5 class="font-size-16 mb-1 text-truncate">{{chatSelected.name | translate}}</h5>
</div>

<p-accordion>

    <div *ngIf="load" class="text-center">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>

    <p-accordionTab header="Departamento e responsável">

        <div *ngIf="chatSelected != null && dropdownDepartamentos.length > 0 && direitoService.can('ALTERAR_ATENDENTE_CHAT')"
            class="p-fluid mt-4 row">

            <div class="col-sm-12">
                <label>Departamento principal</label>
                <p-dropdown class="p-inputtext-sm dropdown-custom" filter="true" [options]="dropdownDepartamentos"
                    [(ngModel)]="chatSelected.departamento_id" (onChange)="updateDepartamentoChat()"></p-dropdown>
            </div>


            <div class="col-sm-12">
                <label for="lastname2">Responsável principal</label>

                <p-progressSpinner *ngIf="loadingUsers" [style]="{width: '50px', height: '50px'}"
                    styleClass="custom-spinner" strokeWidth="8" fill="var(--surface-ground)"
                    animationDuration=".5s"></p-progressSpinner>

                <p-dropdown *ngIf="!loadingUsers" class="p-inputtext-sm dropdown-custom" filter="true" [options]="dropdownUsers"
                    [(ngModel)]="chatSelected.usuario_atendimento_atual_id" (onChange)="updateUsuario()"></p-dropdown>
            </div>


            <div class="col-sm-12 mt-4">
                <label>Outros Departamentos</label>
                <p-multiSelect class="p-inputtext-sm multiselect-custom" filter="true" [options]="dropdownDepartamentos"
                    [(ngModel)]="outros_departamentos" (onPanelHide)="updateDepartamentosChat()"></p-multiSelect>
            </div>

            <div class="col-sm-12">
                <label>Outros Responsáveis</label>

                <p-multiSelect class="p-inputtext-sm multiselect-custom" filter="true" [options]="dropdownUsers"
                    [(ngModel)]="outros_responsaveis" (onPanelHide)="updateResponsaveisChat()"></p-multiSelect>
            </div>


        </div>
    </p-accordionTab>



    <p-accordionTab header="Dados do contato" [selected]="true">
        <div class="p-2" style="height: calc(100vh - 450px); overflow: auto;">

            <div *ngFor="let item of camposPessoaChat" class="mb-2">
                <p class="text-muted mb-1">{{item['campo']}}</p>

                <input [disabled]="!item.editavel" *ngIf="item.tipo == 'STRING'" pInputText
                    class="form-control p-inputtext-sm input-custom" [(ngModel)]="item.value"
                    (blur)="saveDadoPessoa(item)" />

                <p-dropdown *ngIf="item.tipo == 'DROPDOWN'" [disabled]="!item.editavel" [filter]="true"
                    class="p-fluid dropdown-custom" placeholder="Selecione" [options]="item.opcoes"
                    [(ngModel)]="item.value" (onChange)="saveDadoPessoa(item)"></p-dropdown>

                <p-calendar *ngIf="item.tipo == 'DATE'" [disabled]="!item.editavel" class="p-inputtext-sm p-fluid"
                    dateFormat="dd/mm/yy" [(ngModel)]="item.value" (onChange)="saveDadoPessoa(item)"></p-calendar>

                <p-inputNumber *ngIf="item.tipo == 'CURRENCY'" disabled="!item.editavel" class="p-inputtext-sm p-fluid"
                    [(ngModel)]="item.value" inputId="value" mode="currency" currency="BRL"
                    (onChange)="saveDadoPessoa(item)">
                </p-inputNumber>

                <p-inputNumber *ngIf="item.tipo == 'NUMBER'" disabled="!item.editavel" class="p-inputtext-sm p-fluid"
                    inputId="integeronly" [(ngModel)]="item.value" (onChange)="saveDadoPessoa(item)">
                </p-inputNumber>

            </div>
        </div>
    </p-accordionTab>


    <p-accordionTab header="Tags" >
        <div class="p-fluid">
            <div class="col-md-12">
                <p-dropdown appendTo="body" class="p-inputtext-sm dropdown-custom" [(ngModel)]="tag" filter="true"
                    [options]="dropdownTags" placeholder="Adicionar tag" optionLabel="descricao"
                    (onChange)="selectTag()"></p-dropdown>
            </div>

            <div class="row d-flex p-2 mt-2" style="height: calc(100vh - 450px); overflow: auto;">
                <div *ngFor="let item of tagsSelected; let i = index" class="col-12 chat_tag_item rounded mr-2 mb-2"
                    style="color:#007bff; background:#ffffff;margin-right: 5px;">

                    <div class="pointer delete_chat_tag" data-chat-id="6408e91f7bc8dd798c230d35"
                        data-tag-text=" {{item.descricao}}" id="tagDiv" (mouseout)="mostrarIcone = false">
                        {{item.descricao}}<i style="    font-size: 12px;
                        border: 1px solid;
                        border-radius: 8px;
                        margin-left: 3px;
                        padding: 3px; cursor: pointer; color: red;" class="pi pi-times" (click)="removeTag(item)"></i>
                    </div>
                </div>
            </div>

            <!-- <p-panel>
                <ng-template pTemplate="header">
                    <p-dropdown appendTo="body" class="p-inputtext-sm ml-2" [(ngModel)]="tag" filter="true"
                        [options]="dropdownTags" placeholder="Adicionar tag" optionLabel="descricao"
                        (onChange)="selectTag()"></p-dropdown>
                </ng-template>


                <div class="row d-flex">
                    <div *ngFor="let item of tagsSelected; let i = index" class="col-12 chat_tag_item rounded mr-2 mb-2"
                        style="color:#007bff; background:#ffffff;margin-right: 5px;">

                        <div class="pointer delete_chat_tag" data-chat-id="6408e91f7bc8dd798c230d35"
                            data-tag-text=" {{item.descricao}}" id="tagDiv" (mouseout)="mostrarIcone = false">
                            {{item.descricao}}<i style="    font-size: 12px;
                            border: 1px solid;
                            border-radius: 8px;
                            margin-left: 6px;
                            padding: 3px; cursor: pointer; color: red;" class="pi pi-times"
                                (click)="removeTag(item)"></i>
                        </div>
                    </div>
                </div>
            </p-panel> -->
        </div>
    </p-accordionTab>

</p-accordion>