<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['nome']" [rowHover]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>


      </div>

      <div class="p-toolbar-group-right d-flex">


        <button type="button" class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr c>
      <th>Código</th>
      <th>Nome</th>
      <th style="max-width:100px;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td>{{item.id}}</td>
      <td>{{item.nome}}</td>
      <td style="max-width:100px;">
        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="showForm(item.id)" ngbTooltip="Editar"><i
            class="pi pi-pencil"></i></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Adicionar / Editar Usuário" [(visible)]="modal" [modal]="true" [style]="{width: '60vw'}"
  [draggable]="false">

  <div class="font-13 box-shadow-custom">
    <form [formGroup]="form" class="row g-2 p-3">


      <div class="col-sm-12">
        <label for="inputtext">Nome</label>
        <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="nome">
        <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
      </div>


    </form>
  </div>



  <ng-template pTemplate="footer">
    <div>
      <button (click)="modal=false" label="Fechar" type="button"
        class="btn btn-danger waves-effect waves-light me-1">Fechar</button>

      <button [disabled]="form?.invalid" (click)="save()" label="Salvar" type="button"
        class="btn btn-success waves-effect waves-light me-1">Salvar</button>
    </div>
  </ng-template>
</p-dialog>