<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 350px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['nome']" [rowHover]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr>
      <th>Nome</th>
      <th class="th-codigo"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td>{{item.nome}}</td>
      <td class="th-codigo">
        <a class="btn btn-danger btn mr-2" target="_blank" href="{{item.link}}">
          <i  class="pi pi-download"></i>
        </a>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>