<p-tabView>
 
 
  <p-tabPanel *ngIf="direitoService.can('DEPARTAMENTO_INSERIR') || direitoService.can('DEPARTAMENTO_ALTERAR')"
    header="Departamentos">
    <app-departamentos></app-departamentos>
  </p-tabPanel>

  <p-tabPanel header="Grupo de usuários">
    <app-grupo-usuario></app-grupo-usuario>
  </p-tabPanel>

  <p-tabPanel header="Usuários">
    <app-users></app-users>
  </p-tabPanel>

  <p-tabPanel *ngIf="direitoService.can('CADASTRO_PRODUTO')" header="Produtos">
    <app-produtos></app-produtos>
  </p-tabPanel>


  <p-tabPanel *ngIf="direitoService.can('FUNIL_INSERIR') || direitoService.can('FUNIL_ATERAR')"
    header="Funil de atendimento">
    <app-funnel></app-funnel>
  </p-tabPanel>


  <p-tabPanel *ngIf="direitoService.can('CAMPANHA_INSERIR') || direitoService.can('CAMPANHA_ALTERAR')"
    header="Campanha">
    <app-campanha></app-campanha>
  </p-tabPanel>
  <p-tabPanel *ngIf="direitoService.can('RESPOSTA_RAPIDA_INSERIR') || direitoService.can('RESPOSTA_RAPIDA_ALTERAR')"
    header="Resposta rapida">
    <app-resposta-rapida></app-resposta-rapida>
  </p-tabPanel>

  <p-tabPanel *ngIf="direitoService.can('INSERIR_GATILHOS_CHAT') || direitoService.can('ALTERAR_GATILHOS_CHAT')"
    header="Dialogos">
    <app-gatilhos></app-gatilhos>
  </p-tabPanel>

  <p-tabPanel *ngIf="direitoService.can('TAG_CADASTRO_INSERIR') || direitoService.can('TAG_CADASTRO_ALTERAR')"
    header="Tags">
    <app-tag></app-tag>
  </p-tabPanel>

  <p-tabPanel header="Links"
    *ngIf="direitoService.can('LINK_CADASTRO_INSERIR') || direitoService.can('LINK_CADASTRO_ALTERAR')">
    <app-link></app-link>
  </p-tabPanel>

  <p-tabPanel header="Lead" *ngIf="direitoService.can('LEAD_TICKET_SHOW')">
    <p-tabView>
      <p-tabPanel *ngIf="configuracao.modulo_carteira && direitoService.can('CARTEIRA_CLIENTE')" header="Carteiras">
        <app-carteira-cliente></app-carteira-cliente>
      </p-tabPanel>
    </p-tabView>
    <!-- <app-lead-ticket></app-lead-ticket> -->
  </p-tabPanel>