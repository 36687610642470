import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsumoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'insumo');
  }

  getCamposPersonalizados(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/campos-personalizados?id=${id}`);
  }

  linkFileProduto(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/arquivos`, body);
  }

  
}
