import { Component, OnInit, ViewChild, TemplateRef, SimpleChanges, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { chat, groups } from './data';
import { Chats, Groups } from './chat.model';

import { Lightbox } from 'ngx-lightbox';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';

// Date Format
import { DatePipe } from '@angular/common';
import { ChatService } from 'src/app/core/services/chat.service';

import moment from 'moment-timezone';
import { forkJoin } from 'rxjs';
import { CdTimerComponent } from 'angular-cd-timer';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import { TagService } from 'src/app/core/services/tag.service';
import { PessoaService } from 'src/app/core/services/pessoa.service';
import { RespostaRapidaService } from 'src/app/core/services/resposta-rapida.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import { ChatInternoService } from 'src/app/core/services/chat-interno.service';
import { OrigemLeadService } from 'src/app/core/services/origem-lead.service';
import { QualificacaoTicketService } from 'src/app/core/services/qualificacao-ticket.service';
import { InteresseTicketService } from 'src/app/core/services/interesse-ticket.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FunilAtendimentoService } from 'src/app/core/services/funil-atendimento.service';
import { DireitoService } from 'src/app/core/services/direito.service';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';
import { GatilhoService } from 'src/app/core/services/gatilho.service';
import { io, Socket } from 'socket.io-client';
import Quill from 'quill';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AgendaTipoService } from 'src/app/core/services/agenda-tipo.service';
import { AgendaService } from 'src/app/core/services/agenda.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  providers: [MessageService]

})

/**
 * Chat-component
 */
export class IndexComponent implements OnInit {
  @ViewChild('galleryContent') galleryContent: TemplateRef<any>;
  @ViewChild('basicTimer') basicTimer!: CdTimerComponent;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('op') op: OverlayPanel;
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  notification;
  contextGain: any;
  context: any;
  oscillator: any;

  labelDialogoAguardando;
  modalAguardando: boolean = false;;

  scrollAltura: number = 0;

  outros_departamentos: any[] = []
  outros_responsaveis: any[] = []
  array_historico: any[] = []

  loadingNovaConversa = false;

  loadingDialogos = false;

  evolutionSocket;

  fastResponseListNovaConversa = [];

  respostaRapidaNovaConversa = false;

  modalMensagemAgendada = false;

  modalAnotacaoInterna = false;

  atendimentoFilter = true;

  aguardandoFilter = true;

  finalizadoFilter = false;

  showFunil = false;

  positionModlFunil = 'top'


  negociacaoList = [];

  dropdownSituacaoFiltro = [
    { value: '0', label: 'Aberto' },
    { value: '1', label: 'Em andamento' },
    { value: '2', label: 'Finalizado' },
  ]

  situacaoFiltroSelected = ['0', '1'];


  activetab = 2;
  chat: any[];
  chatNotStarted = [];
  chatInternoList = [];
  groups: Groups[];
  formData!: FormGroup;
  formDialogo!: FormGroup;
  @ViewChild('scrollRef') scrollRef: any;
  emoji = '';
  isreplyMessage = false;
  isgroupMessage = false;
  mode: string | undefined;
  public isCollapsed = true;
  contactSelected;
  urlStorage;
  sharedFilesHistoricList = [];
  mensagemLinkAtendimento;
  confirmationLinkAtendimentoUsuario: boolean = false
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  lang: string;
  images: { src: string; thumb: string; caption: string }[] = [];
  piesocket: any;
  chatSelected;
  telaResponder = false;
  mensagem_id_memoria;
  newMessage: any = null;
  showResponse = false;
  messageSelected;
  recorder = false;
  navigatorDevice: any;
  localStream;
  mediaRecord;
  chunks = [];
  srcAudioRecord;
  showAudio = false;
  loadMessages = false;
  modalShare = false;
  forwardedMessage;
  loadChats = false;
  rowsListAtendimento = 100;
  showLeftSidebar = true;
  exibir_filtro_avancado = false;


  dropdownStatus = [
    { value: 0, label: 'Aguardando atendimento' },
    { value: 1, label: 'Atendimento em andamento' },
    { value: 2, label: 'Atendimento finalizado' },
  ]

  countChatNotStarted = 0;
  countChatStarted = 0;
  countNewMessagesChat = 0;
  countNewMessagesNotStarted = 0;
  countNewMessagesGroups = 0;
  countGroups = 0;
  section = 'chat'
  itemsStatus: any[] = [];
  dropdownUsers = [];
  dropdownTags = [];
  tagsSelected: any[] = [];
  modalNotepad = false;
  fastResponseList = [];
  dropdownGroupsWhatsapp = [];
  groupImport;
  dropdownDepartamentos = [];
  departamentoGroup = null;
  dropDownUsuariosDepartamentoGrupo = [];
  usuariosGroupParticipant = [];
  loadingImportGroup = false;
  firstLoading = true;
  intervalChatNotStarted;
  intervalWebsocket;
  intervalGroupList;
  respostaRapida = false;
  atalhoRespostaRapida = null;
  nomeFilter;
  aparelhoFilter;
  departamentoFilter;
  mensagemFilter;
  semDepartamentoFilter: boolean = false
  semRespostaFilter: boolean = false
  modalHistorico: boolean = false
  senderName: any;
  senderProfile: any;
  loadingContatos = false;
  loadSearch: boolean = false

  ordemFIlter = 'DESC';
  dropdownOrdem = [
    { value: 'DESC', label: 'Mensagens recebidas mais recentes' },
    { value: 'ASC', label: 'Mensagens recebidas mais antigas' },
  ]

  galleryList = [];
  modalGallery = false;
  intervalListChatAtendimento;

  intervalListMessagesChat;
  intervalListChatInterno;
  intervalListMessagesChatInterno;
  tagFilter;

  mensagem_a_responder: any
  remetente_a_responder: any
  nome_usuario: any // USADO PARA DEFINIR O NOME NA RESPOSTA

  dropdownStatusFilter = [
    { value: 'ATENDIMENTO', label: 'Em andamento' },
    { value: 'FINALIZADO', label: 'Finalizadas' },
  ]

  statusFilter = 'ATENDIMENTO';

  notListChat = false;
  modalChatInterno = false;
  userChatInterno;
  dropdownUsuario = [];
  userLogado = JSON.parse(localStorage.getItem('user'));
  visualizarTodosChat = false;
  modalFinalizarAtendimento = false;
  showFilterAdvanced = false;
  modalContatos = false;
  contacts = [];
  totalRecordsContatos = 0;

  userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  dropdownDepartamentoFiltroAvancado = [];


  objetoFinalizar: any = {
    inicioAtendimento: null,
    tempoAtendimento: null,
    responsavel: null,
    interesse: null,
    origem: null,
    qualificacao: null,
    relato: null
  }
  dropdownOrigemLead = [];


  dropDownQualificacao: any[] = [
    // { value: '', label: 'Selecione tipo' },
    // { value: 'NENHUMA', label: 'Nenhuma qualificação' },
    // { value: 'PRECO', label: 'Preço de produtos' },
    // { value: 'RECLAMACAO_SUGESTAO', label: 'Reclamação/Sugestão' },
    // { value: 'P_CONCLUIDO', label: 'Pedido Concluído' },
    // { value: 'P_CANCELADO', label: 'Pedido Cancelado' },
    // { value: 'P_PRODUTO', label: 'Pedido de Produtos (não tem na loja)' },
    // { value: 'ACOMPANHAMENTO', label: 'Acompanhamento Entrega' },
  ]

  dropDownInteresse: any[] = [
    // { value: '', label: 'Selecione tipo' },
    // { value: 'NENHUMA', label: 'Nenhum interesse' },
    // { value: 'DUVIDA', label: 'Dúvidas entrega' },
    // { value: 'VALOR', label: 'Valores' },
    // { value: 'COMPRA', label: 'Compras' },
    // { value: 'DELIVERY', label: 'Delivery' },
  ]

  dropdownFunilEtapa = [];
  dropdownFunilEtapaFilter = [];
  dropdownFunil = [];
  etapaFunilSelected;

  sourceContatos = [];

  urlS3 = environment.urlS3;
  nomeFilterContato;
  selectedContatos = [];
  funilFilter;
  chatLinkAtendimentoId;
  userFilter = null;
  dropdownUsuarioFilter = [];
  whatsappCelular;
  whatsappFixo;
  typeTicketFilter = 0;
  addMessageBlock = false;
  pauseLoadMessages = false;


  showModuloAgenda = false;

  messagesAgrupada = [];

  conversasFinalizadas = [];

  formAtendimento: FormGroup;

  modalNewChat = false;

  dropdownCanalAtendimento = [];

  dropdownGatilho

  globalFilters = [];

  intervalStatusMensagem;


  camposPessoaChat = [];

  modalDialogo = false;

  dropdownCanalAtendimentoFiltroAvancado = [];

  showButtonCarregandoConversas = false;


  configPlataforma;

  pageConversas = 0;

  carregandoConversasScrool = false;

  showDadosContato = true;

  dropdownTipoAgenda = [];

  countAgendaDia = 0;

  canal_atendimento_id;

  constructor(private authFackservice: AuthfakeauthenticationService, private authService: AuthenticationService,
    private router: Router, public translate: TranslateService, private modalService: NgbModal, private offcanvasService: NgbOffcanvas,
    public formBuilder: FormBuilder, private datePipe: DatePipe, private lightbox: Lightbox, private chatService: ChatService,
    private confirmationService: ConfirmationService,
    private el: ElementRef,
    private renderer: Renderer2,
    private usuarioService: UsuarioService,
    private tagService: TagService,
    private pessoaService: PessoaService,
    private respostaRapidaService: RespostaRapidaService,
    private departamentoService: DepartamentoService,
    private chatInternoService: ChatInternoService,
    private origemLeadService: OrigemLeadService,
    private qualificacaoTicketService: QualificacaoTicketService,
    private interesseTicketService: InteresseTicketService,
    private funilAtendimentoService: FunilAtendimentoService,
    public direitoService: DireitoService,
    private canalAtendimentoService: IntegracaoWhatsappService,
    private gatilhoService: GatilhoService,
    private config: PrimeNGConfig,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private agendaTipoService: AgendaTipoService,
    private agendaService: AgendaService,
  ) { }

  /**
  * Open lightbox
  */
  openImage(index: number, i: number): void {
    // open lightbox
    this.lightbox.open(this.message[index].imageContent, i, {
      showZoom: true
    });

  }

  listQualificacao() {
    this.qualificacaoTicketService.index().subscribe(json => {
      this.dropDownQualificacao = json.map(item => { return { value: item.id, label: item.nome } });
    })
  }

  listInteresse() {
    this.interesseTicketService.index().subscribe(json => {
      this.dropDownInteresse = json.map(item => { return { value: item.id, label: item.nome } });
    })
  }

  historicoChat() {
    this.array_historico = [];

    this.chatService.historicoChat(this.chatSelected.id).subscribe(json => {

      json.map(item => {
        this.array_historico.push({
          status: item.historico,
          date: moment.utc(item.created_at).tz(this.userTimezone).format("DD/MM/YYYY (HH:mm)"),
          user: item?.usuario?.name || '--'
        })
      })
      this.modalHistorico = true

    })
  }

  typeTicket(type) {
    this.typeTicketFilter = type;
    this.filterAvancado();
  }

  classBtnTypeTicket(type) {
    if (this.typeTicketFilter == type) {
      return 'btn btn-success btn-sm'
    } else {
      return 'btn btn-secondary btn-sm';
    }
  }


  showLabelStatus() {
    if (this.statusFilter == 'ATENDIMENTO') {
      return 'Em andamento';
    } else {
      return 'Finalizadas'
    }
  }



  ngOnInit(): void {
    this.config.setTranslation({
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
        'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    });

    this.pageConversas = 0;

    this.nome_usuario = this.userLogado.name
    this.tagFilter = null;

    this.configPlataforma = JSON.parse(localStorage.getItem('configuracao'));


    if (this.configPlataforma['s3']) {
      this.urlStorage = environment.urlS3;
    } else {
      this.urlStorage = environment.urlBase + '/storage'
    }

    if (this.configPlataforma['modulo_agenda']) {
      this.showModuloAgenda = true;
    } else {
      this.showModuloAgenda = false;
    }

    document.body.setAttribute('data-layout-mode', 'light');

    // Validation
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
      agenda: ['', [Validators.required]],
      anotacaoInterna: [''],
      criarEvento: [false],
      tipoEvento: [null],
      tituloEvento: [null],
      dataEvento: [null],
    });

    const user = window.localStorage.getItem('user');
    this.senderName = JSON.parse(user).name
    this.senderProfile = 'assets/images/users/perfil.png'
    this.chat = chat;
    this.groups = groups;
    this.lang = this.translate.currentLang;
    this.onListScroll();
    this.listAllTags();
    this.listUsuarios();

    this.chat = [];

    this.listChatAtendimento();

    this.getAgendaDia();

    setTimeout(() => {
      this.firstLoading = false;
    }, 2000);

    this.intervalListChatAtendimento = setInterval(() => {
      if (this.activetab == 2) {
        this.refreshListChatAtendimentos();
      }
    }, 10000);

    this.intervalListMessagesChat = setInterval(() => {
      if (this.activetab == 2) {
        this.refreshListMessagesChat();
      }
    }, 15000);

    // this.intervalListMessagesChatInterno = setInterval(() => {
    //   if (this.activetab == 2) {
    //     this.refreshListMessagesChatInterno();
    //   }
    // }, 6000);

    // this.intervalListChatInterno = setInterval(() => {
    //   if (this.activetab == 2) {
    //     this.refreshChatInterno();
    //   }
    // }, 10000)

    //this.listChatsNotStarted();

    this.websocket();
    // this.websocketEvolution();

    let mode = localStorage.getItem('templateMode');
    if (mode != null) {
      this.changeMode(mode);
    } else {
      this.changeMode('light');
    }

    this.intervalWebsocket = setInterval(() => {
      this.websocket();
    }, 30000);


    this.intervalChatNotStarted = setInterval(() => {
      //this.listChatsNotStarted();
    }, 10000);


    this.intervalGroupList = setInterval(() => {
      this.listGroups();
    }, 10000);


    this.intervalStatusMensagem = setInterval(() => {
      this.getUpdateMensagemChat();
    }, 10000);


    this.listDepartamentos();

    this.listGroups();

    this.listQualificacao();

    this.listInteresse();

    this.listAllFunilAndEtapas();

    this.listCanalAtendimentos();

    let direitos = JSON.parse(localStorage.getItem('direito'));
    let visualizarTodosChat = direitos.find(d => { return d.flag == 'VISUALIZAR_TODOS_CHAT' });
    if (visualizarTodosChat != undefined) {
      this.visualizarTodosChat = true;
    }

    this.formAtendimento = this.formBuilder.group({
      canal_atendimento_id: [null, Validators.required],
      resposta_rapida: [],
      gatilho_id: [null],
      whatsapp: [null, Validators.required],
      nome: [null, Validators.required],
      anotacaoInterna: [null],
    });

    this.formDialogo = this.formBuilder.group({
      id: [null],
    })
  }

  listAllTags() {
    this.tagService.index().subscribe(json => {
      this.dropdownTags = json.map(item => { return { value: item.id, label: item.descricao } })
      this.dropdownTags.unshift({ value: null, label: 'Todos' });
    });
  }

  closeFilterAvancado() {
    this.exibir_filtro_avancado = false;
  }

  openFilterAvancado(event) {
    this.exibir_filtro_avancado = true;
  }

  filterAvancado() {
    this.pageConversas = 1;
    this.loadChats = true;
    this.chatSelected = undefined;
    this.message = [];
    this.messagesAgrupada = [];
    this.chat = [];
    // this.exibir_filtro_avancado = false;

    if (this.activetab == 2) {
      this.listChatAtendimento();
      this.closeFilterAvancado();
    }
  }

  clearFilterAvancado() {
    this.nomeFilter = null;
    this.mensagemFilter = null;
    this.statusFilter = 'ATENDIMENTO';
    this.whatsappCelular = null;
    this.whatsappFixo = null;
    this.userFilter = null;
    this.tagFilter = null;
    this.aparelhoFilter = null;
    this.departamentoFilter = null;
    this.semDepartamentoFilter = false;
    this.filterAvancado()
  }

  refreshListChatAtendimentos() {
    if (!this.exibir_filtro_avancado) {
      this.listChatAtendimento();
    }
  }

  refreshChatInterno() {
    if (this.activetab == 12) {
      this.listChatInterno();
    }
  }


  refreshListMessagesChat() {
    if (!this.chatSelected) {
      return;
    }

    if (this.loadMessages) {
      return;
    }

    if (this.activetab == 12) {
      return;
    }

    if (this.pauseLoadMessages) {
      return;
    }

    this.loadMessages = true;

    this.chatService.getMessages(this.chatSelected.id).subscribe(json => {
      this.loadMessages = false;
      json?.forEach((mensagem: any) => {
        this.addMessage(mensagem, 'CLIENTE');
      });
    })
  }

  refreshListMessagesChatInterno() {
    if (!this.chatSelected) {
      return;
    }

    if (this.loadMessages) {
      return;
    }

    if (this.activetab != 12) {
      return;
    }

    this.loadMessages = true;

    this.chatInternoService.getMensagens(this.chatSelected.id).subscribe(json => {
      this.loadMessages = false;
      let usuario = JSON.parse(localStorage.getItem('user'));
      json?.mensagens.forEach((mensagem: any) => {
        this.addMessageInterno(mensagem, usuario.id);
      });
    })
  }

  ngOnDestroy(): void {
    if (this.intervalListMessagesChat) {
      clearInterval(this.intervalListMessagesChat)
    }

    if (this.intervalChatNotStarted) {
      clearInterval(this.intervalChatNotStarted)
    }

    if (this.intervalListChatAtendimento) {
      clearInterval(this.intervalListChatAtendimento)
    }

    if (this.intervalWebsocket) {
      clearInterval(this.intervalWebsocket)
    }

    if (this.intervalGroupList) {
      clearInterval(this.intervalGroupList)
    }

    if (this.intervalListMessagesChatInterno) {
      clearInterval(this.intervalListMessagesChatInterno)
    }

    if (this.intervalListChatInterno) {
      clearInterval(this.intervalListChatInterno)
    }

    if (this.intervalStatusMensagem) {
      clearInterval(this.intervalStatusMensagem)
    }
  }

  listDepartamentos() {
    this.dropdownDepartamentoFiltroAvancado = [{ value: null, label: 'Todos' }];

    this.usuarioService.getDepartamentos().subscribe(json => {
      json.map(departamento => {
        this.dropdownDepartamentos.push({ value: departamento.id, label: departamento.nome });
        this.dropdownDepartamentoFiltroAvancado.push({ value: departamento.id, label: departamento.nome });
      })
    })
  }

  listUsuarios() {
    console.log('litusuarios');

    this.dropdownUsuarioFilter = [];

    let direitos = JSON.parse(localStorage.getItem('direito'));

    let usuarioSessao = JSON.parse(localStorage.getItem('user'));

    this.usuarioService.index().subscribe(json => {

      json.map(usuario => {
        this.dropdownUsers.push({ value: usuario.id, label: `${usuario.name}` });
      });


      let VISUALIZAR_TODOS_CHAT = direitos.find(d => { return d.flag == 'VISUALIZAR_TODOS_CHAT' });

      if (VISUALIZAR_TODOS_CHAT != undefined) {

        json.map(usuario => {
          this.dropdownUsuarioFilter.push({ value: usuario.id, label: usuario.email });
        });


      } else {


        let VISUALIZAR_CHAT_DEPARTAMENTO = direitos.find(d => { return d.flag == 'VISUALIZAR_CHAT_DEPARTAMENTO' });

        if (VISUALIZAR_CHAT_DEPARTAMENTO != undefined) {

          json.map(usuario => {
            if (usuario.departamento_id == usuarioSessao.departamento_id) {
              this.dropdownUsuarioFilter.push({ value: usuario.id, label: usuario.email });
            }
          });

        } else {
          json.map(usuario => {
            if (usuario.id == usuarioSessao.id) {
              this.dropdownUsuarioFilter.push({ value: usuario.id, label: usuario.email });
            }
          });
        }

      }




      this.dropdownUsuarioFilter.unshift({ value: null, label: 'Todos' });
    });




  }

  changeDepartamentoGrupo() {

    this.dropDownUsuariosDepartamentoGrupo = [];
    this.usuarioService.listByDepartamento(this.departamentoGroup).subscribe(json => {
      json.map(item => {
        this.dropDownUsuariosDepartamentoGrupo.push({
          value: item.id,
          label: item.email,
        });
      })
    })
  }

  onScrollEnd() {
    if (this.chat.length >= 50) {
      this.showConversas();
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {



    if (this.activetab == 5) {
      this.section = 'chat';
    }
  }

  ngAfterViewInit() {
    this.scrollRef.SimpleBar.getScrollElement().scrollTop = 100;
  }

  /**
   * Show user profile
   */
  // tslint:disable-next-line: typedef
  showUserProfile() {
    document.getElementById('profile-detail').style.display = 'block';
  }

  listGroupsExisting() {
    this.chatService.listGroupsWhatsapp().subscribe((json) => {
      json.map((item) => {
        this.dropdownGroupsWhatsapp.push({
          label: item.name,
          value: item.name,
        });
      });
    });
  }

  /**
   * Close user chat
   */
  // tslint:disable-next-line: typedef
  closeUserChat() {
    document.getElementById('chat-room').classList.remove('user-chat-show');
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else if (environment.defaultauth === 'fackbackend') {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }

  /**
   * Set language
   * @param lang language
   */
  setLanguage(lang) {
    this.translate.use(lang);
    this.lang = lang;
  }

  openCallModal(content) {
    this.modalService.open(content, { centered: true });
  }

  openVideoModal(videoContent) {
    this.modalService.open(videoContent, { centered: true });
  }

  listGroups() {
    if (this.activetab != 3) {
      return;
    }

    this.chatService.listGroup().subscribe(groups => {
      this.loadChats = false;

      this.countGroups = groups.total;

      let countNewMessageGroup = 0;

      groups.data.map(group => {
        countNewMessageGroup += group.qtd_novas_mensagens || 0;



        if (this.activetab == 3) {

          let groupExists = this.chat.find(c => { return c.id == group.id });

          if (groupExists == undefined) {
            this.chat.push({
              id: group.id,
              name: group.nome_grupo,
              unread: group.count_membros,
              whatsapp_contato: group.whatsapp_contato,
              countNewMessage: group.qtd_novas_mensagens,
              newMessage: group.qtd_novas_mensagens > 0 ? true : false,
              messages: []
            })
          }
        }
      });

      this.countNewMessagesGroups = countNewMessageGroup;
    })
  }

  listOutrosResponsaveis(event) {
    this.outros_responsaveis = []
    this.chatService.getOthersRes(event).subscribe(json => {
      json.map(item => {
        this.outros_responsaveis.push(item.usuario_responsavel_id);
      });
    })
  }

  listOutrosDepartamentos(event) {
    this.outros_departamentos = []
    this.chatService.getOthersDep(event).subscribe(json => {
      json.map(item => {
        this.outros_departamentos.push(item.departamento_id);
      });
    })
  }

  showAbaChat(activetab, section = 'chat') {

    this.activetab = activetab;
    this.section = section;
    this.showFilterAdvanced = false;

   

    if (activetab != 7) {
      this.showLeftSidebar = true;
    } else {
      this.showLeftSidebar = false;
    }


    this.loadChats = true;

    try {
      document.querySelector('.user-chat').classList.add('d-none');
      document.querySelector('.load-messages').classList.remove('load-messages-show');
      document.querySelector('.chat-welcome-section').classList.remove('d-none');
      document.querySelector('.load-messages').classList.add('d-none');
    } catch (error) {

    }

    if (activetab == 2) {
      this.pageConversas = 1;
      this.chatSelected = undefined;
      this.message = [];
      this.messagesAgrupada = [];
      this.chat = [];

      this.getAgendaDia();

      if (this.configPlataforma.visao_360) {
        this.showDadosContato = true;
      } else {
        this.showDadosContato = false;
      }

      // this.listChatAtendimento();
      this.clearFilterAvancado();
    } else if (activetab == 6) {
      this.chatSelected = undefined;
      this.message = [];
      this.messagesAgrupada = [];
      this.chatNotStarted = [];
      //this.listChatsNotStarted();
    } else if (activetab == 3) {
      this.chatSelected = undefined;
      this.message = [];
      this.messagesAgrupada = [];
      this.chat = [];
      this.listGroups();
    } else if (activetab == 7) {
    } else if (activetab == 12) {
      this.chatSelected = undefined;
      this.message = [];
      this.messagesAgrupada = [];
      this.chatInternoList = [];
      this.listChatInterno();
    } else if (activetab == 13) {
    } else if (activetab == 14) {
      this.chatSelected = undefined;
      this.message = [];
      this.messagesAgrupada = [];
      this.chat = [];
      this.listConversasFinalizadas();
    }
  }

  /**
   * Show user chat
   */
  // tslint:disable-next-line: typedef
  userName: any = 'Doris Brown';
  userStatus: any = 'online';
  userProfile: any;
  message: any[] = [];

  showChat(event: any, id: any) {

    this.showResponse = false




    this.sharedFilesHistoricList = [];

    let usuario = JSON.parse(localStorage.getItem('user'));


    if (this.loadMessages) {
      return;
    }

    var removeClass = document.querySelectorAll('.chat-user-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });

    var removeClass = document.querySelectorAll('.chat-group-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });

    try {
      event.target.closest('li').classList.add('active');
    } catch (error) {
    }

    var data = null;

    if (this.activetab == 2 || this.activetab == 3) {
      data = this.chat.find((chat: any) => {
        return chat.id === id;
      });

      this.etapaFunilSelected = data?.etapa_id || null;
    }

    if (this.activetab == 6) {
      data = this.chatNotStarted.find((chat: any) => {
        return chat.id === id;
      });
    }

    if (this.activetab == 12) {
      data = this.chatInternoList.find((chat: any) => {
        return chat.id === id;
      });
    }

    if (this.activetab == 14) {
      data = this.conversasFinalizadas.find((chat: any) => {
        return chat.id === id;
      });
    }

    this.chatSelected = data;
    this.listOutrosResponsaveis(this.chatSelected.id)
    this.listOutrosDepartamentos(this.chatSelected.id)
    this.listCamposContato();


    if (this.activetab == 2 || this.activetab == 6) {
      this.pessoaService.tagLinked(this.chatSelected.codigoCliente).subscribe(json => {
        this.chatSelected.tagsCliente = json;
      })
    }

    if (this.activetab == 2) {
      if (this.chatSelected.countNewMessage > 0) {
        if (this.countNewMessagesChat > 0) {
          this.countNewMessagesChat = this.countNewMessagesChat - this.chatSelected.countNewMessage;
        }
      }
    } else if (this.activetab == 6) {
      if (this.chatSelected.countNewMessage > 0) {
        if (this.countNewMessagesNotStarted > 0) {
          this.countNewMessagesNotStarted--;
        }
      }
    } else if (this.activetab == 3) {
      if (this.chatSelected.countNewMessage > 0) {
        if (this.countNewMessagesGroups > 0) {
          this.countNewMessagesGroups--;
        }
      }
    }


    this.userName = data.name
    this.userStatus = data.status
    this.userProfile = data.profilePicture

    if (!usuario.modo_espiao) {
      this.chatSelected.newMessage = false;
      this.chatSelected.countNewMessage = 0
    }

    this.chatSelected.notRead = false;

    this.message = [];
    this.messagesAgrupada = [];

    this.negociacaoList = [];

    this.pessoaService.getNegociacoes(this.chatSelected['codigoCliente']).subscribe(json => {
      this.negociacaoList = json;

      this.listAllFunil();
    })

    this.loadMessages = true;

    document.querySelector('.user-chat').classList.add('d-none');
    document.querySelector('.load-messages').classList.add('load-messages-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.load-messages').classList.remove('d-none');

    if (this.activetab == 12) {

      this.chatInternoService.getMensagens(data.id).subscribe(json => {

        json?.mensagens.forEach((mensagem: any) => {
          this.addMessageInterno(mensagem, undefined);
        });

        this.loadMessages = false;

        document.querySelector('.load-messages').classList.add('d-none');

        document.querySelector('.user-chat').classList.add('user-chat-show');
        document.querySelector('.user-chat').classList.remove('d-none');


        this.onListScroll();
      })
    } else {
      this.chatService.getMessages(data.id).subscribe(json => {
        this.loadMessages = false;

        this.pauseLoadMessages = true;

        json?.forEach((mensagem: any) => {
          this.addMessage(mensagem);
        });



        this.pauseLoadMessages = false;



        document.querySelector('.load-messages').classList.add('d-none');

        document.querySelector('.user-chat').classList.add('user-chat-show');
        document.querySelector('.user-chat').classList.remove('d-none');


        this.onListScroll();
      })
    }
  }

  getDates(): string[] {
    return Object.keys(this.messagesAgrupada);
  }

  formatDate(d) {
    let day = '' + d.getDate();
    let month = '' + (d.getMonth() + 1);
    const year = d.getFullYear();

    if (day.length < 2) day = '0' + day;
    if (month.length < 2) month = '0' + month;

    return [day, month, year].join('/');
  }

  aggMensagemPorData(data) {
    const today = this.formatDate(new Date());

    let groupedData = data.reduce((acc, item) => {
      const date = item.createdAtGroup === today ? 'Hoje' : item.createdAtGroup;

      if (date != undefined) {
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
      }


      return acc;
    }, {});

    return groupedData;

  }

  addMessageInterno(item, usuarioOrigem = undefined) {
    let usuario = JSON.parse(localStorage.getItem('user'));

    let msgExists = this.message.find(m => { return m.id == item.id });

    if (msgExists == undefined && item.chat_interno_id == this.chatSelected.id) {

      if (usuarioOrigem == undefined || item.usuario_id != usuarioOrigem) {
        let toDay = false;

        let toDayMensagem = this.message.find(m => { return m.isToday == true });
        if (toDayMensagem != undefined) {
          toDay = true;
        }


        let msg: any = {
          id: item.id,
          name: item.nome_emitente || item.numero_emitente,
          profile: null,
          time: 'chat.messages.1.time',
          isLista: false,
          type: item.tipo,
          mensagem_resposta: null,
          read: item.data_hora_lido,
          delivery: true,
        }

        if (item.usuario_id == usuario.id) {
          msg = { ...msg, align: 'right' }
        }


        if (item.tipo == 'audio') {
          msg = { ...msg, isAudio: true, fileContent: 'Arquivo de audio', conteudo: `${this.urlStorage}/${item.conteudo}` }
          this.sharedFilesHistoricList.push(item);
        } else if (item.tipo == 'text') {

          msg = { ...msg, isText: true, conteudo: item.conteudo }
        } else if (item.tipo == 'image') {
          let imageContent = [
            { id: item.id, conteudo: `${this.urlStorage}/${item.conteudo}`, caption: item.legenda_arquivo, thumb: '' },
          ];
          msg = { ...msg, isImage: true, imageContent: imageContent }
          this.sharedFilesHistoricList.push(item);
        } else if (item.tipo == 'document') {
          msg = { ...msg, isfile: true, fileContent: 'Documento', conteudo: `${this.urlStorage}/${item.conteudo}` }
          this.sharedFilesHistoricList.push(item);
        } else if (item.tipo == 'video') {
          msg = { ...msg, isVideo: true, fileContent: 'Video', conteudo: `${this.urlStorage}/${item.conteudo}` }
          this.sharedFilesHistoricList.push(item);
        } else if (item.tipo == 'location') {
          let location = JSON.parse(item.conteudo);
          msg.isLocation = true;
          msg.linkLocation = `https://www.google.com/maps?q=${Number(location.latitude)},${Number(location.longitude)}`
          msg.locationOption = {
            center: { lat: Number(location.latitude), lng: Number(location.longitude) },
            zoom: 12
          }
        } else if (item.tipo == 'contact') {
          let conteudo = JSON.parse(item.conteudo);
          msg = { ...msg, isContact: true, conteudo: conteudo }
        }


        let otherDate;
        if (item.id == null) {
          otherDate = moment(item.created_at).toDate();
        } else {
          otherDate = moment(item.created_at).toDate();
        }
        let todayDate = new Date();

        if (
          otherDate.getDate() === todayDate.getDate() &&
          otherDate.getMonth() === todayDate.getMonth() &&
          otherDate.getFullYear() === todayDate.getFullYear()
        ) {

          msg.time = moment(item.created_at).format("HH:mm");

          if (toDay == false) {
            toDay = true;
            this.message.push({ isToday: true });
          }

        } else {
          msg.time = moment(item.created_at).format("HH:mm");
        }



        this.message.push(msg);

        this.onListScroll();
      }


    }
  }

  notificationMessage(title, msg, chat_id) {

    const icon = "https://via.placeholder.com/50x50";
    const song = 'sine.mp3';

    if (!("Notification" in window)) {
      return;
    } else {
      if (Notification.permission == 'granted') {
        this.callNotify(title, msg, icon, chat_id);
        return;
      } else {
        Notification.requestPermission(permission => {
          if (permission === 'granted') {
            this.callNotify(title, msg, icon, chat_id);
            return;
          }
        })
      }
    }
  }

  callNotify(title, msg, icon, chat_id) {
    let message = new Notification(title, { body: msg, icon: icon });
    let page = this;
    message.onclick = function () {
      // let chat = page.chatList.find(x => { return x.id == chat_id });
      // page.onSelectChat(chat);

    };
    // new Audio(song).play();
    this.stopAudioNotification();
  }


  startAudioNotification() {
    this.context = new AudioContext();
    this.oscillator = this.context.createOscillator();
    this.contextGain = this.context.createGain();

    this.oscillator.connect(this.contextGain);
    this.contextGain.connect(this.context.destination);
    this.oscillator.start(0);
  }

  stopAudioNotification() {
    this.startAudioNotification();
    this.contextGain.gain.exponentialRampToValueAtTime(
      0.00001, this.context.currentTime + 4
    )
  }

  addMessage(item, origem = 'ALL') {
    let msgExists = this.message.find(m => { return m.id == item.id });

    if (msgExists == undefined || item.id == null && item.chat_contato_id == this.chatSelected.id) {
      this.addMessageBlock = true;


      if (origem == item.origem || origem == 'ALL') {

        // let msgNotification = wss.data.chat.nome_cliente || wss.data.chat.whatsapp_contato;

        let toDay = false;

        let toDayMensagem = this.message.find(m => { return m.isToday == true });
        if (toDayMensagem != undefined) {
          toDay = true;
        }


        let msg: any = {
          id: item.id,
          name: item.nome_emitente || item.numero_emitente,
          profile: this.userProfile,
          time: 'chat.messages.1.time',
          appSend: item.plataforma_emitente,
          isLista: false,
          type: item.tipo,
          delivery: item.recebida,
          read: item.data_hora_lido || null,
          mensagem_resposta: item?.mensagem_resposta,
          mensagem_resposta_conteudo: item?.mensagem_resposta?.conteudo,
          url_campanha: item?.url_campanha || null,
          descricao_campanha: item?.descricao_campanha || null,
          excluida: item?.excluida || false,
          waiting_message: item?.aguardando_mensagem,
          observacao_interna: item?.observacao_interna,
          zapiMessageId: item?.zapiMessageId,
          createdAtGroup: item?.created_at_format,
          agendamento: item?.mensagem_agendada,
          status_agendamento: item?.status,
          origem: item?.origem,
          data_hora_agenda: item.data_hora_agenda ? moment.utc(item.data_hora_agenda).tz(this.userTimezone).format("DD/MM/YYYY (HH:mm)") : null,
          time_exibir: null
        }



        if (item.origem == 'PLATAFORMA') {
          msg = { ...msg, align: 'right' }
        }


        if (item.tipo == 'audio') {
          msg = { ...msg, isAudio: true, fileContent: 'Arquivo de audio', conteudo: `${this.urlStorage}/${item.conteudo}` }

          if (item.origem == 'CLIENTE') {
            this.sharedFilesHistoricList.push(item);
          }

        } else if (item.tipo == 'text') {
          const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
          let x = regex.test(item.conteudo);

          if (x == true) {
            msg = { ...msg, isLink: true, conteudo: item.conteudo }
          } else {
            // item.conteudo = item.conteudo.replace(/\*.*?\*/g, '');
            item.conteudo = item.conteudo.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
            item.conteudo = item.conteudo.replace(/\n/g, '<br>');
            msg = { ...msg, isText: true, conteudo: item.conteudo }
          }

        } else if (item.tipo == 'image') {
          let imageContent = [
            { id: item.id, conteudo: `${this.urlStorage}/${item.conteudo}`, caption: item.legenda_arquivo, thumb: '' },
          ];
          msg = { ...msg, isImage: true, imageContent: imageContent }


          if (item.origem == 'CLIENTE') {
            this.sharedFilesHistoricList.push(item);
          }

        } else if (item.tipo == 'document') {
          msg = { ...msg, isfile: true, fileContent: 'Documento', conteudo: `${this.urlStorage}/${item.conteudo}` }

          if (item.origem == 'CLIENTE') {
            this.sharedFilesHistoricList.push(item);
          }

        } else if (item.tipo == 'video') {
          msg = { ...msg, isVideo: true, fileContent: 'Video', conteudo: `${this.urlStorage}/${item.conteudo}` }

          if (item.origem == 'CLIENTE') {
            this.sharedFilesHistoricList.push(item);
          }

        } else if (item.tipo == 'location') {
          let location = JSON.parse(item.conteudo);
          msg.isLocation = true;
          msg.linkLocation = `https://www.google.com/maps?q=${Number(location.latitude)},${Number(location.longitude)}`
          msg.locationOption = {
            center: { lat: Number(location.latitude), lng: Number(location.longitude) },
            zoom: 12
          }
        } else if (item.tipo == 'contact') {
          let conteudo = JSON.parse(item.conteudo);

          if (!conteudo?.phones) {
            let regexTelefone = /TEL:\s*(\+?\d[\d\s-]+)/;
            let whatsappContato = item.conteudo.match(regexTelefone);

            if (whatsappContato && whatsappContato[1]) {
              conteudo['phones'] = [whatsappContato[1].replace(/\s|-/g, '')]; // Remove espaços e traços para ter só números e o '+'
            } else {
              regexTelefone = /waid=\d+:\s*(\+?\d[\d\s-]+)/;
              whatsappContato = item.conteudo.match(regexTelefone);
              if (whatsappContato && whatsappContato[1]) {
                conteudo['phones'] = [whatsappContato[1].replace(/\s|-/g, '')]; // Remove espaços e traços para ter só números e o '+'
              }
            }

          }


          msg = { ...msg, isContact: true, conteudo: conteudo }
        } else if (item.tipo == 'contacts') {
          let conteudo = JSON.parse(item.conteudo);
          msg = { ...msg, isContacts: true, conteudo: conteudo }

          conteudo.map(item => {
            if (!item?.phones) {
              let regexTelefone = /TEL:\s*(\+?\d[\d\s-]+)/;

              let whatsappContato;

              if (item?.vcard) {
                whatsappContato = item.vcard.match(regexTelefone)
              } else {
                whatsappContato = item.match(regexTelefone);
              }

              if (whatsappContato && whatsappContato[1]) {
                item['phones'] = [whatsappContato[1].replace(/\s|-/g, '')]; // Remove espaços e traços para ter só números e o '+'
              } else {
                regexTelefone = /waid=\d+:\s*(\+?\d[\d\s-]+)/;

                if (item?.vcard) {
                  whatsappContato = item.vcard.match(regexTelefone)
                } else {
                  whatsappContato = item.match(regexTelefone);
                }

                if (whatsappContato && whatsappContato[1]) {
                  item['phones'] = [whatsappContato[1].replace(/\s|-/g, '')]; // Remove espaços e traços para ter só números e o '+'
                }
              }

            }
          })


          console.log(conteudo);

        }


        let otherDate;
        if (item.id == null) {
          otherDate = moment(item.created_at).toDate();
        } else {
          otherDate = moment(item.created_at).toDate();
        }
        let todayDate = new Date();

        if (
          otherDate.getDate() === todayDate.getDate() &&
          otherDate.getMonth() === todayDate.getMonth() &&
          otherDate.getFullYear() === todayDate.getFullYear()
        ) {

          msg.time = moment(item.created_at).format("HH:mm");

          if (toDay == false) {
            toDay = true;
            this.message.push({ isToday: true });
          }
          msg.time_exibir = moment(item.created_at).format("HH:mm");
        } else {
          msg.time = moment(item.created_at).format("HH:mm");
          msg.time_exibir = this.getDaysSinceLastMessage(item.created_at)
        }

        this.addMessageBlock = false;
        this.message.push(msg);

        // this.onListScroll();

      } else {
        this.addMessageBlock = false;
      }

    } else {
      this.addMessageBlock = false;
    }

    this.messagesAgrupada = this.aggMensagemPorData(this.message);
  }

  updateAtendente() {
    let usuario = JSON.parse(localStorage.getItem('user'));
    let chat = this.chat.find(c => { return c.id == this.chatSelected.id });

    if (chat != undefined) {
      chat.atendente = usuario.name;
      chat.usuario_atendimento_atual_id = usuario.id;
      chat.situacao = 1;
      chat.departamento_id = usuario.departamento_id;
      chat.departamento = usuario?.departamento?.nome || null;
    }
  }

  // Message Search
  MessageSearch() {
    var input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("searchMessage") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.getElementById("users-conversation");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("h5")[0];
      txtValue = a?.innerText;
      if (txtValue?.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  // Filter Offcanvas Set
  onChatInfoClicked(content: TemplateRef<any>) {

    if (this.configPlataforma.visao_360) {
      this.showDadosContato = true;
    } else {

      if (this.chatSelected.departamento == null) {
        this.offcanvasService.open(content, { position: 'end' });
        return;
      }

      this.offcanvasService.open(content, { position: 'end' });

      this.listCamposContato();
    }

  }

  listCamposContato(content = null) {
    this.camposPessoaChat = [];
    this.pessoaService.listCamposChat(this.chatSelected['codigoCliente'], this.chatSelected['departamento_id']).subscribe(json => {

      json.map(item => {

        let personalizado = true;

        if (item.campo == 'CPF' || item.campo == 'Nome' || item.campo == 'Email') {
          personalizado = false;
        }

        this.camposPessoaChat.push({
          tipo: item.tipo,
          campo: item.campo,
          campo_personalizado_id: item.campo_personalizado_id,
          value: item.value,
          personalizado: personalizado,
          opcoes: item?.opcoes || [],
          editavel: item?.editavel || true
        });
      })
    })
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /**
   * Save the message in chat
   */
  messageSave(observacao_interna = null, agendamento = false, origem = null) {
    if (this.modalMensagemAgendada) {
      return;
    }

    var groupMsg = document.querySelector('.pills-groups-tab.active');
    let message = this.formData.get('message')!.value;
    if (!message) {
      return;
    }

    message = message.replace(/\*(.*?)\*/g, '<strong>$1</strong>');


    if (!groupMsg) {
      try {
        document.querySelector('.chat-user-list li.active .chat-user-message').innerHTML = message ? message : this.img;
      } catch (error) {

      }
    }
    var img = this.img ? this.img : '';
    var status = this.img ? true : '';
    var dateTime = moment(new Date()).format("HH:mm");
    var chatReplyUser = this.isreplyMessage == true ? (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML : '';
    var chatReplyMessage = this.isreplyMessage == true ? (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerText : '';
    this.message.push({
      id: 1,
      conteudo: message,
      isText: true,
      name: this.senderName,
      profile: this.senderProfile,
      time: dateTime,
      align: 'right',
      isimage: status,
      imageContent: [img],
      replayName: chatReplyUser,
      replaymsg: chatReplyMessage,
      mensagem_resposta: this.showResponse ? this.messageSelected.mensagem_resposta : null,
      mensagem_resposta_conteudo: this.showResponse ? this.messageSelected.mensagem_resposta.conteudo : null,
      delivery: this.chatSelected.internal ? true : false,
      observacao_interna: observacao_interna,
      agendamento: agendamento,
      excluida: false,
      createdAtGroup: moment.utc(new Date()).local().format('DD/MM/YYYY'),
      origem: origem,
      appSend: 0
    });
    this.onListScroll();

    // Set Form Data Reset
    // this.formData = this.formBuilder.group({
    //   message: null,
    //   agenda: null
    // });

    this.formData.reset();

    this.isreplyMessage = false;
    this.emoji = '';
    this.img = '';
    chatReplyUser = '';
    chatReplyMessage = '';
    document.querySelector('.replyCard')?.classList.remove('show');

    if (this.showResponse) {
      this.showResponse = false;
    }


    this.messagesAgrupada = this.aggMensagemPorData(this.message);

  }

  convertUrlsToLinks(text: string): string {
    const urlRegex = /(http[s]?:\/\/[^\s]+)/g;

    return text.replace(urlRegex, (url: string) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  onListScroll() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
        return this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
      }, 10);
    }
  }

  // Emoji Picker
  showEmojiPicker = false;
  sets: any = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set: any = 'twitter';
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event: any) {
    const { emoji } = this;
    const text = `${emoji}${event.emoji.native}`;
    this.emoji = text;
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }


  onBlur() {
    this.fastResponseList = [];
    let msg = this.formData.get('message').value;

    if (msg == null || msg == '') {
      this.respostaRapida = false;
    }

    if (msg.substring(0, 2) === "//") {
      this.respostaRapida = true;

      let value = msg.replace(/\/\//g, '');
      if (value == null || value == '') {
        value = 'ALL';
      }

      this.atalhoRespostaRapida = value;

      this.respostaRapidaService.autoCompleteResposta(this.atalhoRespostaRapida).subscribe(json => {
        this.fastResponseList = json;
      })
    }


  }

  copiaRespostaRapida(resposta) {

    let h = new Date().getHours();
    let sd: any;

    if (h <= 5) {
      sd = 'Boa madrugada';
    } else if (h < 12) {
      sd = 'Bom dia'
    } else if (h < 18) {
      sd = 'Boa tarde'
    } else {
      sd = 'Boa noite'
    }

    let text = this.chatSelected.name.split(' ');

    let val = resposta.texto.replace("{PRIMEIRO_NOME_LEAD}", text[0]);

    val = val.replace("{DAY_GREETING}", sd);

    val = val.replace("{NOME_ATENDENTE}", this.userLogado.name);

    val = val.replace("{NOME_DEPARTAMENTO}", this.chatSelected.departamento);

    this.formData.get('message').setValue(val);

    this.respostaRapida = false;
  }

  closeReplay() {
    document.querySelector('.replyCard')?.classList.remove('show');
  }

  // Copy Message
  copyMessage(event: any) {
    navigator.clipboard.writeText(event.target.closest('.chats').querySelector('.messageText').innerHTML);
    document.getElementById('copyClipBoard')?.classList.add('show');
    setTimeout(() => {
      document.getElementById('copyClipBoard')?.classList.remove('show');
    }, 1000);
  }

  // Delete Message
  // deleteMessage(event: any) {
  //   event.target.closest('.chats').remove();
  // }

  deleteMessage(data) {
    this.chatService.deleteMessage(data.id).subscribe(json => {
      data.excluida = true;
    })
  }

  // Delete All Message
  deleteAllMessage(event: any) {
    var allMsgDelete: any = document.getElementById('users-conversation')?.querySelectorAll('.chats');
    allMsgDelete.forEach((item: any) => {
      item.remove();
    })
  }

  // // Replay Message
  // replyMessage(event: any, align: any) {
  //   this.isreplyMessage = true;
  //   document.querySelector('.replyCard')?.classList.add('show');
  //   var copyText = event.target.closest('.chats').querySelector('.messageText').innerHTML;
  //   (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerHTML = copyText;
  //   var msgOwnerName: any = event.target.closest(".chats").classList.contains("right") == true ? 'You' : document.querySelector('.username')?.innerHTML;
  //   (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML = msgOwnerName;
  // }

  /**
  * Open center modal
  * @param centerDataModal center modal data
  */
  centerModal(centerDataModal: any) {
    this.modalService.open(centerDataModal, { centered: true });
  }

  // File Upload
  imageURL: string | undefined;
  img: any;
  fileChange(event: any) {
    let fileList: any = (event.target as HTMLInputElement);
    let file: File = fileList.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
      this.img = this.imageURL;
    }
    reader.readAsDataURL(file)
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: string) {
    this.mode = mode;

    localStorage.setItem('templateMode', this.mode);

    switch (mode) {
      case 'light':
        document.body.setAttribute('data-layout-mode', "light");
        break;
      case 'dark':
        document.body.setAttribute('data-layout-mode', "dark");
        break;
      default:
        document.body.setAttribute('data-layout-mode', "light");
        break;
    }
  }

  /***
   * ========== Group Msg ============
   */
  /**
 * Show user chat
 */
  // tslint:disable-next-line: typedef
  showGroupChat(event: any, id: any) {
    var removeClass = document.querySelectorAll('.chat-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });
    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');
    event.target.closest('li').classList.add('active');
    var data = this.groups.filter((group: any) => {
      return group.id === id;
    });
    this.userName = data[0].name
    this.userProfile = ''
    this.message = data[0].messages
  }

  colorLabelMessageLeft(data) {
    let templateMode = localStorage.getItem('templateMode');
    if (templateMode == 'light') {
      if (data.align != 'right') {
        try {
          const elemento = this.el.nativeElement.querySelector('.ri-more-fill');
          this.renderer.setStyle(elemento, 'color', 'white');
        } catch (error) {

        }
        return { color: 'white' }
      }
    }

    return;
  }

  /**
   * Open add group modal
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openGroupModal(content: any) {
    this.listGroupsExisting();
    this.modalService.open(content, { centered: true });
  }

  // Group Search
  GroupSearch() {
    var input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("searchGroup") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.querySelectorAll(".group-list");
    ul.forEach((item: any) => {
      li = item.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("h5")[0];
        txtValue = a?.innerText;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    })
  }


  listConversasFinalizadas() {
    if (this.exibir_filtro_avancado) {
      return;
    }

    this.conversasFinalizadas = [];

    this.chatService.listConversasFinalizadas(this.departamentoFilter, this.nomeFilter, this.whatsappCelular, this.mensagemFilter, this.funilFilter, this.tagFilter, this.userFilter, this.aparelhoFilter).subscribe(json => {

      json.map(item => {
        this.addConversasFinalizadas(item);
      });

      setTimeout(() => {
        this.loadChats = false;
      }, 1000);
    })
  }

  addConversasFinalizadas(chat) {
    let item = {
      id: chat.id,
      name: chat.nome_cliente,
      profilePicture: chat.foto_perfil ? this.urlStorage + '/' + chat.foto_perfil : null,
      situacao: chat.situacao,
      // lastMessage: chat?.ultima_mensagem?.conteudo,
      // typeLastMessage: chat.ultima_mensagem?.tipo,
      time: chat.created_at,
      whatsapp_contato: chat.whatsapp_contato,
      messages: [],
      usuario_atendimento_atual_id: chat.usuario_atendimento_atual_id,
      codigoCliente: chat.codigo_cliente,
      timestamp: null,
      // data_finalizado_format: chat.data_finalizado_format,      
      internal: false,
      canal_atendimento: chat.canal_atendimento,
      canal_atendimento_id: chat?.canal_atendimento_id,
      atendente: chat.atendente,
      departamento: chat.departamento,
      departamento_id: chat?.departamento_id,
      data_hora_andamento: chat.data_hora_andamento
    }

    let timestamp = new Date(chat.data_ultima_mensagem).getTime();
    item.timestamp = timestamp;

    let data_ultima_mensagem;
    let otherDate;
    let todayDate;

    try {
      data_ultima_mensagem = this.dateToLocal(chat.data_ultima_mensagem);
      otherDate = moment(data_ultima_mensagem).tz("Etc/GMT+0").toDate();
      todayDate = new Date();

      if (
        otherDate.getDate() === todayDate.getDate() &&
        otherDate.getMonth() === todayDate.getMonth() &&
        otherDate.getFullYear() === todayDate.getFullYear()
      ) {
        item.time = moment(data_ultima_mensagem).format("HH:mm");
      } else {
        item.time = moment(data_ultima_mensagem).tz("Etc/GMT+0").format("DD/MM/YYYY");
      }
    } catch (error) {
    }



    let isExists = this.conversasFinalizadas.find(c => { return c.id == item.id });
    if (isExists == undefined) {
      this.conversasFinalizadas.push(item);
    }
  }

  updateDepartamentosChat() {
    let departamentos: any[] = []
    this.outros_departamentos.map(item => {
      departamentos.push(item)
    })

    let params = {
      departamentos: departamentos
    }
    this.chatService.saveOthersDep(this.chatSelected.id, params).subscribe()
  }

  updateResponsaveisChat() {
    let responsaveis: any[] = []
    this.outros_responsaveis.map(item => {
      responsaveis.push(item)
    })

    let params = {
      responsaveis: responsaveis
    }
    this.chatService.saveOthersRes(this.chatSelected.id, params).subscribe()

  }

  listChatsNotStarted() {
    if (this.exibir_filtro_avancado) {
      return;
    }


    this.chatService.listChatAguardandoAtendimento(this.departamentoFilter, 1, this.nomeFilter, this.whatsappCelular, this.mensagemFilter, this.funilFilter, this.tagFilter, this.userFilter, this.aparelhoFilter).subscribe(chatsNotStarted => {
      this.countChatNotStarted = chatsNotStarted.total;

      let countNewMessagesNotStarted = 0;

      chatsNotStarted.data.map(item => {

        if (item.qtd_novas_mensagens > 0) {
          countNewMessagesNotStarted++;
        }
        this.addChatNotStarted(item);
      });

      this.countNewMessagesNotStarted = countNewMessagesNotStarted;

      if (this.chat.length > 0) {
        this.chat[0]['isActive'] = true;
      }

      this.loadChats = false;

      this.refreshListChatByLastMessage();

    })


    // if (this.activetab == 6) {
    //   this.notListChat = true;
    //   this.chatService.listChatAguardandoAtendimento(null, 1).subscribe(chatsNotStarted => {
    //     this.notListChat = false;
    //     this.countChatNotStarted = chatsNotStarted.total;

    //     let countNewMessagesNotStarted = 0;

    //     chatsNotStarted.data.map(item => {
    //       if (item.qtd_novas_mensagens > 0) {
    //         countNewMessagesNotStarted++;
    //       }

    //       this.addChatNotStarted(item);
    //     });

    //     this.countNewMessagesNotStarted = countNewMessagesNotStarted;

    //     if (this.chat.length > 0) {
    //       this.chat[0]['isActive'] = true;
    //     }

    //     this.loadChats = false;
    //   })
    // } else {
    //   this.chatService.listChatAguardandoAtendimento(null, 1).subscribe(chatsNotStarted => {
    //     this.countChatNotStarted = chatsNotStarted.total;
    //     let countNewMessagesNotStarted = 0;

    //     chatsNotStarted.data.map(item => {
    //       if (item.qtd_novas_mensagens > 0) {
    //         countNewMessagesNotStarted++;
    //       }
    //     });

    //     this.countNewMessagesNotStarted = countNewMessagesNotStarted;
    //   })
    // }
  }



  rowsListChat(event) {
    if (event.keyCode == 13) {
      this.chat = [];
      this.filterAvancado();
    }
  }

  styleScrollChats() {
    if (this.exibir_filtro_avancado) {
      return { 'height': 'calc(100vh - 450px)' };
    } else {
      return { 'height': 'calc(100vh - 150px)' };
    }
  }

  listChatAtendimentoFilter() {

    this.notListChat = true;

    let naoLidas = null;
    if (this.typeTicketFilter == 1) {
      naoLidas = 'sim';
    }

    this.chatService.listConversas(this.atendimentoFilter, this.aguardandoFilter, this.nomeFilter, this.statusFilter, this.whatsappCelular, this.mensagemFilter, false, this.rowsListAtendimento, this.funilFilter, this.tagFilter, this.userFilter, this.whatsappFixo, this.aparelhoFilter, this.departamentoFilter, naoLidas, this.semDepartamentoFilter).subscribe(json => {
      this.loadChats = false;

      let countNewMessages = 0;

      this.countChatStarted = json.total;

      json.map(item => {
        if (item.qtd_novas_mensagens > 0) {
          countNewMessages++;
        }

        this.addChat(item);

      });

      this.refreshListChatByLastMessage();
    });
  }

  setCountNovasMensagens() {
    let countNewMessages = 0;

    this.chat.map(item => {
      countNewMessages += item.countNewMessage || 0;
    });

    this.countNewMessagesChat = countNewMessages;
  }

  listChatAtendimento(removerFinalizados = true) {
    this.notListChat = true;

    let naoLidas = null;
    if (this.typeTicketFilter == 1) {
      naoLidas = 'sim';
    }

    this.atendimentoFilter = false;
    this.aguardandoFilter = false;
    this.finalizadoFilter = false;

    if (this.pageConversas == 0) {
      this.pageConversas++;
    }

    this.situacaoFiltroSelected.forEach(item => {

      if (item == '0') {
        this.aguardandoFilter = true;
      }

      if (item == '1') {
        this.atendimentoFilter = true;
      }

      if (item == '2') {
        this.finalizadoFilter = true;
      }
    })

    this.chatService.listConversas(this.atendimentoFilter, this.aguardandoFilter, this.nomeFilter, this.statusFilter, this.whatsappCelular, this.mensagemFilter, false, this.rowsListAtendimento, this.funilFilter, this.tagFilter, this.userFilter, this.whatsappFixo, this.aparelhoFilter, this.departamentoFilter, naoLidas, this.semDepartamentoFilter, this.finalizadoFilter, this.pageConversas, this.ordemFIlter, this.semRespostaFilter).subscribe(json => {
      this.loadChats = false;
      this.carregandoConversasScrool = false;

      this.countChatStarted = json.total;

      json.map(item => {
        this.addChat(item);
      });

      if (removerFinalizados) {
        this.removeChatsFinalizados(json);
      }

      this.refreshListChatByLastMessage();
    });
  }



  addChat(chat) {
    let add = true;

    if (this.semRespostaFilter == true && chat?.ultima_mensagem?.origem == 'PLATAFORMA') {
      add = false;
    }

    let chatExists = this.chat.find(c => { return c.id == chat.id });

    if (chatExists == undefined && add) {
      let item = {
        id: chat.id,
        name: chat.nome_cliente,
        cpf: chat?.cpf || null,
        profilePicture: chat.foto_perfil ? this.urlStorage + '/' + chat.foto_perfil : null,
        // status: 'online',
        situacao: chat.situacao,
        lastMessage: chat?.ultima_mensagem?.conteudo,
        typeLastMessage: chat.ultima_mensagem?.tipo,
        time: chat.created_at,
        whatsapp_contato: chat.whatsapp_contato,
        messages: [],
        usuario_atendimento_atual_id: chat.usuario_atendimento_atual_id,
        codigoCliente: chat.codigo_cliente,
        newMessage: chat.qtd_novas_mensagens > 0 ? true : false,
        countNewMessage: chat.qtd_novas_mensagens,
        timestamp: null,
        data_finalizado_format: chat.data_finalizado_format,
        empresa_fechada: chat.empresa_fechada,
        internal: false,
        canal_atendimento: chat.canal_atendimento,
        atendente: chat.atendente,
        departamento: chat.departamento,
        data_hora_andamento: chat.data_hora_andamento,
        negociacao_id: chat.negociacao_id,
        etapa_id: chat.etapa_id,
        canal_atendimento_id: chat?.canal_atendimento_id,
        departamento_id: chat?.departamento_id,
        data_ultima_mensagem: chat?.ultima_mensagem?.created_at,
        notRead: chat.nao_lido,
        time_exibir: null
      }
      item = this.setTimestampChat(item);

      let isExists = this.chat.find(c => { return c.id == item.id });
      if (isExists == undefined) {
        this.chat.push(item);
      }
    } else {
      if (chatExists != undefined) {
        chatExists.departamento = chat.departamento;
        chatExists.departamento_id = chat.departamento_id;
        chatExists.usuario_atendimento_atual_id = chat.usuario_atendimento_atual_id;
        chatExists.situacao = chat.situacao;
        chatExists.lastMessage = chat?.ultima_mensagem?.conteudo;
        chatExists.typeLastMessage = chat.ultima_mensagem?.tipo;
        chatExists.newMessage = chat.qtd_novas_mensagens > 0 ? true : false;
        chatExists.countNewMessage = chat.qtd_novas_mensagens;
        chatExists.data_ultima_mensagem = chat?.ultima_mensagem?.created_at;
        chatExists = this.setTimestampChat(chatExists);
      }
    }
  }

  setTimestampChat(chat) {
    let timestamp = new Date(chat.data_ultima_mensagem).getTime();
    chat.timestamp = timestamp;

    let data_ultima_mensagem;
    let otherDate;
    let todayDate;

    try {
      data_ultima_mensagem = this.dateToLocal(chat.data_ultima_mensagem);
      otherDate = moment(data_ultima_mensagem).tz("Etc/GMT+0").toDate();
      todayDate = new Date();

      if (
        otherDate.getDate() === todayDate.getDate() &&
        otherDate.getMonth() === todayDate.getMonth() &&
        otherDate.getFullYear() === todayDate.getFullYear()
      ) {
        chat.time_exibir = moment(data_ultima_mensagem).format("HH:mm");
      } else {
        chat.time_exibir = this.getDaysSinceLastMessage(data_ultima_mensagem)
      }

      chat.time = moment(data_ultima_mensagem).format("HH:mm");

    } catch (error) {
    }

    return chat;
  }

  removeChatsFinalizados(chatList = []) {
    //REMOVE OS FINALIZADOS
    this.chat = this.chat.filter(item1 =>
      chatList.some(item2 => item2.id === item1.id)
    );
  }

  refreshListChatByLastMessage() {

    if (this.ordemFIlter == 'DESC') {
      this.chat.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
    } else {
      this.chat.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });
    }


    this.setCountNovasMensagens();
  }

  listChatInterno() {
    let usuario = JSON.parse(localStorage.getItem('user'));

    this.chatInternoService.listByUsuario(usuario.id).subscribe(json => {

      let countNewMessages = 0;

      this.countChatStarted = json.total;

      json.map(item => {
        if (item.qtd_novas_mensagens > 0) {
          countNewMessages++;
        }
        this.addChatInterno(item);
      });

      this.countNewMessagesChat = countNewMessages;

      this.loadChats = false;
    });
  }

  dateToLocal(date) {
    let datePartial = date.substr(0, 19);
    return moment(datePartial + '+00:00').toDate();
  }

  addChatInterno(chat) {
    let usuario = JSON.parse(localStorage.getItem('user'));
    let chatExists = this.chatInternoList.find(c => { return c.id == chat.id });

    if (chatExists == undefined) {
      let item = {
        id: chat.id,
        name: usuario.id == chat.usuario_origem_id ? chat.usuario_destino_nome : chat.usuario_origem_nome,
        profilePicture: null,
        situacao: chat.situacao,
        lastMessage: null,
        typeLastMessage: null,
        time: chat.created_at,
        whatsapp_contato: null,
        messages: [],
        usuario_atendimento_atual_id: chat.usuario_origem_id,
        codigoCliente: chat.usuario_destino_id,
        newMessage: false,
        countNewMessage: 0,
        timestamp: null,
        data_finalizado_format: null,
        empresa_fechada: false,
        internal: true
      }

      let timestamp = new Date(chat.data_ultima_mensagem).getTime();
      item.timestamp = timestamp;

      let data_ultima_mensagem;
      let otherDate;
      let todayDate;

      try {
        if (chat.data_ultima_mensagem != null) {
          data_ultima_mensagem = this.dateToLocal(chat.data_ultima_mensagem);
          otherDate = moment(data_ultima_mensagem).tz("Etc/GMT+0").toDate();
          todayDate = new Date();

          if (
            otherDate.getDate() === todayDate.getDate() &&
            otherDate.getMonth() === todayDate.getMonth() &&
            otherDate.getFullYear() === todayDate.getFullYear()
          ) {
            item.time = moment(data_ultima_mensagem).format("HH:mm");
          } else {
            item.time = moment(data_ultima_mensagem).tz("Etc/GMT+0").format("DD/MM/YYYY");
          }
        } else {
          item.time = null;
        }
      } catch (error) {
      }



      this.chatInternoList.push(item);
    }
  }

  addChatNotStarted(chat) {
    let chatExists = this.chatNotStarted.find(c => { return c.id == chat.id });
    if (chatExists == undefined) {
      let item = {
        id: chat.id,
        name: chat.nome_cliente,
        profilePicture: chat.foto_perfil ? this.urlStorage + '/' + chat.foto_perfil : null,
        // status: 'online',
        situacao: chat.situacao,
        lastMessage: chat?.ultima_mensagem?.conteudo,
        typeLastMessage: chat.ultima_mensagem?.tipo,
        time: chat.created_at,
        whatsapp_contato: chat.whatsapp_contato,
        messages: [],
        usuario_atendimento_atual_id: chat.usuario_atendimento_atual_id,
        codigoCliente: chat.codigo_cliente,
        newMessage: chat.qtd_novas_mensagens > 0 ? true : false,
        countNewMessage: chat.qtd_novas_mensagens,
        timestamp: null,
        data_finalizado_format: chat.data_finalizado_format,
        empresa_fechada: chat.empresa_fechada,
        internal: false,
        canal_atendimento: chat.canal_atendimento,
        atendente: chat.atendente,
        departamento: chat.departamento,
        departamento_id: chat.departamento_id,
        data_hora_andamento: chat.data_hora_andamento
      }

      let timestamp = new Date(chat.data_ultima_mensagem).getTime();
      item.timestamp = timestamp;

      let data_ultima_mensagem;
      let otherDate;
      let todayDate;

      try {
        data_ultima_mensagem = this.dateToLocal(chat.data_ultima_mensagem);
        otherDate = moment(data_ultima_mensagem).tz("Etc/GMT+0").toDate();
        todayDate = new Date();

        if (
          otherDate.getDate() === todayDate.getDate() &&
          otherDate.getMonth() === todayDate.getMonth() &&
          otherDate.getFullYear() === todayDate.getFullYear()
        ) {
          item.time = moment(data_ultima_mensagem).format("HH:mm");
        } else {
          item.time = moment(data_ultima_mensagem).tz("Etc/GMT+0").format("DD/MM/YYYY");
        }
      } catch (error) {
      }


      let isExists = this.chatNotStarted.find(c => { return c.id == item.id });
      if (isExists == undefined) {
        this.chatNotStarted.push(item);
      }
    } else {
      if (chat.situacao == 2) {
        let chats = this.chatNotStarted.filter(c => { return c.id != chat.id });
        this.chatNotStarted = chats;
      }
    }

  }




  getDaysSinceLastMessage(item) {
    let today = moment();
    let diffInDays = today.diff(moment(item), 'days');
    diffInDays++
    if (diffInDays == 1) {
      return `Ontem`;
    } else {
      return `há ${diffInDays} dias`;
    }

  }

  websocketEvolution() {
    return false;

    let url = 'wss://server2.bkchat.com.br/estacio-teste-rx698w85fs9cl2y209rs4m';

    const socket = new WebSocket(url);

    socket.onmessage = function (message) {
      console.log(message);
    }


    // const socket = io(url, {
    //   transports: ['websocket']
    // });

    // socket.on('connect', () => {
    //   console.log('Connected to Evolution API WebSocket');
    // });

    // // Listening to events
    // socket.on('MESSAGES_UPSERT', (data) => {
    //   console.log('Event received:', data);
    // });
  }

  websocket() {
    try {
      const window = this;
      let confWebsocket = JSON.parse(localStorage.getItem('confWebsocket')!);
      var apiKey = confWebsocket.api_key;
      var channelId = confWebsocket.canal;
      this.piesocket = new WebSocket(`wss://${confWebsocket.cloud_id}.piesocket.com/v3/${channelId}?api_key=${apiKey}&notify_self`);

      var res = this.piesocket.onmessage = function (message) {
        let wss = JSON.parse(message.data);

        if (wss?.data?.tipo == 'REICEVED_MESSAGE') {
          if (window.chatSelected != null) {
            if (window.chatSelected && wss.data.chat.id == window.chatSelected.id) {
              window.addMessage(wss.data.mensagem);
              window.messagesAgrupada = window.aggMensagemPorData(window.message);
              window.onListScroll();
            } else {
              let chatUpdate;
              chatUpdate = window.chat.find(c => { return c.id == wss.data.chat.id });
              if (chatUpdate != undefined) {
                chatUpdate = { ...chatUpdate, ultima_mensagem: wss.data.chat?.ultima_mensagem || null }
              }

              let configuracao = localStorage.getItem('subdominio');
              if (configuracao == 'administramosimoveis') {
                window.notificationMessage('(BKChat) Nova mensagem recebida', wss.data.chat.whatsapp_contato, wss.data.chat.id);
              }
            }
          }

          window.updateLastMessageChat(wss.data.chat);
        }

        if (wss?.data?.tipo == 'UPDATE_DEPARTAMENTO') {
          if (window.chatSelected.id == wss.data.chat.id) {
            window.chatSelected.departamento_id = wss.data.chat.departamento_id
            window.chatSelected.departamento = wss.data.chat.departamento.nome;
          }
        }

        if (wss?.data?.tipo == 'UPDATE_USUARIO') {
          if (window.chatSelected.id == wss.data.chat.id) {
            window.chatSelected['usuario_atendimento_atual_id'] = wss.data.chat['usuario_atendimento_atual_id'];
            window.chatSelected['atendente'] = wss.data.chat.usuario.name;
          }
        }
      }
    } catch (error) {

    }

  }



  setMessageDelivery(id) {
    this.message.map(item => {
      if (item.id == id) {
        item.delivery = true;
      }
    })
  }

  setMessageRead(mensagem) {
    this.message.map(item => {
      if (item.id == mensagem.id) {
        item.read = mensagem.data_hora_lido;

      }
    })
  }

  updateLastMessageChat(chat) {
    this.chat.forEach(objChat => {
      if (objChat.id == chat.id) {
        objChat.lastMessage = chat.ultima_mensagem?.conteudo;

        objChat.typeLastMessage = chat.ultima_mensagem?.tipo;
        objChat['countNewMessage'] = chat?.qtd_novas_mensagens || 0;

        if (objChat.data_ultima_mensagem) {
          objChat.data_ultima_mensagem = chat.data_ultima_mensagem;
        } else {
          objChat.data_ultima_mensagem = moment(chat.created_at).format("YYYY-MM-DD HH:mm:ss");
        }

        let data_ultima_mensagem = this.dateToLocal(objChat.data_ultima_mensagem);
        let otherDate = moment(data_ultima_mensagem).tz("Etc/GMT+0").toDate();
        let todayDate = new Date();

        let timestamp = new Date(chat.data_ultima_mensagem).getTime();
        objChat.timestamp = timestamp;


        if (
          otherDate.getDate() === todayDate.getDate() &&
          otherDate.getMonth() === todayDate.getMonth() &&
          otherDate.getFullYear() === todayDate.getFullYear()
        ) {
          objChat.time = moment(data_ultima_mensagem).format("HH:mm");
          objChat.time_exibir = moment(data_ultima_mensagem).format("HH:mm");
        } else {
          objChat.time = moment(data_ultima_mensagem).tz("Etc/GMT+0").format("DD/MM/YYYY");
          objChat.time_exibir = this.getDaysSinceLastMessage(data_ultima_mensagem)
        }

        if (!this.chatSelected || objChat.id != this.chatSelected.id) {
          objChat.newMessage = true;
        } else {
          objChat.newMessage = false;
        }
      }
    });

    this.refreshListChatByLastMessage();
  }

  sendMessageInterno() {
    this.showEmojiPicker = false;



    this.newMessage = this.formData.get('message').value;

    var campoTrimmed = this.newMessage.trim();

    if (campoTrimmed === "") {
      this.formData.get('message').setValue('');
      return
    }

    let body: any = {
      chat_id: this.chatSelected.id,
      mensagem: this.newMessage,
      observacao_interna: false
    }

    if (this.showResponse) {
      body = { ...body, messageId: this.messageSelected.mensagem_resposta.id }
    }

    this.messageSave();

    this.chatSelected.data_ultima_mensagem = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")

    this.chatInternoService.sendMensagem(body).subscribe(json => {
      this.refreshListChatByLastMessage();
    })
  }

  sendMessageWhatsapp() {
    this.showEmojiPicker = false;

    this.newMessage = this.formData.get('message').value;

    var campoTrimmed = this.newMessage.trim();

    if (campoTrimmed === "") {
      this.formData.get('message').setValue('');
      return
    }

    let body: any = {
      chat_id: this.chatSelected.id,
      whatsapp: this.chatSelected.whatsapp_contato,
      mensagem: this.newMessage,
      mensagemAgendada: false,
      agenda: null,
      observacao_interna: false
    }

    if (this.showResponse) {
      body = { ...body, messageId: this.messageSelected.mensagem_resposta.id }
    }

    this.messageSave(null, false, 'PLATAFORMA');

    this.onListScroll();



    this.chatService.sendMensagem(body).subscribe(json => {

      this.updateAtendente();

      this.message[this.message.length - 1]['id'] = json.obj.id;
      this.message[this.message.length - 1]['delivery'] = true;

      this.chatSelected.ultima_mensagem = json.obj;
      this.chatSelected.data_ultima_mensagem = json.obj.created_at;
      this.updateLastMessageChat(this.chatSelected);
    })
  }



  uploadFiles(event) {
    const input = event.target as HTMLInputElement;
    let files: any = input.files;
    if (this.activetab == 12) {
      this.sendFileInterno(files);
    } else {
      this.sendFileWhatsapp(files);
    }
  }

  sendFileWhatsapp(files) {
    let formData: FormData = new FormData();

    let filenames: any[] = [];

    [...files].forEach(file => {
      this.mensagem_id_memoria = new Date().getTime();
      let extensao = file.type.split("/")[1];

      if (extensao == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
        extensao = 'docx';
      } else if (extensao == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        extensao = 'xlsx';
      }

      filenames.push(`${file.lastModified}${file.name}`);

      formData.append('myFiles[]', file);
    });

    this.onListScroll();

    formData.append("nome_arquivo", JSON.stringify(filenames));
    formData.append("chat_id", this.chatSelected.id);
    formData.append("whatsapp", this.chatSelected.whatsapp_contato);

    this.fileInput.nativeElement.value = '';

    if (this.activetab == 6) {
      // this.chatInternoService.sendFile(formData).subscribe(json => { });
    } else {
      this.labelDialogoAguardando = 'Aguarde, o arquivo está sendo enviado...';
      this.modalAguardando = true;
      this.chatService.sendFile(formData).subscribe(json => {
        this.modalAguardando = false;
        this.chatSelected.ultima_mensagem = json.obj[json.obj.length - 1];
        this.chatSelected.data_ultima_mensagem = json.obj[json.obj.length - 1].created_at;
        this.updateLastMessageChat(this.chatSelected);
        this.updateAtendente();
        json.obj.map(msg => {
          this.addMessage(msg);
          this.onListScroll();
        });
      });
    }
  }

  sendMessage() {
    if (this.formData.get('message').value == null || this.formData.get('message').value == '' || this.formData.get('message').value == undefined) {
      return;
    }

    if (this.activetab == 12) {
      this.sendMessageInterno();
    } else {
      this.sendMessageWhatsapp();
    }
  }

  sendAudio() {
    let body: any = {
      chat_id: this.chatSelected.id,
      whatsapp: this.chatSelected.whatsapp_contato,
      audioFile: btoa(this.srcAudioRecord),
      audio: true,
      filename: `${new Date().getTime()}audio.ogg`,
      mensagemAgendada: false,
      agenda: null
    }

    this.cancelAudio();

    this.labelDialogoAguardando = 'Aguarde, o audio está sendo enviado...';
    this.modalAguardando = true;

    this.chatService.sendAudio(body).subscribe(json => {
      this.modalAguardando = false;
      this.chatSelected.ultima_mensagem = json.obj;
      this.chatSelected.data_ultima_mensagem = json.obj.created_at;
      this.updateLastMessageChat(this.chatSelected);
      let msg = json.obj;
      this.updateAtendente();
      this.addMessage(msg);
      this.onListScroll();
      setTimeout(() => {
        this.onListScroll();

      }, 200);
    })
  }

  sendFileInterno(files) {
    let formData: FormData = new FormData();

    let filenames: any[] = [];

    [...files].forEach(file => {
      this.mensagem_id_memoria = new Date().getTime();

      let extensao = file.type.split("/")[1];

      if (extensao == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
        extensao = 'docx';
      } else if (extensao == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        extensao = 'xlsx';
      }

      filenames.push(`${file.lastModified}${file.name}`);

      formData.append('myFiles[]', file);
    });

    this.onListScroll();

    formData.append("nome_arquivo", JSON.stringify(filenames));
    formData.append("chat_id", this.chatSelected.id);

    this.fileInput.nativeElement.value = '';

    this.chatInternoService.sendFile(formData).subscribe(json => {
      json.mensagens.map(msg => {
        this.addMessageInterno(msg);
      });
    });
  }

  uploadImagem(event) {
    const input = event.target as HTMLInputElement;
    let files: any = input.files;
    this.showGallery(files);
  }

  showGallery(files) {
    let window = this;
    this.galleryList = [];
    [...files].forEach(file => {
      let url: any;
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (_event) => {
        url = reader.result;

        if (file.type == 'video/mp4') {
          window.galleryList.push({
            file: file,
            url: url,
            caption: null,
          });
        } else {
          this.resizeImage(url, { width: 250 }).then(function (blob: any) {

            var reader2 = new FileReader();
            reader2.readAsDataURL(blob);
            reader2.onload = (_event) => {
              window.galleryList.push({
                file: file,
                url: reader2.result,
                caption: null,
              });


            }
          })
        }

        this.modalGallery = true;

        // this.openGalleryModal(this.galleryContent);
      }
    });
  }

  openGalleryModal(content) {
    this.modalService.open(content, { centered: true });
  }

  resizeImage(src, options) {

    return this.loadImage(document.createElement('img'), src).then(function (image: any) {

      var canvas = document.createElement('canvas');

      if (options.width && !options.height) {
        options.height = image.height * (options.width / image.width)
      } else if (!options.width && options.height) {
        options.width = image.width * (options.height / image.height)
      }

      Object.assign(canvas, options);

      canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);

      return new Promise(function (resolve) {
        canvas.toBlob(resolve, options.type || 'image/png', options.quality)
      })
    })
  }

  loadImage(img, src) {
    return new Promise((resolve, reject) => {
      img.src = src;
      img.completed ? resolve(img) : img.addEventListener('load', function () {
        resolve(img)
      });
      img.addEventListener('error', reject);
    })
  }


  uploadHandlerGallery() {
    this.modalGallery = false;
    let formData: FormData = new FormData();
    let captions: any[] = [];
    let filenames: any[] = [];

    this.galleryList.map(item => {
      let file = item.file;
      let url: any;
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (_event) => {
        url = reader.result;
        captions.push(item.caption);
        file.caption = item.caption;
        filenames.push(`${file.lastModified}${file.name}`);
        formData.append('myFiles[]', file);
      }
    });

    setTimeout(() => {

      formData.append("nome_arquivo", JSON.stringify(filenames));
      formData.append("chat_id", this.chatSelected.id);
      formData.append("captions", JSON.stringify(captions));
      formData.append("whatsapp", this.chatSelected.whatsapp_contato);

      this.labelDialogoAguardando = 'Aguarde, o arquivo está sendo enviado...';
      this.modalAguardando = true;

      this.chatService.sendFile(formData).subscribe(json => {
        this.modalAguardando = false;
        this.chatSelected.ultima_mensagem = json.obj[json.obj.length - 1];
        this.chatSelected.data_ultima_mensagem = json.obj[json.obj.length - 1].created_at;
        this.updateLastMessageChat(this.chatSelected);

        this.updateAtendente();

        //APOS ENVIAR PARA O WHATSAPP ATUALIZA O ID DA MENSAGEM QUE ESTAVA SOMENTE NA MEMORIA
        json.obj.map(item => {
          this.addMessage(item);
        });

        this.onListScroll();
      });

    }, 1000);
  }


  response(data) {

    this.showResponse = true;

    this.messageSelected = {
      mensagem_resposta: {
        id: data.id,
        conteudo: data.conteudo
      }
    }

    this.remetente_a_responder = data.name
    this.mensagem_a_responder = data.conteudo
  }


  scroll(id) {
    const elmnt = document.getElementById(id);
    elmnt.scrollIntoView({ block: "start", inline: "nearest" });
  }

  initiateRecording() {
    this.recorder = true;

    let myPage = this;

    this.navigatorDevice = navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.localStream = stream;

      this.mediaRecord = new MediaRecorder(stream);

      this.chunks = [];
      this.mediaRecord.ondataavailable = (data: any) => {
        this.chunks.push(data.data);
      }

      this.mediaRecord.onstop = () => {
        const blob = new Blob(this.chunks, { type: 'audio/webm; code=opus' });
        const reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.srcAudioRecord = reader.result;

          myPage.montarAudio();
        }
      }

      this.mediaRecord.start();
    })
  }


  montarAudio() {
    if (this.srcAudioRecord) {
      const audio = document.createElement('audio');
      audio.src = this.srcAudioRecord;
      audio.id = 'myAudioElement'
      audio.style.height = "29px"
      audio.controls = true;
      let divAudio = document.getElementById('myAudio') as HTMLElement;
      divAudio.append(audio);
    }
  }

  stopRecord() {
    this.showAudio = true;
    this.basicTimer.stop();
    this.stopBothVideoAndAudio(this.localStream);
  }

  stopBothVideoAndAudio(stream: any) {
    stream.getTracks().forEach(function (track: any) {
      if (track.readyState == 'live') {
        track.stop();
      }
    });
  }

  cancelAudio() {
    this.showAudio = false;
    this.recorder = false;
    this.srcAudioRecord = null;
    let myAudioElement = document.getElementById('myAudioElement') as HTMLElement;
    myAudioElement.remove();
  }



  openModalShared(message) {
    this.modalShare = true;
    this.forwardedMessage = message;

  }

  closeModalShared() {

    this.modalShare = false;
  }

  shared(event) {

    this.modalShare = false;

    let selectedContacts = event.contactList
    let sharedType = event.sharedType

    if (sharedType == 0) {
      selectedContacts.forEach(contact => {
        let body = {
          destino: contact.celular_principal,
          mensagem_id: this.forwardedMessage.id
        }

        this.loadChats = true;

        this.chatService.encaminhaMensagem(body).subscribe(json => {
          let c = this.chatSelected;
          this.listChatAtendimento();
          setTimeout(() => {
            this.chatSelected = c;
          }, 2000);
        })
      })
    } else if (sharedType == 1) {
      selectedContacts.forEach(user => {
        let body = {
          userDestinoId: user.id,
          messageId: this.forwardedMessage.id
        }
        this.chatInternoService.forward_message_by_whatsapp(body).subscribe(json => {

        })
      })
    }


  }


  linkAtendimentoUsuario() {
    this.confirmationLinkAtendimentoUsuario = true
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      message: 'Deseja atender o chat selecionado?',
      accept: () => {
        let body = {
          chat_id: this.chatSelected.id,
          mensagem: this.mensagemLinkAtendimento || null
        }



        this.activetab = 2;

        try {
          document.querySelector('.user-chat').classList.add('d-none');
          document.querySelector('.load-messages').classList.remove('load-messages-show');
          document.querySelector('.chat-welcome-section').classList.remove('d-none');
          document.querySelector('.load-messages').classList.add('d-none');
        } catch (error) {

        }



        this.chatService.linkAtendimentoUser(body).subscribe(json => {
          this.mensagemLinkAtendimento = null;
          this.chat = [];
          this.listChatAtendimento();
        }, err => {

        })
      },
      reject: () => {

      }
    });
  }

  updateSituacaoAtendimento(dadosFinais = null) {
    let id = this.chatSelected?.id;
    let situacao = this.chatSelected.situacao;

    if (this.activetab == 2 || this.activetab == 3) {
      this.chatService.updateSituacaoAtendimento(situacao, id, dadosFinais).subscribe(json => {
        if (situacao == 2) {
          this.pageConversas = 0;
          this.message = [];
          this.chatSelected = undefined;
          this.loadChats = true;
          this.userProfile = [];
          document.querySelector('.user-chat').classList.add('d-none');
          document.querySelector('.chat-welcome-section').classList.remove('d-none');

          if (this.activetab == 2) {
            this.chat = [];
            this.listChatAtendimento();
          } else if (this.activetab == 3) {
            this.chat = [];
            this.listGroups();
          }
        }
      })
    } else {
      // this.chatInternoService.updateSituacaoAtendimento(situacao, this.chatSelected?.id).subscribe(json => {

      // })
    }


  }

  closeBoxResponse() {
    this.showResponse = false;
  }

  onNewChat(event) {

    this.loadMessages = false;

    this.loadChats = false;

    this.chat = [];

    this.showAbaChat(2)

    try {
      document.querySelector('.user-chat').classList.add('d-none');
      document.querySelector('.load-messages').classList.remove('load-messages-show');
      document.querySelector('.chat-welcome-section').classList.remove('d-none');
      document.querySelector('.load-messages').classList.add('d-none');
    } catch (error) {

    }

    setTimeout(() => {
      this.chatSelected = this.chat.find(c => { c.id == event });
    }, 300);
  }

  selectContact(event) {
    this.contactSelected = event;
  }

  showNotes() {
    this.modalNotepad = true;
  }


  importGroup() {
    this.loadingImportGroup = true;
    let contatos: any[] = [];
    let usuarios: any[] = [];
    this.usuariosGroupParticipant.map((item) => {
      usuarios.push(item.value);
    });

    let grupo = this.dropdownGroupsWhatsapp.find(g => { return g.value == this.groupImport });

    let params = {
      contatos: contatos,
      usuarios: usuarios,
      nome: grupo['label'],
      departamento_id: this.departamentoGroup,
      importGroup: true,
    };

    this.chatService.createGroup(params).subscribe(json => {
      this.loadingImportGroup = false;
      this.modalService.dismissAll();
      this.listGroups();
    });
  }

  filePaste(e) {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === 'file') {
        return item.getAsFile();
      }
    }
  }

  onPaste(e) {
    try {
      const file = this.filePaste(e);
      let files: any = [];
      files.push(file);
      try {
        this.showGallery(files);
      } catch (error) {

      }
      return;
    } catch (error) {
    }
  }

  eventKey(event: any) {
    let newMessage = this.formData.get('message').value;

    if (event.shiftKey && event.keyCode == 13) {
      if (newMessage.length < 1 || newMessage == null || newMessage == undefined || newMessage == '') {
        setTimeout(() => {
          this.formData.get('message').setValue('');
        }, 100);
      }
    } else if (event.keyCode == 13) {
      if (newMessage !== null && newMessage !== undefined && newMessage !== '') {
        setTimeout(() => {
          this.sendMessage();
        }, 100);
      } else {
        setTimeout(() => {
          this.formData.get('message').setValue('');
        }, 100);

      }
    }
  }

  showUsersChatInterno() {
    this.modalChatInterno = true;
    this.listUsuarios();
  }

  newChatInterno() {
    let body = {
      usuario_destino: {
        id: this.userChatInterno
      }
    }
    this.chatInternoService.addChat(body).subscribe(json => {
      this.chatSelected = null;
      this.chat = [];
      this.listChatInterno();
      this.modalChatInterno = false;
      this.userChatInterno = null;
    })
  }

  closeChat() {
    this.chatInternoService.closeChat(this.chatSelected.id).subscribe(json => {
      this.chatSelected = null;
      this.chat = [];
      this.listChatInterno();

      document.querySelector('.user-chat').classList.add('d-none');
      document.querySelector('.load-messages').classList.remove('load-messages-show');
      document.querySelector('.chat-welcome-section').classList.remove('d-none');
      document.querySelector('.load-messages').classList.add('d-none');
    })
  }

  finalizarAtendimentoShow() {

    this.objetoFinalizar = {
      inicioAtendimento: null,
      tempoAtendimento: null,
      responsavel: null,
      interesse: null,
      origem: null,
      qualificacao: null,
      relato: null,
      valor: null
    }

    this.objetoFinalizar.inicioAtendimento = this.chatSelected.data_hora_andamento
    this.objetoFinalizar.atendente = this.chatSelected.atendente



    let gmt = parseInt(this.configPlataforma.gmt);
    if (gmt < 0) {
      gmt = gmt * -1;
    }

    let dataInicial = new Date(this.chatSelected.data_hora_andamento);
    dataInicial.setHours(dataInicial.getHours() - gmt);

    let dataFinal: any = new Date(); // Data atual

    // Calcule a diferença de tempo em milissegundos
    var diferencaEmMilissegundos = dataFinal.getTime() - dataInicial.getTime();

    // Converta a diferença de milissegundos para o formato desejado (dias, horas, etc.)
    var segundos = Math.floor(diferencaEmMilissegundos / 1000);
    var minutos = Math.floor(segundos / 60);

    if (minutos < 1) {
      minutos = 0;
    }

    this.objetoFinalizar.tempoAtendimento = `${minutos} minutos`

    this.origemLeadService.index().subscribe(json => {
      this.dropdownOrigemLead = json.map(item => { return { value: item.id, label: item.descricao } })
    })

    this.modalFinalizarAtendimento = true;
  }

  finalizarAtendimento() {
    let body = {
      relato: this.objetoFinalizar.relato,
      dadosFinais: {
        origem: this.objetoFinalizar.origem,
        qualificacao: this.objetoFinalizar.qualificacao,
        interesse: this.objetoFinalizar.interesse,
        valor: this.objetoFinalizar.valor,
      }
    }

    this.chatSelected.situacao = 2;

    this.updateSituacaoAtendimento(body);

    this.modalFinalizarAtendimento = false;
  }


  showModalContatos() {
    this.lazyLoadContacts();
    this.modalContatos = true;
    this.canal_atendimento_id = this.dropdownCanalAtendimento[0]['value'];
  }

  lazyLoadContacts(event: any = null) {
    this.loadingContatos = true;
    let page = 1;

    if (event != null) {
      page = (event['first'] / event['rows']) + 1;
    }

    let body: any = {};

    if (this.nomeFilterContato != null) {
      body = { ...body, nome: this.nomeFilterContato }
    }

    this.pessoaService.filtroPaginado(page, body, 100).subscribe(json => {
      try {

        this.totalRecordsContatos = json.total;

        this.contacts = json.data.map(c => { return { id: c.id, name: c.nome, profilePicture: c.foto_perfil, whatsapp: c.celular_principal } });
        this.contacts.forEach(element => {
          element.name = element.name || element.whatsapp;
        })

        this.loadingContatos = false;
      } catch (error) {

      }
    })
  }

  sendContato() {

    if (this.selectedContatos.length == 0) {
      return;
    }

    this.selectedContatos.map(item => {
      let body = {
        chat_id: this.chatSelected.id,
        contato_id: item['id'],
        canalAtendimento: this.canal_atendimento_id
      }
      this.chatService.sendContact(body).subscribe(json => {
        this.addMessage(json, 'PLATAFORMA');
        this.modalContatos = false;
      })
    })

  }


  //BUSCA OS DADOS PRA PREENCHER A TELA DE VINCULAR O CHAT A UM FUNIL
  listAllFunil() {
    this.funilAtendimentoService.listPorDepartamento().subscribe(json => {
      this.dropdownFunil = json;

      this.dropdownFunil.map(item => {
        item.etapas.map(etapa => {
          let etapaChat = this.negociacaoList.find(e => { return e.etapa_funil_id == etapa.id });
          if (etapaChat != undefined) {
            etapa['selected'] = true;
          } else {
            etapa['selected'] = false;
          }
        })
      })
    })
  }

  //BUSCA OS DADOS PRA PREENCHER O DROPDOWN DE FUNIL DO FILTRO
  listAllFunilAndEtapas() {
    let usuario = JSON.parse(localStorage.getItem('user'));
    this.dropdownFunilEtapaFilter = [];
    this.funilAtendimentoService.getAllFunilAndEtapa(usuario.departamento_id).subscribe(etapas => {
      this.dropdownFunilEtapa = etapas.map(item => { return { value: item.etapa_id, label: item.funil_etapa } });

      this.dropdownFunilEtapaFilter.push({ value: null, label: 'Todos' })
      this.dropdownFunilEtapa.map(item => {
        this.dropdownFunilEtapaFilter.push({ value: item.value, label: item.label });
      })
    })
  }

  updateEtapaNegociacao() {
    this.chatSelected.etapa_id = this.etapaFunilSelected;
    this.funilAtendimentoService.updateEtapaNegociacao(this.etapaFunilSelected, this.chatSelected.negociacao_id).subscribe(json => {
    })
  }

  getLabelEtapaFunil() {
    if (this.etapaFunilSelected == undefined) {
      return '';
    }

    return this.dropdownFunilEtapa.find(x => { return x.value == this.etapaFunilSelected })['label'];
  }

  notifyWindows() {
    this.notification = new Notification("Título", {
      icon: 'https://i.stack.imgur.com/dmHl0.png',
      body: "Texto da notificação"
    });
  }


  showModalNovaConversa() {
    this.modalNewChat = true;
    this.loadingNovaConversa = true;
    this.formAtendimento.reset();
    this.listAllGatilhos();
    setTimeout(() => {
      this.loadingNovaConversa = false;
    }, 1000);
  }


  startConversa() {

    if (this.formAtendimento.invalid) {
      return;
    }

    let whatsapp = this.formAtendimento.get('whatsapp').value;
    let body = {
      nome: this.formAtendimento.get('nome').value,
      whatsapp: `55${whatsapp.replace(/\D/g, '')}`,
      mensagem: this.formAtendimento.get('resposta_rapida').value,
      canal_atendimento_id: this.formAtendimento.get('canal_atendimento_id').value,
      gatilho_id: this.formAtendimento.get('gatilho_id').value,
      anotacaoInterna: this.formAtendimento.get('anotacaoInterna').value,
    }

    this.loadingNovaConversa = true;


    this.chatService.startConversa(body).subscribe(json => {
      this.loadingNovaConversa = false;

      this.modalNewChat = false;

      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Contato cadastrado' });
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Atendimento gerado' });

      if (json.dialogoExecutado) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Dialogo executado com sucesso' });
      }

      this.showAbaChat(2);
    });
  }

  onGetResposta() {
    this.fastResponseListNovaConversa = [];

    let msg = this.formAtendimento.get('resposta_rapida').value;

    if (msg.substring(0, 2) === "//") {
      this.respostaRapidaNovaConversa = true;

      let value = msg.replace(/\/\//g, '');
      if (value == null || value == '') {
        value = 'ALL';
      }

      let atalhoRespostaRapida = value;

      this.respostaRapidaService.autoCompleteResposta(atalhoRespostaRapida).subscribe(json => {
        this.fastResponseListNovaConversa = json;
      })
    }
  }

  listCanalAtendimentos() {
    this.dropdownCanalAtendimentoFiltroAvancado = [{ value: null, label: 'Todos' }];
    this.canalAtendimentoService.index().subscribe(json => {
      json = json.filter(x => { return x.ativo == true });
      this.dropdownCanalAtendimento = json.map(item => { return { value: item.id, label: `${item.whatsapp} - ${item.nome}` } });


      json.map(item => {
        this.dropdownCanalAtendimentoFiltroAvancado.push({ value: item.id, label: `${item.whatsapp} - ${item.nome}` })
      })
    })
  }

  listAllGatilhos() {
    this.loadingDialogos = true;
    this.gatilhoService.listByDepartamentoUsuario().subscribe(json => {
      this.dropdownGatilho = json.map(item => { return { value: item.id, label: item.nome, acoes: item.acoes } });
      this.loadingDialogos = false;
    })
  }

  copiaRespostaRapidaNovaConversa(resposta) {
    this.formAtendimento.get('resposta_rapida').setValue(resposta['texto']);
    this.respostaRapidaNovaConversa = false;
  }

  getUpdateMensagemChat() {
    if (this.chatSelected) {
      this.chatService.getStatusMensagemChat(this.chatSelected['id']).subscribe(json => {
        json.forEach(element => {

          if (this.messagesAgrupada['Hoje']) {
            this.messagesAgrupada['Hoje'].forEach(mensagem => {
              if (mensagem['id'] == element['id']) {
                mensagem['delivery'] = element['recebida'];
                mensagem['read'] = element.data_hora_lido || null;
              }
            });
          }
        });
      })
    }
  }

  changeGatilho() {
    let gatilho = this.dropdownGatilho.find(g => { return g.value == this.formAtendimento.get('gatilho_id').value });
    gatilho.acoes.forEach(element => {
      let acao = JSON.parse(element['acao']);
      if (acao.tipo == 7) {
        this.formAtendimento.get('canal_atendimento_id').setValue(acao.valor);
      }
    });
  }

  deleteMensagemSistema(data) {
    data.excluida = true;
    this.chatService.deleteObservacaoInterna(this.chatSelected.id, data.id).subscribe(json => {

    })
  }

  openConversa() {
    this.chatService.reabrirConversa(this.chatSelected.id).subscribe(json => {
      this.showAbaChat(2);
    });
  }

  saveDadoPessoa(item) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: `${item.campo} salvo com sucesso` });

    return;
    if (this.configPlataforma.visao_360) {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `${item.campo} salvo com sucesso` });
    } else {
      let body = {
        pessoaId: this.chatSelected['codigoCliente'],
        campo: item['campo'],
        campoId: item['campo_personalizado_id'],
        value: item['value'],
        personalizado: item['personalizado'],
        chatId: this.chatSelected.id
      }

      this.pessoaService.saveDadosChat(body).subscribe(json => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `${item.campo} salvo com sucesso` });
      });
    }
  }

  showModalAgendarMensagem() {
    this.modalMensagemAgendada = true;
  }

  agendarMensagem() {
    this.modalMensagemAgendada = false;

    this.showEmojiPicker = false;

    this.newMessage = this.formData.get('message').value;

    var campoTrimmed = this.newMessage.trim();

    if (campoTrimmed === "") {
      this.formData.get('message').setValue('');
      return
    }

    let body: any = {
      chat_id: this.chatSelected.id,
      whatsapp: this.chatSelected.whatsapp_contato,
      mensagem: this.newMessage,
      mensagemAgendada: true,
      agenda: this.formData.get('agenda').value,
      observacao_interna: false
    }

    if (this.showResponse) {
      body = { ...body, messageId: this.messageSelected.mensagem_resposta.id }
    }


    // this.messageSave(false, true);

    this.labelDialogoAguardando = 'Aguarde, agendamento sendo criado...';
    this.modalAguardando = true;

    this.chatSelected.data_ultima_mensagem = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")

    this.chatService.sendMensagem(body).subscribe(json => {
      this.formData.reset();
      this.addMessage(json.obj);
      this.modalAguardando = false;
      // this.message[this.message.length - 1]['id'] = json.obj.id;
      // this.message[this.message.length - 1]['delivery'] = true;
      // this.message[this.message.length - 1]['data_hora_agenda'] = json.obj.data_hora_agenda ? moment.utc(json.obj.data_hora_agenda).tz(this.userTimezone).format("DD/MM/YYYY (HH:mm)") : null;      
      this.refreshListChatByLastMessage();
    })
  }

  getDialogos() {
    this.modalDialogo = true;
    this.listAllGatilhos();
  }

  sendDialogo() {
    this.modalDialogo = false;

    this.chatService.setGatilho(this.chatSelected['id'], this.formDialogo.get('id').value).subscribe(json => {
      this.loadMessages = true;

      this.chatService.getMessages(this.chatSelected.id).subscribe(json => {
        this.loadMessages = false;

        this.pauseLoadMessages = true;

        json?.forEach((mensagem: any) => {
          this.addMessage(mensagem);
        });

        this.pauseLoadMessages = false;

        document.querySelector('.load-messages').classList.add('d-none');

        document.querySelector('.user-chat').classList.add('user-chat-show');
        document.querySelector('.user-chat').classList.remove('d-none');

        this.onListScroll();
      })
      this.listChatAtendimento();

      //this.listChatsNotStarted();
    })
  }

  saveAgendamentoDialogo() {

    let body: any = {
      chat_id: this.chatSelected.id,
      whatsapp: this.chatSelected.whatsapp_contato,
      mensagem: this.formDialogo.get('id').value,
      mensagemAgendada: true,
      agenda: this.formData.get('agenda').value,
      tipo: 'dialog',
      observacao_interna: false
    }

    if (this.showResponse) {
      body = { ...body, messageId: this.messageSelected.mensagem_resposta.id }
    }

    this.messageSave(false, true);

    this.chatSelected.data_ultima_mensagem = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")

    this.chatService.sendMensagem(body).subscribe(json => {
      this.message[this.message.length - 1]['id'] = json.obj.id;
      this.message[this.message.length - 1]['delivery'] = true;
      this.message[this.message.length - 1]['data_hora_agenda'] = json.obj.data_hora_agenda ? moment.utc(json.obj.data_hora_agenda).tz(this.userTimezone).format("DD/MM/YYYY (HH:mm)") : null;
      this.refreshListChatByLastMessage();
      this.modalDialogo = false;
    })
  }

  getFunils() {
    this.showFunil = true;
  }

  classListFunil(etapaId) {
    let negociacao = this.negociacaoList.find(n => { return n.value == etapaId });

    if (negociacao != undefined) {
      return 'list-group-item active';
    } else {
      return 'list-group-item'
    }
  }


  checkEtapaFunil(event, etapaId, funil) {

    if (event.checked) {
      this.dropdownFunil.map(item => {
        if (item.id == funil) {
          item.etapas.map(etapa => {
            if (etapa.id != etapaId) {
              etapa['selected'] = false;
            }
          })
        }
      });

      let body = {
        pessoaId: this.chatSelected['codigoCliente'],
        etapaId: etapaId,
        chatId: this.chatSelected['id']
      }

      this.chatService.saveOportunidade(body).subscribe(json => { })
    } else {
      this.chatService.deleteOportunidade(this.chatSelected.id, etapaId).subscribe(json => { })
    }

    // this.negociacaoList.map(item => {
    //   this.addNegociacao(item.value);
    // })

  }

  setNaoLido() {
    let body = {
      naoLido: this.chatSelected['notRead']
    }

    this.chatService.setNaoLido(this.chatSelected['id'], body).subscribe(json => {

    })
  }


  getTagLinked() {
    this.pessoaService.tagLinked(this.chatSelected.codigoCliente).subscribe(json => {
      this.chatSelected.tagsCliente = json;
    })
  }

  downloadMedia(data) {
    this.chatService.downloadMedia(data.zapiMessageId).subscribe(jsonList => {
      jsonList.map(json => {

        if (json.id == data.id) {
          if (json.tipo == 'image') {

            let imageContent = [
              { id: json.id, conteudo: `${this.urlStorage}/${json.conteudo}`, caption: json.legenda_arquivo, thumb: '' },
            ];
            data.imageContent = imageContent

          } else {
            data.conteudo = `${this.urlStorage}/${json.conteudo}`;
          }

          data.waiting_message = false;
        }

      })
    })
  }




  showConversas() {
    if (this.chat.length >= 50) {
      this.carregandoConversasScrool = true;
      this.pageConversas++;
      this.showButtonCarregandoConversas = false;
      this.cdr.detectChanges();
      this.listChatAtendimento(false);
    }
  }


  anotacaoInterna() {
    this.showEmojiPicker = false;

    this.newMessage = this.formData.get('anotacaoInterna').value;

    var campoTrimmed = this.newMessage.trim();

    if (campoTrimmed === "") {
      this.formData.get('anotacaoInterna').setValue('');
      return
    }

    let body: any = {
      chat_id: this.chatSelected.id,
      whatsapp: this.chatSelected.whatsapp_contato,
      mensagem: this.newMessage,
      mensagemAgendada: false,
      agenda: null,
      observacao_interna: true,
      criarEvento: this.formData.get('criarEvento').value,
      tituloEvento: this.formData.get('tituloEvento').value,
      tipoEvento: this.formData.get('tipoEvento').value,
      dataEvento: this.formData.get('dataEvento').value,
    }

    this.formData.get('message').setValue(this.newMessage);

    this.messageSave(true);

    this.chatSelected.data_ultima_mensagem = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")

    this.chatService.sendMensagem(body).subscribe(json => {
      this.getAgendaDia();
      this.modalAnotacaoInterna = false;
      this.message[this.message.length - 1]['id'] = json.obj.id;
      this.message[this.message.length - 1]['delivery'] = true;
      this.refreshListChatByLastMessage();
    })
  }

  showModalAnotacao() {
    this.formData.get('anotacaoInterna').setValue(null);
    this.formData.get('criarEvento').setValue(false);
    this.modalAnotacaoInterna = true;
  }

  getTipoAgenda() {
    if (this.formData.get('criarEvento').value == true) {
      this.agendaTipoService.index().subscribe(json => {
        this.dropdownTipoAgenda = json;
      })
    }
  }

  getAgendaDia() {
    this.agendaService.getEventosDia().subscribe(json => {
      this.countAgendaDia = json.length;
    })
  }
}
