import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class PessoaService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'pessoa');
  }

  listFuncionarios(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/funcionarios`);
  }

  getByCelular(celular = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get-by-celular/${celular}`);
  }

  autoCompleteEmpresa(value: any): Observable<any> {
    if (value !== '') {
      return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete/empresa/${value}`);
    } else {
      return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete/empresa`);
    }
  }

  autoCompleteAll(value: any = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/get/autocomplete`;
    if (value != null && value != "" && value != undefined) {
      url += `?value=${value}`;
    }
    return this.http.get(url);
  }


  filtroPaginado(page: number, body = null, row = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/paginate`;

    if (page !== null) {
      url += '?page=' + page;
    }

    if (row != null) {
      url += '&row=' + row;
    }

    return this.http.post(url, body);
  }

  linkTag(body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/tag`, body);
  }
  
  removeTag(chatId: number, pessoaId: number, tagId: number): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/link/tag/${chatId}/${pessoaId}/${tagId}`);
  }

  // removeTag(body: any): Observable<any> {
  //   return this.http.post(`${this.url_base}/${this.prefixo}/remove/tag`, body);
  // }

  storeFast(params: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/fast`, params);
  }

  updateDadosChat(body: any, id: number): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/dados/chat/${id}`, body);
  }

  autoCompletePessoa(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/list/pessoas/filtro`, body);
    // if (param !== '') {
    //   return this.http.get(`${this.url_base}/${this.prefixo}/get/autocomplete/${param}`);
    // } else {

    // }
  }

  listAvailable(value = null): Observable<any> {
    if (value !== null) {
      return this.http.get(`${this.url_base}/${this.prefixo}/list/available?value=${value}`);
    } else {
      return this.http.get(`${this.url_base}/${this.prefixo}/list/available`);
    }

  }


  listFinalizados(rows = null, filtroChat = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/has/finished/chat`;


    if (rows !== null) {
      url += '?row=' + rows;
    }

    if (filtroChat !== null) {
      url += '&filtroChat=' + filtroChat;
    }

    return this.http.get(url);
  }


  listForwarding(value = null): Observable<any> {
    if (value !== null) {
      return this.http.get(`${this.url_base}/${this.prefixo}/list/forwarding?value=${value}`);
    } else {
      return this.http.get(`${this.url_base}/${this.prefixo}/list/forwarding`);
    }

  }

  showProfileChat(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/show/profile-chat/${id}`);
  }

  saveProfile(body, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/save/profile/${id}`, body);
  }

  tagLinked(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/tag-linked/${id}`);
  }

  linkOneTag(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/one-tag`, body);
  }

  linkOneResponsavel(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/one-responsavel`, body);
  }

  saveSendContato(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/save/send/contact`, body);
  }

  showLite(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/show/lite/${id}`);
  }

  updateLite(body, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/lite/${id}`, body);
  }

  verifyWhatsapp(phone) {
    return this.http.get(`${this.url_base}/${this.prefixo}/verify/whatsapp/${phone}`);
  }

  aggregation(type, query = undefined) {
    let url = `${this.url_base}/${this.prefixo}/get/aggregation/${type}`;

    if (query != undefined) {
      url += `?${query}`;
    }

    return this.http.get(url);
  }

  updateContactsInstance(instancia) {
    return this.http.put(`${this.url_base}/${this.prefixo}/contacts/list/${instancia}`, null);
  }

  listCamposChat(id, departamentoId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/campos/chat/${id}/${departamentoId}`);
  }

  saveDadosChat(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/save/dados-chat`, body);
  }

  getNegociacoes(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/negociacoes/${id}`);
  }
}
