import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FunilAtendimentoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'funil-atendimento');
  }

  updateEtapaNegociacao(etapaId, negociacaoId): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/negociacao/etapa/${etapaId}/${negociacaoId}`, null);
  }

  getNegociacoesByEtapa(etapaId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/negociacao/etapa/${etapaId}`);
  }

  getAllFunilAndEtapa(departamento = null): Observable<any> {
    let query: string = '';

    if (departamento != null) {
      query += '&departamento=' + departamento;
    }

    if (query) {
      return this.http.get(`${this.url_base}/${this.prefixo}/get/funil-and-etapa?${query}`);
    } else {
      return this.http.get(`${this.url_base}/${this.prefixo}/get/funil-and-etapa`);
    }

  }

  getAllFunilAtendimento(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/all`);
  }

  listPorDepartamento(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/departamento`);
  }
}
