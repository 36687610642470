import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConexaoPolosService  extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'conexao-polos');
  }

    link(): Observable<any> {
      return this.http.get(`${this.url_base}/${this.prefixo}/get/link`);
    }

}
