import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastroPageComponent } from './cadastro-page/cadastro-page.component';
import { DepartamentosComponent } from './departamentos/departamentos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule, NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { LightboxModule } from 'ngx-lightbox';


import { DatePipe } from '@angular/common';

// Emoji Picker
import { PickerModule } from '@ctrl/ngx-emoji-mart';

// Simplebar
import { SimplebarAngularModule } from 'simplebar-angular';

import { TranslateModule } from '@ngx-translate/core';

import { DialogModule } from 'primeng/dialog';

import { ButtonModule } from 'primeng/button';

import { ProgressSpinnerModule } from 'primeng/progressspinner';


import { CdTimerModule } from 'angular-cd-timer';

import { AutoCompleteModule } from 'primeng/autocomplete';

import { TableModule } from 'primeng/table';

import { GMapModule } from 'primeng/gmap';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { BadgeModule } from 'primeng/badge';
import { ImageModule } from 'primeng/image';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { UsersComponent } from './users/users.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { FunnelComponent } from './funnel/funnel.component';
import { CampanhaComponent } from './campanha/campanha.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../core/core.module';
import { PasswordModule } from 'primeng/password';

import { CalendarModule } from 'primeng/calendar';
import { RespostaRapidaComponent } from './resposta-rapida/resposta-rapida.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TagComponent } from './tag/tag.component';
import { LinkComponent } from './link/link.component';
import { QualificacaoLeadComponent } from './qualificacao-lead/qualificacao-lead.component';
import { OrigemLeadComponent } from './origem-lead/origem-lead.component';
import { InteresseTicketComponent } from './interesse-ticket/interesse-ticket.component';
import { LeadTicketComponent } from './lead-ticket/lead-ticket.component';
import { ToastModule } from 'primeng/toast';
import { GatilhosComponent } from './gatilhos/gatilhos.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ProdutosComponent } from './produtos/produtos.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { CarteiraClienteComponent } from './carteira-cliente/carteira-cliente.component';
import {EditorModule} from 'primeng/editor';
import { GrupoUsuarioComponent } from './grupo-usuario/grupo-usuario.component';


@NgModule({
  declarations: [
    CadastroPageComponent,
    DepartamentosComponent,
    UsersComponent,
    FunnelComponent,
    CampanhaComponent,
    RespostaRapidaComponent,
    TagComponent,
    LinkComponent,
    QualificacaoLeadComponent,
    OrigemLeadComponent,
    InteresseTicketComponent,
    LeadTicketComponent,
    GatilhosComponent,
    ProdutosComponent,
    CarteiraClienteComponent,
    GrupoUsuarioComponent
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    LightboxModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbCollapseModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbDropdownModule,
    TranslateModule,
    SimplebarAngularModule,
    PickerModule,
    DialogModule,
    ButtonModule,
    CdTimerModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    TableModule,
    GMapModule,
    ConfirmDialogModule,
    DropdownModule,
    BadgeModule,
    ImageModule,
    TabViewModule,
    ToolbarModule,
    InputTextModule,
    PanelModule,
    InputTextareaModule,
    FileUploadModule,
    HttpClientModule,
    CoreModule,
    PasswordModule,
    CalendarModule,
    OverlayPanelModule,
    CalendarModule,
    ToastModule,
    SplitButtonModule,
    CheckboxModule,
    MultiSelectModule,
    InputNumberModule,
    EditorModule
  ],
  exports: [CadastroPageComponent, DepartamentosComponent, UsersComponent, FunnelComponent, CampanhaComponent, GatilhosComponent, CarteiraClienteComponent, GrupoUsuarioComponent]
})
export class CadastrosModule { }
