import { Component, OnInit } from '@angular/core';
import { ConexaoPolosService } from 'src/app/core/services/conexao-polos.service';

@Component({
  selector: 'app-download-conexao-polos',
  templateUrl: './download-conexao-polos.component.html',
  styleUrls: ['./download-conexao-polos.component.css']
})
export class DownloadConexaoPolosComponent implements OnInit {
  dataSource:any[] = [];

  constructor(
    private conexaoPolosService: ConexaoPolosService
  ) { }

  ngOnInit() {
    this.conexaoPolosService.link().subscribe(json => {
      this.dataSource = json;
    })
  }

}
