<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 350px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['titulo', 'mensagem']" [rowHover]="true" [paginator]="true" [rows]="50" [lazy]="true"
  (onLazyLoad)="listAll($event)" [totalRecords]="totalRecords" [loading]="loading">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="d-flex">
        <div class="p-fluid p-field mr-2" style="margin-right: 10px !important;">
          <input pInputText type="text" class="p-inputtext-sm ml-4 mr-2"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..."
            style="margin-right: 10px;" />
        </div>

        <div class="p-fluid p-field p-mr-2" style="margin-right: 5px !important;">
          <p-dropdown [options]="dropdownOrder" class="ml-2 p-inputtext-sm" [(ngModel)]="orderFilter"></p-dropdown>
        </div>

        <div class="p-fluid p-field p-mr-2" style="margin-top: 5px">
          <button id="btn-export-departamento" type="button" class="btn btn-primary btn-sm btn mr-2"
            style="margin-right: 5px;" (click)="listAll()" ngbTooltip="Atualizar lista"><i
              class="pi pi-search"></i></button>
        </div>
      </div>

      <div class="p-toolbar-group-right d-flex">

        <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;"
          (click)="exportExcel()" ngbTooltip="Exportar para excel"><i class="pi pi-file-export"></i></button>

        <button type="button" class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr c>
      <th>Código</th>
      <th>Titulo</th>
      <th>Mensagem</th>
      <th>Status</th>
      <th>Data do cadastro</th>
      <th>Agendada</th>
      <th>Enviados</th>
      <th>Pendentes</th>
      <th style="max-width:100px;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td>{{item.id}}</td>
      <td>{{item.titulo}}</td>
      <td>{{item.mensagem}}</td>
      <td>{{item.pendente == true ? 'Pendente de envio' : 'Enviado'}}</td>
      <td>{{item.created_at | date:'dd/MM/yyyy'}}</td>
      <td>{{item.campanha_agendada ? 'Sim' : 'Não'}}</td>
      <td>{{item.count_envio}}</td>
      <td>{{item.count_pendentes}}</td>
      <td style="max-width:100px;">
        <button type="button" class="btn btn-primary btn-sm mr-2" style="margin-right: 5px;" (click)="showForm(item.id)"
          ngbTooltip="Visualizar"><i class="pi pi-eye"></i></button>
        <button type="button" class="btn btn-danger btn-sm mr-2" (click)="sendCancel(item.id)"
          ngbTooltip="Cancelar envio pendentes"><i class="pi pi-times"></i></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>


<p-dialog header="Dados para o envio" [(visible)]="modal" [style]="{width: '100vw', height: '100vh'}">
  <form [formGroup]="form" class="row g-2 p-3">

    <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="campanha_agendada">
        <label class="form-check-label" for="customCheck2">Agendar campanha</label>
      </div>
    </div>

    <div *ngIf="form.get('campanha_agendada').value == true" class="col-md-12 mb-12 p-fluid">
      <label for="lastname2">Data do disparo</label>
      <p-calendar dateFormat="dd/mm/yy" [showTime]="true" [locale]="pt" formControlName="data_disparo">
      </p-calendar>
    </div>

    <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="enviar_opacao_blacklist">
        <label class="form-check-label" for="customCheck2">Enviar opção de não receber mais notificações
        </label>
      </div>
    </div>

    <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="multiplo">
        <label class="form-check-label" for="customCheck2">Cadastrar multiplos</label>
      </div>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Título</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="titulo" />
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div>

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">Mensagem</label>
      <textarea rows="5" cols="30" pInputTextarea formControlName="mensagem" class="p-inputtext-sm"></textarea>
    </div>

    <div class="col-sm-6 p-fluid">
      <label for="lastname2">Canal de envio</label>
      <p-dropdown appendTo="body" placeholder="Selecione" [options]="dropDownIntegracaoWhatsapp" class="p-inputtext-sm"
        formControlName="integracao_whatsapp_id">
      </p-dropdown>
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div>

    <div *ngIf="form.get('multiplo').value" class="col-sm-4 p-fluid">
      <label for="lastname2">Tag</label>
      <p-dropdown appendTo="body" filter="true" placeholder="Selecione" [options]="dropdownTag" formControlName="tag"
        class="p-inputtext-sm">
      </p-dropdown>
    </div>

    <div *ngIf="form.get('multiplo').value" class="col-sm-2 p-fluid" style="margin-top: 32px;">
      <button type="button" class="btn btn-primary btn mr-2" style="margin-right: 5px;" (click)="addMultiplo()"
        ngbTooltip="Adicionar"><i class="pi pi-plus"></i></button>
    </div>

    <div *ngIf="!form.get('multiplo').value" class="col-sm-6 p-fluid">
      <label for="lastname2">Tipo de filtro</label>
      <p-dropdown appendTo="body" [options]="dropdownTipofiltro" placeholder="Selecione" class="p-inputtext-sm"
        formControlName="tipo_filtro">
      </p-dropdown>
    </div>




  </form>

  <div *ngIf="form.get('multiplo').value" class="row p-3">
    <p-table [value]="multiploListConfig" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th>Canal de atendimento</th>
          <th>Tag</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item.canal_atendimento_label}}</td>
          <td>{{item.tag_label}}</td>
          <td>
            <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;"
              (click)="removeItemMultiplo(item)" ngbTooltip="Remover"><i class="pi pi-times"></i></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        Footer
      </ng-template>
    </p-table>

  </div>

  <div class="row p-3">
    <p-panel header="Arquivo" [toggleable]="true" [collapsed]="false" class="mb-2">
      <div class="col-12">
        <p-fileUpload [multiple]="true" name="myFiles[]" [url]="urlUpload" (onUpload)="onBasicUpload($event)"
          chooseIcon="pi pi-paperclip" [auto]="true" chooseLabel="Selecione o arquivo">
          <ng-template pTemplate="content">
            <ul *ngIf="files.length > 0">
              <li *ngFor="let file of files">
                {{file.nome}}
                <div class="col-sm-12 p-fluid">
                  <label>Descrição do arquivo</label>
                  <textarea rows="5" cols="30" [(ngModel)]="file.caption" pInputTextarea></textarea>
                </div>
              </li>
            </ul>

          </ng-template>
        </p-fileUpload>
      </div>
    </p-panel>


    <p-panel header="Audio" [toggleable]="true" [collapsed]="false" class="mb-2">
      <div class="card mt-2">
        <div class="card-header col-auto">
          <div class="col-auto mt-2" style="cursor: pointer;">
            <button pButton pRipple type="button" icon="fa fa-microphone" label="Gravar audio"
              (click)="initiateRecording()"></button>
          </div>
        </div>

        <div class="card-body" style="border: solid 1px;     border-color: lightgray;">
          <div *ngIf="recorder" class="col-auto" style="text-align: center">
            <div *ngIf="!visualizarAudio" class="d-flex justify-content-start">
              <div class="p-2">
                <label>Gravando...</label>
              </div>

              <div class="p-2">
                <cd-timer format="hms" #basicTimer></cd-timer>
              </div>


              <div class="p-2">
                <i class="pi pi-pause" aria-hidden="true" style="color: red; cursor: pointer; margin-top: 0px;"
                  (click)="stopRecord()"></i>
              </div>
            </div>

            <div *ngIf="visualizarAudio" class="d-flex justify-content-start">
              <div id="myAudio">

              </div>

              <div class="p-2">
                <input pInputText [(ngModel)]="nomeAudio" placeholder="Informe o nome do audio" />
              </div>

              <div class="p-2">
                <button pButton pRipple type="button" icon="pi pi-times" class="p-button-danger"
                  (click)="cancelAudio()"></button>
              </div>

              <div class="p-2">
                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-success"
                  (click)="addAudio()"></button>
              </div>
            </div>
          </div>

          <div class="col-12 mt-2">
            <ul *ngIf="audioList.length > 0">
              <li *ngFor="let audio of audioList">Audio - {{audio.nome}}</li>
            </ul>
          </div>
        </div>
      </div>
    </p-panel>

    <!-- <p-panel header="Botões" [toggleable]="true" [collapsed]="true">
            <div class="card mt-2">
                <div class="card-header col-auto">
                    <div class="col-auto mt-2" style="cursor: pointer;">
                        <button pButton pRipple type="button" icon="pi pi-plus" label="Adicionar botão"
                            (click)="addBotao()"></button>
                    </div>
                </div>

                <div class="card-body p-fluid p-formgrid p-grid "
                    style="border: solid 1px;     border-color: lightgray;">
                    <div *ngFor="let botao of array_botao" class="d-flex justify-content-start">

                        <div class="col-5 p-2">
                            <span class="p-float-label">
                                <input type="text" id="sistema" pInputText [(ngModel)]="botao.valor">
                                <label for="sistema">Gatilho no sistema</label>
                            </span>
                        </div>

                        <div class="col-5 p-2">
                            <span class="p-float-label">
                                <input type="text" id="cliente" pInputText [(ngModel)]="botao.descricao">
                                <label for="cliente">Sera mostrado para o cliente</label>
                            </span>
                        </div>

                        <div class="p-2">
                            <button pButton type="button" class="p-button-danger" label="Remover opção"
                                icon="pi pi-trash" (click)="removeOpcaoBotao(botao)"></button>
                        </div>
                    </div>

                </div>
            </div>
        </p-panel> -->
  </div>

  <div *ngIf="form.get('tipo_filtro').value == '0' && !form.get('multiplo').value" class="col-sm-12 p-3">
    <h5>Configurar filtro de pessoas</h5>

    <form [formGroup]="formCampanha" class="row">
      <div class="col-sm-3">
        <label for="lastname2">UF</label>
        <input pInputText class="form-control p-inputtext-sm" formControlName="uf" />
        <small class="badge badge-soft-info me-1 rounded-pill">Use # para separar os UF. Ex:(MT#MS#SP)
        </small>
      </div>

      <div class="col-sm-3">
        <label for="lastname2">Cidade</label>
        <input pInputText class="form-control p-inputtext-sm" formControlName="cidade" />
        <small class="badge badge-soft-info me-1 rounded-pill">Use # para separar as cidades. Ex:(Cuiaba#Campo
          Grande#São Paulo)
        </small>
      </div>

      <div class="col-sm-3">
        <label for="lastname2">Bairro</label>
        <input pInputText class="form-control p-inputtext-sm" formControlName="bairro" />
        <small class="badge badge-soft-info me-1 rounded-pill">Use # para separar os bairros. Ex:(Centro#Centro
          sul)
        </small>
      </div>

      <div class="col-sm-3 p-fluid">
        <label for="lastname2">Tag</label>
        <p-multiSelect appendTo="body" [options]="dropdownTag" formControlName="tag" optionLabel="label"
          class="p-inputtext-sm">
        </p-multiSelect>
      </div>

      <div class="col-sm-3 p-fluid">
        <label for="lastname2">Dia aniversário</label>
        <p-inputNumber inputId="integeronly" formControlName="diaAniversario">
        </p-inputNumber>
      </div>

      <div class="col-sm-3 p-fluid">
        <label for="lastname2">Mês aniversário</label>
        <p-inputNumber inputId="integeronly" formControlName="mesAniversario">
        </p-inputNumber>
      </div>


      <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="random">
          <label class="form-check-label" for="customCheck2">Ordenação randomica</label>
        </div>
      </div>

      <div class="col-sm-12">
        <label for="lastname2">Contatos com seguintes iniciais</label>
        <input pInputText class="form-control p-inputtext-sm" formControlName="iniciais" />
        <small class="badge badge-soft-info me-1 rounded-pill">Se deseja filtrar contatos que iniciam entre A e F
          informe da seguinte forma: A,F
        </small>
      </div>

      <div class="col-sm-12">
        <label for="lastname2">Limite de registros</label>
        <input pInputText class="form-control p-inputtext-sm" formControlName="limit" />
      </div>
    </form>

    <button class="btn btn-success waves-effect waves-light me-1 mt-2 mb-2" (click)="showPessoas()">Visualizar
      pessoas</button>

    <p-table *ngIf="form.get('tipo_filtro').value == '0'" [loading]="loadingFilter" [value]="pessoasImportList"
      responsiveLayout="scroll" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th>Nome</th>
          <th>Whatsapp</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item.nome}}</td>
          <td>{{item.whatsapp | celular}}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr>
          <td>
            Total de {{pessoasImportList ? pessoasImportList.length : 0 }} registros.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>



  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" (click)="save(false)" ngbTooltip="Salvar"><i
        class="pi pi-save"></i></button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="modalMultiplo" [modal]="true" [style]="{width: '20vw'}" [draggable]="false" [resizable]="false">
  <p class="m-0">Aguarda, registrando campanhas</p>
  <p-progressSpinner animationDuration=".5s"></p-progressSpinner>
  <ng-template pTemplate="footer">
    <!-- <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
  </ng-template>
</p-dialog>