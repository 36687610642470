<div *ngIf="loading" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
        fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div>

<div *ngIf="!loading" class="p-fluid  row mt-2" style="padding: 0;
margin: 0;">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body row" style="background-color: #edeff1;">
                <div class="d-flex justify-content-start ofset-6 col-md-2 mb-12">
                    <div class="text-md-start mt-3 mt-md-0">
                        <a *ngIf="direitoInserir != undefined" (click)="adicionar()" class="btn btn-success"><i
                                class="mdi mdi-plus-circle me-1"></i>
                            Adicionar</a>
                        <a *ngIf="direitoInserir == undefined" class="btn btn-secondary"><i
                                class="mdi mdi-plus-circle me-1"></i>
                            Adicionar</a>
                    </div>
                    <div *ngIf="direitoInserir == undefined" class="text-md-start mt-3 mt-md-0">
                        <small class="badge badge-soft-danger me-1 rounded-pill">Usuario não tem permissão para
                            realizar esta funcionalidade</small>
                    </div>

                </div>

            </div>
        </div> <!-- end card -->

    </div>
</div>


<div class="card" style="height: calc(100vh - 100px);">
    <perfect-scrollbar class="chat-message-list">
        <div class="col-lg-12 row d-flex">
            <div class="col-lg-2" *ngFor="let canal of canalAtendimentoList">
                <div *ngIf="canal" class="card bg-pattern">
                    <div class="card-body">
                        <div class="text-center">
                            <img src="assets/images/whatsapp.png" alt="logo" class="avatar-xl rounded-circle mb-3">
                            <h6 class="mb-1 font-15">{{canal.nome}} <a href="javascript: void(0);" (click)="show(canal)"
                                    class="btn btn-info btn-xs waves-effect waves-light me-1"><i
                                        class="mdi mdi-pencil"></i></a>
                            </h6>
                        </div>

                        <div class="text-center">
                            <span *ngIf="canal.online == true"
                                class="badge badge-soft-success me-1 rounded-pill font-15">Online</span>
                            <span *ngIf="canal.online !== true"
                                class="badge badge-soft-danger me-1 rounded-pill">Offline</span>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    </perfect-scrollbar>
</div>




<p-dialog header="Instancia" [(visible)]="modal" [style]="{width: '100vw', height: '100vh'}">
    <app-instancia-whatsapp (onClose)="modal = false" [instancia]="instanciaSelected"></app-instancia-whatsapp>

    <ng-template pTemplate="footer">


    </ng-template>
</p-dialog>