import { Component } from '@angular/core';
import { DireitoService } from 'src/app/core/services/direito.service';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent {

  constructor(
    public direitoService: DireitoService
  ) { }

}
