<p-tabView>
  <p-tabPanel header="Geral">

    <p-table #dt1 [paginator]="false" [showCurrentPageReport]="true" [value]="data"
      [scrollHeight]="'calc(100vh - 350px)'" responsiveLayout="scroll" [rowHover]="true" [loading]="loading"
      styleClass="p-datatable-sm p-datatable p-datatable-gridlines" [scrollable]="true" scrolldirection="both">


      <ng-template pTemplate="caption">
        <p-toolbar>
          <div class="p-toolbar-group-left">
            <div class="p-fluid row g-2 p-3">
              <div *ngIf="direitoService.can('RELATORIO_TODOS_ATENDIMENTOS')" class="col-auto">
                <label for="lastname2">Usuário</label>
                <p-multiSelect appendTo="body" class="p-inputtext-sm" [options]="dropdownUsuarios"
                  [(ngModel)]="selectedUsuario" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
              </div>

              <div class="col-auto">
                <label for="lastname2">Status</label>
                <p-multiSelect appendTo="body" class="p-inputtext-sm" [options]="dropdownStatus"
                  [(ngModel)]="selectedStatus" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
              </div>

              <div class="col-auto">
                <label for="lastname2">Período</label>
                <p-calendar class="p-inputtext-sm" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="rangeDates"
                  [locale]="pt" selectionMode="range" [readonlyInput]="false" inputId="range">
                </p-calendar>
              </div>

              <div class="col-auto">
                <label>Campos personalizados</label>
                <p-dropdown filter="true" class="p-inputtext-sm" [options]="camposPersonalizadosPessoaList"
                  [(ngModel)]="campoPersonalizadoFiltro"></p-dropdown>
              </div>

              <div *ngIf="campoPersonalizadoFiltro != null" class="col-auto">
                <label>Valor do campo</label>
                <input pInputText class="form-control p-inputtext-sm" [(ngModel)]="campoPersonalizadoValue" />
              </div>

              <div class="col-auto">
                <p-button icon="ri-search-line" styleClass="p-button-info mt-3" (click)="report()">
                </p-button>
              </div>

              <div class="col-auto">
                <p-button icon="ri-file-excel-line" styleClass="p-button-success mt-3" (click)="exportExcel()">
                </p-button>
              </div>
            </div>


          </div>

          <!-- <div class="p-toolbar-group-right d-flex">

                <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2"
                    style="margin-right: 5px;" (click)="exportExcel()" ngbTooltip="Exportar para excel"><i
                        class="pi pi-file-export"></i></button>

                <button *ngIf="direitoService.can('DEPARTAMENTO_INSERIR')" type="button"
                    class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
                        class="pi pi-plus"></i></button>
            </div> -->
        </p-toolbar>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th >Cliente</th>
          <th >Whatsapp contato</th>
          <th >Atendente</th>
          <th >Departamento</th>
          <th >Situação</th>
          <th >Avaliação atendimento</th>
          <th >Data/hora abertura</th>
          <th >Data/hora atendimento</th>
          <th >Data/hora finalizado</th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td >{{item.cliente}}</td>
          <td >{{item.whatsapp_contato}}</td>
          <td >{{item.atendente}}</td>
          <td >{{item.departamento}}</td>
          <td >{{item.situacao}}</td>
          <td >
            <div *ngIf="item.estrelas" class="d-flex">
              <i *ngFor="let estrela of item.estrelas" class="pi pi-star dourada"></i>
            </div>
            <div *ngIf="item.avaliacao_atendimento == 'Não avaliado'" class="d-flex">
              {{item.avaliacao_atendimento}}
            </div>
          </td>
          <td >{{item.data_hora_aberto}}</td>
          <td >{{item.data_hora_andamento}}</td>
          <td >{{item.data_hora_finalizado}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr *ngIf="data.length>0">
          <td colspan="9">{{data.length}} registros encontrados</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>Nenhum dado encontrado.</td>
        </tr>
      </ng-template>
    </p-table>
  </p-tabPanel>

  <!-- <p-tabPanel header="Campos personalizados">
    <app-report-atendimento-campos-personalizados></app-report-atendimento-campos-personalizados>
  </p-tabPanel> -->


  <p-tabPanel header="Ranking">
    <app-ranking-avaliacao></app-ranking-avaliacao>
  </p-tabPanel>
</p-tabView>