<p-table #dt1 [columns]="cols" [paginator]="false" [showCurrentPageReport]="true" [value]="data"
  [scrollHeight]="'calc(100vh - 350px)'" responsiveLayout="scroll" [rowHover]="true" [loading]="loading"
  styleClass="p-datatable-sm p-datatable p-datatable-gridlines" [scrollable]="true" scrolldirection="both">


  <ng-template pTemplate="caption">
    <p-toolbar>

      <div class="p-toolbar-group-left">

        <div class="p-fluid row g-2 p-3">

          <div class="col-auto">
            <label for="lastname2">Status</label>
            <p-multiSelect appendTo="body" class="p-inputtext-sm" [options]="dropdownStatus"
              [(ngModel)]="selectedStatus" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
          </div>

          <div class="col-auto">
            <label for="lastname2">Período</label>
            <p-calendar class="p-inputtext-sm" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="rangeDates"
              [locale]="pt" selectionMode="range" [readonlyInput]="false" inputId="range">
            </p-calendar>
          </div>

          <div *ngIf="configuracao.modulo_carteira" class="col-auto">
            <label>Carteira</label>
            <p-dropdown filter="true" class="p-inputtext-sm" [options]="dropdownCarteira"
              [(ngModel)]="carteiraFiltro"></p-dropdown>
          </div>

          <div class="col-auto">
            <label>Campos personalizados</label>
            <p-dropdown filter="true" class="p-inputtext-sm" [options]="camposPersonalizadosPessoaList"
              [(ngModel)]="campoPersonalizadoFiltro.id"></p-dropdown>
          </div>

          <div class="col-auto">
            <label>Condição</label>
            <p-dropdown filter="true" class="p-inputtext-sm" [options]="dropdownCondicao"
              [(ngModel)]="campoPersonalizadoFiltro.clausula"></p-dropdown>
          </div>

          <div *ngIf="campoPersonalizadoFiltro.id != null" class="col-auto">
            <label>Valor do campo</label>
            <input pInputText class="form-control p-inputtext-sm" [(ngModel)]="campoPersonalizadoFiltro.value" />
          </div>

          <div class="col-auto">
            <label>Agrupar dados</label>
            <p-dropdown filter="true" class="p-inputtext-sm" [options]="agruparDados"
              [(ngModel)]="agrupar"></p-dropdown>
          </div>

          <!-- <div *ngIf="agrupar" class="col-auto">
            <label>Agrupar pelo campo:</label>
            <p-dropdown filter="true" class="p-inputtext-sm" [options]="camposPersonalizadosPessoaList"
              [(ngModel)]="campoPersonalizadoAgrupamento.id"></p-dropdown>
          </div> -->

          <div class="col-auto">
            <p-button icon="ri-search-line" styleClass="p-button-info mt-3" (click)="report()">
            </p-button>
          </div>

          <div class="col-auto">
            <p-button icon="ri-file-excel-line" styleClass="p-button-success mt-3" (click)="exportExcel()">
            </p-button>
          </div>
        </div>


      </div>
    </p-toolbar>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" style="min-width:100px">
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" style="min-width:100px">
        {{rowData[col.field]}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr *ngIf="data.length>0">
      <td colspan="9">{{data.length}} registros encontrados</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td>Nenhum dado encontrado.</td>
    </tr>
  </ng-template>
</p-table>