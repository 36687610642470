import { Component, OnInit } from '@angular/core';
import { CampoPersonalizadoServiceService } from 'src/app/core/services/campo-personalizado-service.service';
import { RelatorioService } from 'src/app/core/services/relatorio.service';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { CarteiraClienteService } from 'src/app/core/services/carteira-cliente.service';


@Component({
  selector: 'app-report-contatos',
  templateUrl: './report-contatos.component.html',
  styleUrls: ['./report-contatos.component.css']
})
export class ReportContatosComponent implements OnInit {
  pt: any = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  loading = false;

  agrupar = null;

  camposPersonalizadosPessoaList = [];

  campoPersonalizadoFiltro: any = {
    id: null,
    value: null,
    campo: null,
    condicao: null
  };

  campoPersonalizadoAgrupamento: any = {
    id: null,
  };

  agruparDados = [
    { value: null, label: 'Não agrupar' },
    // {value:'status', label: 'Status'},
    // {value:'periodo', label: 'Periodo'},
    { value: 'campo_personalizado', label: 'Campo personalizado' },
  ]


  dropdownStatus: any[] = [
    { value: '0', label: 'Aberto' },
    { value: '1', label: 'Em andamento' },
    { value: '2', label: 'Finalizado' },
  ];

  selectedStatus: any[] = [];


  rangeDates: any[] = [];

  data = [];

  camposPersonalizadosPessoaTable = [];

  dataExcel = [];

  colsSemAgrupamento = [];

  colsAgrupadoCampo = [];

  cols = [];

  dropdownCondicao = [
    { value: '=', label: 'Igual' },
    { value: '<>', label: 'Diferente' },
    { value: 'like', label: 'Contém' },
  ]

  configuracao = JSON.parse(localStorage.getItem('configuracao'));

  dropdownCarteira = [];

  carteiraFiltro = null;

  constructor(
    private campoPersonalizadoService: CampoPersonalizadoServiceService,
    private relatorioService: RelatorioService,
    private carteiraService: CarteiraClienteService,
  ) { }

  ngOnInit() {
    this.rangeDates = null;
    this.listCamposPersonalizadosPessoa();
    this.listCarteiras();
  }

  listCamposPersonalizadosPessoa() {

    this.colsSemAgrupamento.push({ field: 'nome', header: 'Nome' });
    this.colsSemAgrupamento.push({ field: 'celular_principal', header: 'Whatsapp' });
    this.colsSemAgrupamento.push({ field: 'email_principal', header: 'Email' });
    this.colsSemAgrupamento.push({ field: 'etapa_funil', header: 'Etapa funil' });
    this.colsSemAgrupamento.push({ field: 'data_mov_etapa', header: 'Data da etapa funil' });
    
    if (this.configuracao.modulo_carteira) {
      this.colsSemAgrupamento.push({ field: 'carteira', header: 'Carteira' });
      this.colsSemAgrupamento.push({ field: 'carteira_cliente_responsavel', header: 'Responsavel carteira' });
    }

    let query = 'localidade=PESSOA';
    this.camposPersonalizadosPessoaList.push({ value: null, label: 'Selecione' });
    this.campoPersonalizadoService.index(null, query).subscribe(json => {

      json.map(item => {
        this.camposPersonalizadosPessoaList.push({
          value: item.id,
          label: item.campo,
        });


        this.colsSemAgrupamento.push({ field: item.campo, header: item.campo })
      });

    })

  }

  listCarteiras() {
    let direitos = JSON.parse(localStorage.getItem('direito'));
    let direito = direitos.find(d => { return d.flag == 'VISUALIZAR_TODAS_CARTEIRAS' });
    let usuario = JSON.parse(localStorage.getItem('user'));

    let request = null;
    if (direito == null) {
      request = `?usuarioId=${usuario.id}`;
    }

    this.carteiraService.index(null, request).subscribe(json => {
      this.dropdownCarteira = [{ value: null, label: 'Todas' }];
      json.map(item => {
        this.dropdownCarteira.push({ value: item.id, label: item.nome });
      })
    })
  }

  report() {
    let startDate;
    let endDate;

    try {
      startDate = new Date(this.rangeDates[0]).getTime();
    } catch (error) {
      startDate = null;
    }

    try {
      endDate = new Date(this.rangeDates[1]).getTime();
    } catch (error) {
      endDate = null;
    }  

    let body = {
      status: this.selectedStatus,
      startDate: startDate,
      endDate: endDate,
      campoPersonalizado: this.campoPersonalizadoFiltro,
      agrupar: this.agrupar,
      carteira: this.carteiraFiltro
    }

    this.loading = true;

    if (this.agrupar == null) {

      this.cols = this.colsSemAgrupamento;

      this.relatorioService.pessoas(body).subscribe(json => {
        this.loading = false;

        this.data = [];


        json.map(item => {
          let obj: any = {};

          obj['nome'] = item.nome;
          obj['celular_principal'] = item.celular_principal;
          obj['email_principal'] = item.email_principal;
          obj['etapa_funil'] = item.etapa_funil;
          obj['data_mov_etapa'] = item.data_mov_etapa != null ? moment(item.data_mov_etapa).format("DD/MM/YYYY") : null;

          if (this.configuracao.modulo_carteira) {
            obj['carteira'] = item.carteira_cliente;
            obj['carteira_cliente_responsavel'] = item.carteira_cliente_responsavel;
          }

          this.camposPersonalizadosPessoaList.map(campo => {
            if (campo.value != null) {
              let campoPessoa = item.campos_personalizados.find(c => { return campo.value == c.id });
              if (campoPessoa) {
                obj[campoPessoa['campo']] = campoPessoa['value']
              }
            }
          });

          this.data.push(obj);
        });

      })
    } else if (this.agrupar == 'campo_personalizado') {

      let campo = this.camposPersonalizadosPessoaList.find(c => { return c.value == this.campoPersonalizadoFiltro.id })

      this.colsAgrupadoCampo = [
        { field: 'value', header: campo.label },
        { field: 'count', header: 'Valor' },
      ]

      this.cols = this.colsAgrupadoCampo;

      this.relatorioService.reportPessoaGroupByCampoPersonalizado(body).subscribe(json => {
        this.loading = false;
        this.data = json;
      })
    }

  }


  exportExcel() {
    import("xlsx").then(xlsx => {

      this.dataExcel = [];

      this.data.forEach((dt, x) => {

        let obj = {};

        this.cols.forEach((i) => {
          if (dt[i.field] == null) {
            dt[i.field] = "";
          }
          obj[i.header] = `${dt[i.field]}`;
        })

        this.dataExcel.push(obj);

      });

      const worksheet = xlsx.utils.json_to_sheet(this.dataExcel);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "contatos");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
