<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
    [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
    [globalFilterFields]="['nome']" [rowHover]="true">

    <ng-template pTemplate="caption">
        <p-toolbar>
            <div class="p-toolbar-group-left">
                <div class="p-input-icon-left p-fluid p-field p-mr-2">
                    <input pInputText type="text" class="p-inputtext-sm ml-4"
                        (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
                </div>


            </div>

            <div class="p-toolbar-group-right d-flex">

                <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2"
                    style="margin-right: 5px;" (click)="exportExcel()" ngbTooltip="Exportar para excel"><i
                        class="pi pi-file-export"></i></button>

                <button *ngIf="direitoService.can('DEPARTAMENTO_INSERIR')" type="button"
                    class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
                        class="pi pi-plus"></i></button>
            </div>
        </p-toolbar>
    </ng-template>


    <ng-template pTemplate="header">
        <tr c>
            <th>Código</th>
            <th>Nome</th>
            <th>Ativo</th>
            <th style="max-width:100px;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr class="row-test">
            <td>{{item.id}}</td>
            <td>{{item.nome}}</td>
            <td>{{item.ativo ? 'Sim' : 'Não'}}</td>
            <td style="max-width:100px;">
                <button *ngIf="direitoService.can('DEPARTAMENTO_ALTERAR')" type="button"
                    class="btn btn-primary btn-sm mr-2" (click)="showForm(item.id)" ngbTooltip="Editar"><i
                        class="pi pi-pencil"></i></button>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
        <tr>
            <td>
                Total de {{dataSource ? dataSource.length : 0 }} registros.
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog header="Departamento" [(visible)]="modal" [style]="{width: '35vw'}">
    <p-tabView>
        <p-tabPanel header="Dados principais">
            <form [formGroup]="form" class="row g-2 p-3">

                <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
                        <label class="form-check-label" for="customCheck2">Ativo</label>
                    </div>
                </div>

                <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck2"
                            formControlName="disponivel_bot">
                        <label class="form-check-label" for="customCheck2">Disponivel no dialogo(BOT)</label>
                    </div>
                </div>

                <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck2"
                            formControlName="habilitar_horario_funcionamento">
                        <label class="form-check-label" for="customCheck2">Habilitar horario de funcionamento</label>
                        <small class="badge badge-soft-danger me-1 rounded-pill">Configuração recomendada para empresas
                            que possuem departamentos que trabalham em horários diferentes</small>
                    </div>
                </div>

                <div class="col-sm-12">
                    <label for="lastname2">Nome</label>
                    <input pInputText class="form-control p-inputtext-sm" formControlName="nome" />
                    <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
                </div>

                <div class="col-sm-12 p-fluid">
                    <label for="lastname2">Superior</label>
                    <p-dropdown appendTo="body" [options]="superiores" class="p-inputtext-sm"
                        formControlName="departamento_superior_id">
                    </p-dropdown>
                </div>

                <div *ngIf="configuracao.habilitar_vinculo_departamento_canal" class="col-sm-12 p-fluid">
                    <label for="lastname2">Canal de atendimento</label>
                    <p-dropdown appendTo="body" placeholder="Selecione" [options]="dropdownCanalAtendimento"
                        class="p-inputtext-sm" formControlName="canal_atendimento_id">
                    </p-dropdown>
                </div>

            </form>
        </p-tabPanel>

        <p-tabPanel *ngIf="form.get('habilitar_horario_funcionamento').value == true" header="Horario de funcionamento">

            <button type="button" class="btn btn-success waves-effect waves-light me-1"
                (click)="addHorarioFuncionamento()" style="margin: 10px;"><i class="fa fa-check-circle"></i>
                Adicionar</button>


            <ul class="list-group">
                <li class="list-group-item">
                    <div *ngFor="let item of arrayHorarioFuncionamento" class="row">
                        <div class="col-6 mt-2">
                            <p-dropdown appendTo="body" [(ngModel)]="item.dia_semana"
                                placeholder="Selecione o dia da semana" class="p-inputtext-sm"
                                [options]="arrayDiasSemana">
                            </p-dropdown>
                        </div>

                        <div class="p-fluid col-2" style="margin-top: 7px;">
                            <p-calendar appendTo="body" [(ngModel)]="item.abertura" [showTime]="true"
                                [showSeconds]="true" class="p-inputtext-sm" [showSeconds]="false" [timeOnly]="true"
                                inputId="time"></p-calendar>
                        </div>

                        <div class="col-1" style="margin-top: 12px;">
                            Até.
                        </div>

                        <div class="p-fluid col-2" style="margin-top: 7px;">
                            <p-calendar appendTo="body" [(ngModel)]="item.fechamento" [showTime]="true"
                                [showSeconds]="true" class="p-inputtext-sm" [showSeconds]="false" [timeOnly]="true"
                                inputId="time"></p-calendar>
                        </div>

                        <div class="col-1">
                            <button type="button" class="btn btn-danger waves-effect waves-light me-1"
                                (click)="removeHorarioFuncionamento(item)" style="margin: 3px;"><i
                                    class="ri-close-circle-line"></i>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </p-tabPanel>
    </p-tabView>

    <ng-template pTemplate="footer">

        <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
            ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

        <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
                class="pi pi-save"></i></button>
    </ng-template>
</p-dialog>