import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'relatorio');
  }

  pessoas(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/pessoas`, body);
  }

  reportPessoaGroupByCampoPersonalizado(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/pessoas/group-by/campo-personalizado`, body);
  }

}
