import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { ArquivoService } from 'src/app/core/services/arquivo.service';
import { CampoPersonalizadoServiceService } from 'src/app/core/services/campo-personalizado-service.service';
import { InsumoService } from 'src/app/core/services/insumo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})
export class ProdutosComponent {
  @ViewChild('fubauto') fubauto: FileUpload;


  dataSource = [];

  modal = false;

  form: FormGroup;

  campos_personalizados: any[] = []

  loading = false;

  imagemList = [];

  urlStorage = `${environment.urlS3}`;

  constructor(
    private formBuilder: FormBuilder,
    private campoPersonalizadoService: CampoPersonalizadoServiceService,
    private insumoService: InsumoService,
    private sanitizer: DomSanitizer,
    private arquivoService: ArquivoService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      ativo: [null],
    })


    this.index();
  }

  showModal(id = null) {
    this.loading = true;

    this.imagemList = [];

    this.modal = true;

    this.form.reset();

    this.form.get('ativo').setValue(true);

    this.campos_personalizados = [];
    if (id == null) {
      let query = 'localidade=PRODUTO';
      this.campoPersonalizadoService.index(null, query).subscribe(json => {

        json.map(item => {
          if (item.ativo == true) {
            this.campos_personalizados.push(item)
          }
        })

        this.loading = false
      })
    } else {

      this.insumoService.show(id).subscribe(insumoResponse => {

        this.form.patchValue(insumoResponse);

        if (insumoResponse.imagens.length > 0) {
          insumoResponse.imagens.map(imagem => {
            this.imagemList.push(imagem.arquivo);
          })
        }

        this.insumoService.getCamposPersonalizados(id).subscribe(camposResponse => {
          camposResponse.map(item => {
            this.campos_personalizados.push(item)
          });


          this.loading = false
        })
      })
    }


  }

  index() {
    this.dataSource = [];
    this.insumoService.index().subscribe(json => {
      this.dataSource = json
    })

  }

  save() {
    let form = this.form.value

    let data = {
      insumo: {
        id: form.id,
        ativo: form.ativo,
        nome: form.nome,
      },
      campos_personalizados: this.campos_personalizados
    }

    this.loading = true;

    this.insumoService.save(data, this.form.get('id').value).subscribe(produtoResponse => {

      let countFiles: number = 0;
      let formData = new FormData();

      this.imagemList.map(file => {
        if (!file?.id) {
          countFiles++;
          formData.append('myFiles[]', file);
        }
      })

      if (countFiles > 0) {
        this.arquivoService.uploadMulti(formData).subscribe(arquivosResponse => {

          let novosArquivos:any[] = arquivosResponse;

          this.imagemList.map(arquivo => {
            if (arquivo?.id) {
              novosArquivos.push(arquivo);
            }
          })

          let body = {
            arquivos: novosArquivos,
            produto: produtoResponse
          }

          this.insumoService.linkFileProduto(body).subscribe(json => {
            this.loading = false;
            this.modal = false;
            this.index();
          })
        })
      } else {
        let novosArquivos:any[] = [];

        this.imagemList.map(arquivo => {
          if (arquivo?.id) {
            novosArquivos.push(arquivo);
          }
        })

        let body = {
          arquivos: novosArquivos,
          produto: produtoResponse
        }

        this.insumoService.linkFileProduto(body).subscribe(json => {
          this.loading = false;
          this.modal = false;
          this.index();
        })
      }

    })
  }

  fileToSrc(file) {
    if (file?.id) {
      return this.urlStorage + '/' + file.path;
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(file['objectURL']['changingThisBreaksApplicationSecurity'])
    }
  }

  myUploader(event) {
    event.files.map(file => {
      this.imagemList.push(file);
    })
    this.fubauto.clear();
  }

  removeImagem(item) {
    let imagens = this.imagemList.filter(i => {return i != item});
    this.imagemList = imagens;
  }
}
