import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { ChatModule } from './chat/chat.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { initFirebaseBackend } from './authUtils';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import {TabViewModule} from 'primeng/tabview'; 
import {ToolbarModule} from 'primeng/toolbar';
import {ButtonModule} from 'primeng/button';

import {TableModule} from 'primeng/table';
import { CadastrosModule } from './cadastros/cadastros.module';
import { AppConfigService } from './core/services/app-config.service';
import { SettingsPageModule } from './settings/settings-page.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCadastroModule } from './auto-cadastro/auto-cadastro.module';
import { AgendaComponent } from './agenda/agenda.component';
import { AgendaModule } from './agenda/agenda.module';
import { TimelineModule } from 'primeng/timeline';
import { SharedModule } from './shared/shared.module';

if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}

export function initializeApp(appConfigService: AppConfigService) {
  
  return () => appConfigService.initializeConfig();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    ToolbarModule,
    ButtonModule,
    BrowserModule,
    AppRoutingModule,
    TableModule,
    ChatModule,
    ToastModule,
    TabViewModule,
    CadastrosModule,
    AutoCadastroModule,
    SettingsPageModule,
    SharedModule,
    AgendaModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
    ConfirmationService, MessageService
  ],
  bootstrap: [AppComponent],
  exports: []  
})
export class AppModule { }
