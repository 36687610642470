import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export abstract class GenericService {
  protected prefixo: any;

  url_base: string = environment.urlBase + '/api';

  production = environment.production;

  constructor(protected http: HttpClient, protected target: any) {
    this.prefixo = target;
  }

  grid(query = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/get/grid`;

    if (query != null) {
      url += query
    }

    return this.http.get(url);
  }


  index(page = null, request = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}`;
    if (page !== null) {
      url += '?page=' + page;

      if (request != null) {
        url += '&' + request
      }
    } else {
      if (request != null) {
        url += '?&' + request
      }
    }
    return this.http.get(url);
  }


  save(params = null, id = null): Observable<any> {
    if (id != null) {
      return this.http.put(`${this.url_base}/${this.prefixo}/${id}`, params);
    } else {
      return this.http.post(`${this.url_base}/${this.prefixo}`, params);
    }
  }

  show(id = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/${id}`);
  }

  delete(id = null): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/${id}`);
  }

  update(params = null, id = null): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/${id}`, params);
  }

  validatorToken(params): Observable<any> {
    return this.http.post(`${this.url_base}/token/validator`, params);
  }

  salvarAutoCadastro(params): Observable<any> {
    return this.http.post(`${this.url_base}/token/save`, params);
  }



}
