<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 350px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['nome']" [rowHover]="true">
  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>
      </div>

      <div class="p-toolbar-group-right d-flex">
        <button type="button" class="btn btn-primary btn mr-2" (click)="add()" ngbTooltip="Adicionar"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th>Descrição</th>
      <th>Whatsapp Polo</th>
      <th>Whatsapp Polo Agressivo</th>
      <th>Whatsapp QG</th>
      <th>Whatsapp QG Agressivo</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{item.descricao_sallesforce}}</td>
      <td>{{item.whatsapp_polo}}</td>
      <td>{{item.whatsapp_polo_agressivo}}</td>
      <td>{{item.whatsapp_qg}}</td>
      <td>{{item.whatsapp_qg_agressivo}}</td>
      <td >
        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="show(item.id)" ngbTooltip="Editar"><i
            class="pi pi-pencil"></i></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Configuração" [(visible)]="modal" [style]="{width: '35vw'}">
  <div *ngIf="loadingForm" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>

  <form *ngIf="!loadingForm" [formGroup]="form" class="row g-2 p-3">

    <div class="col-sm-12">
      <label for="lastname2">Nome no Salesforce</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="descricao_sallesforce" />
    </div>

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">Departamento</label>
      <p-dropdown appendTo="body" placeholder="Selecione" [filter]="true" [options]="dropdownDepartamento"
        class="p-inputtext-sm" formControlName="departamento_id">
      </p-dropdown>
    </div>

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">Campo personalizado</label>
      <p-dropdown [filter]="true" appendTo="body" placeholder="Selecione" [options]="dropdownCamposPesonalizados"
        class="p-inputtext-sm" formControlName="campo_personalizado_id" (onChange)="changeCampoPersonalizado()">
      </p-dropdown>
    </div>

    <div *ngIf="dropdownCampoOpcao.length > 0" class="col-sm-12 p-fluid">
      <label for="lastname2">Valor</label>
      <p-dropdown [filter]="true" appendTo="body" placeholder="Selecione" [options]="dropdownCampoOpcao"
        class="p-inputtext-sm" formControlName="campo_personalizado_valor">
      </p-dropdown>
    </div>

    <div *ngIf="dropdownCampoOpcao.length == 0" class="col-sm-12">
      <label for="lastname2">Valor</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="campo_personalizado_valor" />
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Whatsapp polo</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="whatsapp_polo" />
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Whatsapp polo agressivo</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="whatsapp_polo_agressivo" />
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Whatsapp qg</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="whatsapp_qg" />
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Whatsapp qg agressivo</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="whatsapp_qg_agressivo" />
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Link do polo</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="link_polo" />
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Endereço do polo</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="endereco_polo" />
    </div>

  </form>

  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
        class="pi pi-save"></i></button>
  </ng-template>

</p-dialog>