<p-toast></p-toast>


<p-dialog header="Nova conversa" [(visible)]="modalNewChat" [style]="{width: '50vw'}" (onHide)="close()">


  <div *ngIf="loadingNovaConversa == true" class="col-md-12" style="text-align: center; margin-top: 5px;">

    <p-progressSpinner [style]="{width: '20px', height: '20px'}" styleClass="custom-spinner" strokeWidth="8"
      fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

  </div>

  <form *ngIf="!loadingNovaConversa" [formGroup]="formAtendimento" class="row">
    <div class="col-sm-12">
      <label for="lastname2">Nome</label>
      <input pInputText class="form-control" formControlName="nome" />
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div>

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">WhatsApp</label>
      <input pInputText class="form-control" formControlName="whatsapp" />
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório (Informe o numero com o DDD)</small>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Canal de atendimento</label>

      <p-progressSpinner *ngIf="loadInstanciaConversa" [style]="{width: '20px', height: '20px'}"
        styleClass="custom-spinner" strokeWidth="8" fill="var(--surface-ground)"
        animationDuration=".5s"></p-progressSpinner>


      <p-dropdown [disabled]="hasCanalInDialogo" appendTo="body" placeholder="Selecione um canal"
        [options]="dropdownCanalAtendimento" formControlName="canal_atendimento_id"></p-dropdown>

      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>

    </div>

    <div class="col-sm-12">
      <label for="lastname2">Resposta rapida</label>
      <input pInputText class="form-control" formControlName="resposta_rapida" (input)="onGetResposta()" />
    </div>

    <div class="col resposta-rapida" *ngIf="respostaRapidaNovaConversa">
      <div class="p-fluid row col-12 pt-4">
        <div class="col-2" (click)="respostaRapidaNovaConversa = false">
          <span class="btn btn-sm btn-danger float-right cancel_reply_msg"><i class="pi pi-times"></i></span>
        </div>
      </div>

      <ul *ngFor="let resposta of fastResponseListNovaConversa" class="item-response-fast">

        <div class="row col-10 d-block mt-3" style="cursor: pointer;"
          (click)="copiaRespostaRapidaNovaConversa(resposta)">
          <article>
            <h4 style="font-weight: bolder;">{{resposta.atalho}}</h4>
            <p>{{resposta.texto}}</p>
          </article>
        </div>

      </ul>
    </div><!-- end col-->

    <div class="col-sm-12 p-fluid">
      <label for="lastname2">Anotação interna</label>
      <textarea rows="5" cols="5" pInputTextarea formControlName="anotacaoInterna" class="p-inputtext-sm"></textarea>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Dialogo</label>
      <p-dropdown appendTo="body" placeholder="Selecione um dialogo" [options]="dropdownGatilho"
        formControlName="gatilho_id" (onChange)="listInstanciasUsuario(true)"></p-dropdown>
    </div>
  </form>


  <ng-template pTemplate="footer">
    <p-button *ngIf="!loadingNovaConversa" [disabled]="formAtendimento.invalid" icon="pi pi-check"
      (click)="startConversa()" label="Iniciar conversa" styleClass="p-button-primary"></p-button>
  </ng-template>
</p-dialog>