import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ChatService } from 'src/app/core/services/chat.service';
import { GatilhoService } from 'src/app/core/services/gatilho.service';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';
import { RespostaRapidaService } from 'src/app/core/services/resposta-rapida.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-nova-conversa',
  templateUrl: './nova-conversa.component.html',
  styleUrls: ['./nova-conversa.component.css'],
  providers: [MessageService]
})
export class NovaConversaComponent implements OnInit {
  @Input() modalNewChat: boolean = false;

  @Input() contato: any = null;

  @Output() onStartConversa: EventEmitter<any> = new EventEmitter();

  @Output() onHide: EventEmitter<any> = new EventEmitter();

  loadingNovaConversa: boolean = false;

  formAtendimento: FormGroup;

  fastResponseListNovaConversa = [];

  respostaRapidaNovaConversa: boolean = false;

  dropdownCanalAtendimento: any[] = [];

  loadInstanciaConversa: boolean = false;

  loadingDialogos: boolean = false;

  userLogado: any = JSON.parse(localStorage.getItem('user'));

  dropdownGatilho: any[] = [];

  hasCanalInDialogo: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private respostaRapidaService: RespostaRapidaService,
    private chatService: ChatService,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
    private gatilhoService: GatilhoService,
    private canalAtendimentoService: IntegracaoWhatsappService,
  ) { }

  ngOnInit() {

    this.formAtendimento = this.formBuilder.group({
      canal_atendimento_id: [null, Validators.required],
      resposta_rapida: [],
      gatilho_id: [null],
      whatsapp: [null, Validators.required],
      nome: [null, Validators.required],
      anotacaoInterna: [null],
    });

    if (this.contato != null) {
      this.formAtendimento.get('nome').setValue(this.contato.nome);
      this.formAtendimento.get('whatsapp').setValue(this.contato.celular_principal.substring(2));
    }

    this.listInstanciasUsuario();
    this.listAllGatilhos();
  }

  listInstanciasUsuario(getGatilho = false) {
    this.loadInstanciaConversa = true;
    this.dropdownCanalAtendimento = [];
    this.usuarioService.listInstancias(this.userLogado.id).subscribe(json => {
      this.loadInstanciaConversa = false;
      json.map(item => {
        this.dropdownCanalAtendimento.push({ value: item.id, label: `${item.whatsapp} - ${item.nome}` })
      });
    
      if (getGatilho) {
        this.changeGatilho();
      } else {
        this.formAtendimento.get('canal_atendimento_id').setValue(this.dropdownCanalAtendimento[0]['value']);
      }
    })
  }

  listAllGatilhos() {
    this.loadingDialogos = true;
    this.gatilhoService.listByDepartamentoUsuario().subscribe(json => {
      this.dropdownGatilho = json.map(item => { return { value: item.id, label: item.nome, acoes: item.acoes } });
      this.loadingDialogos = false;
    })
  }

  changeGatilho() {
    this.hasCanalInDialogo = false;
    let gatilho = this.dropdownGatilho.find(g => { return g.value == this.formAtendimento.get('gatilho_id').value });
    gatilho.acoes.forEach(element => {
      let acao = JSON.parse(element['acao']);
      if (acao.tipo == 7) {
        this.hasCanalInDialogo = true;
        this.loadInstanciaConversa = true;
        this.canalAtendimentoService.index().subscribe(json => {
          this.loadInstanciaConversa = false;
          json.map(item => {
            this.dropdownCanalAtendimento.push({ value: item.id, label: `${item.whatsapp} - ${item.nome}` })
          })
          this.formAtendimento.get('canal_atendimento_id').setValue(acao.valor);
        })
      }
    });
  }

  onGetResposta() {
    this.fastResponseListNovaConversa = [];

    let msg = this.formAtendimento.get('resposta_rapida').value;

    if (msg.substring(0, 2) === "//") {
      this.respostaRapidaNovaConversa = true;

      let value = msg.replace(/\/\//g, '');
      if (value == null || value == '') {
        value = 'ALL';
      }

      let atalhoRespostaRapida = value;

      this.respostaRapidaService.autoCompleteResposta(atalhoRespostaRapida).subscribe(json => {
        this.fastResponseListNovaConversa = json;
      })
    }
  }


  copiaRespostaRapidaNovaConversa(resposta) {
    this.formAtendimento.get('resposta_rapida').setValue(resposta['texto']);
    this.respostaRapidaNovaConversa = false;
  }

  startConversa() {

    if (this.formAtendimento.invalid) {
      return;
    }

    let whatsapp = this.formAtendimento.get('whatsapp').value;
    let body = {
      nome: this.formAtendimento.get('nome').value,
      whatsapp: `55${whatsapp.replace(/\D/g, '')}`,
      mensagem: this.formAtendimento.get('resposta_rapida').value,
      canal_atendimento_id: this.formAtendimento.get('canal_atendimento_id').value,
      gatilho_id: this.formAtendimento.get('gatilho_id').value,
      anotacaoInterna: this.formAtendimento.get('anotacaoInterna').value,
    }

    this.loadingNovaConversa = true;


    this.chatService.startConversa(body).subscribe(json => {

      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Contato cadastrado' });
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Atendimento gerado' });

      if (json.dialogoExecutado) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Dialogo executado com sucesso' });
      }

      setTimeout(() => {
        this.loadingNovaConversa = false;
        this.modalNewChat = false;
        this.onStartConversa.emit();
      }, 1000);
    });
  }

  close() {
    this.onHide.emit();
  }
}
