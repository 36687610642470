import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { ChatService } from 'src/app/core/services/chat.service';
import { GatilhoService } from 'src/app/core/services/gatilho.service';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';
import { PessoaService } from 'src/app/core/services/pessoa.service';
import { RespostaRapidaService } from 'src/app/core/services/resposta-rapida.service';
import { TagService } from 'src/app/core/services/tag.service';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent {
  form: FormGroup;
  dropDownTipo = [];
  dropDownTag = [];

  loading = false;

  modalNewChat = false;

  dropdownCanalAtendimento = [];

  @Input() contactSelected;

  @Output() onNewChat: EventEmitter<any> = new EventEmitter();
  @Output() onList: EventEmitter<any> = new EventEmitter();
  dropdownTipo = [
    { value: 'FISICA', label: 'Pessoa Fisica' },
    { value: 'JURIDICA', label: 'Pessoa Juridica' },
  ]
  formAtendimento: FormGroup;

  fastResponseList = [];

  respostaRapida = false;

  atalhoRespostaRapida;

  dropdownGatilho = [];

  constructor(
    private formBuilder: FormBuilder,
    private chatService: ChatService,
    private pessoaService: PessoaService,
    private http: HttpClient,
    private tagService: TagService,
    private canalAtendimentoService: IntegracaoWhatsappService,
    private respostaRapidaService: RespostaRapidaService,
    private gatilhoService: GatilhoService,
    private messageService: MessageService
  ) { }


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      pessoa: this.formBuilder.group({
        id: [null],
        ativo: [true],
        nome: [null, Validators.required],
        tipo: ['FISICA'],
        telefone_principal: [null],
        celular_principal: [null],
        email_principal: [null],
        cliente: [true],
        funcionario: [null],
        fornecedor: [null],
        permitir_chatbot: [true],
      }),
      fisica: this.formBuilder.group({
        nascimento: [null],
        apelido: [null],
        cpf: [null],
        rg: [null],
      }),
      juridica: this.formBuilder.group({
        razao_social: [null],
        cnpj: [null],
        ie: [null],
      }),
      endereco: this.formBuilder.group({
        cep: [null],
        logradouro: [null],
        bairro: [null],
        municipio: [null],
        complemento: [null],
        uf: [null],
        numero: [null],
      }),
      tag: this.formBuilder.group({
        tags: [] = [],
      }),
    });

    this.formAtendimento = this.formBuilder.group({
      canal_atendimento_id: [Validators.required],
      resposta_rapida: [],
      gatilho_id: [null],
    })

    this.listAllTags();

    this.listCanalAtendimentos();

    this.form.disable();

    this.listAllGatilhos();
  }

  buscaCEP() {
    let cep = this.form.get("endereco.cep").value;

    try {
      cep = parseInt(cep.replace(/[^0-9]/g, ""));
    } catch (error) {
      cep = this.form.get("endereco.cep").value;
    }

    if (cep == null) {
      return;
    }

    this.http
      .get(`https://viacep.com.br/ws/${cep}/json`)
      .toPromise()
      .then((response) => {
        response["municipio"] = response["localidade"];
        response["cep"] = cep;
        this.form.get("endereco").patchValue(response);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.contactSelected && changes.contactSelected?.currentValue?.id) {

      this.form.reset();

      this.loading = true;

      this.pessoaService.show(changes.contactSelected.currentValue.id).subscribe(pessoa => {
        this.loading = false;

        if (pessoa?.fisica?.nascimento != null) {
          pessoa.fisica.nascimento = moment(pessoa?.fisica?.nascimento).tz("Etc/GMT+0").toDate();
        }

        this.form.get('pessoa').patchValue(pessoa);
        this.form.get('fisica').patchValue(pessoa.fisica);
        this.form.get('juridica').patchValue(pessoa.juridica);
        this.form.get('endereco').patchValue(pessoa.endereco);

        if (pessoa.tags.length > 0) {
          let tags = [];
          pessoa.tags.map(item => {
            tags.push({ value: item.id, label: item.descricao });
          });
          this.form.get('tag.tags').setValue(tags);
        }
      })
    }
  }


  onBlur() {
    this.fastResponseList = [];
    let msg = this.formAtendimento.get('resposta_rapida').value;

    if (msg == null || msg == '') {
      this.respostaRapida = false;
    }

    if (msg.substring(0, 2) === "//") {
      this.respostaRapida = true;

      let value = msg.replace(/\/\//g, '');
      if (value == null || value == '') {
        value = 'ALL';
      }

      this.atalhoRespostaRapida = value;

      this.respostaRapidaService.autoCompleteResposta(this.atalhoRespostaRapida).subscribe(json => {
        this.fastResponseList = json;
      })
    }
  }

  copiaRespostaRapida(resposta) {
    this.formAtendimento.get('resposta_rapida').setValue(resposta['texto']);
    this.respostaRapida = false;
  }

  showModalNewChat() {
    this.modalNewChat = true;
  }

  listCanalAtendimentos() {
    this.canalAtendimentoService.index().subscribe(json => {
      this.dropdownCanalAtendimento = json.map(item => { return { value: item.id, label: `${item.whatsapp} - ${item.identificador}` } });
    })
  }

  startConversa() {
    this.modalNewChat = false;
    this.onNewChat.emit(null)
  }

  // newChat() {

  //   let body = {
  //     nome: this.contactSelected.name,
  //     whatsapp: this.contactSelected.whatsapp,
  //     mensagem: null,
  //     pessoaId: this.form.get('pessoa.id').value,
  //     canal_atendimento_id: this.formAtendimento.get('canal_atendimento_id').value,
  //     resposta_rapida: this.formAtendimento.get('resposta_rapida').value,
  //     gatilho_id: this.formAtendimento.get('gatilho_id').value,
  //   }

  //   this.chatService.newChat(body).subscribe(json => {
  //     this.modalNewChat = false;
  //     this.onNewChat.emit(json.id);
  //   });

  // }

  save() {
    this.loading = true;

    let body = {
      pessoa: this.form.get('pessoa').value,
      pessoaFisica: this.form.get('fisica').value,
      pessoaJuridica: this.form.get('juridica').value,
      endereco: this.form.get('endereco').value,
      tags: this.form.get('tag.tags').value
    }

    this.contactSelected.name = this.form.get('pessoa.nome').value;
    this.contactSelected.whatsapp = this.form.get('pessoa.celular_principal').value;

    this.pessoaService.save(body, this.form.get('pessoa.id').value).subscribe(json => {
      this.loading = false;
      this.cancel();
      this.onList.emit();
    })
  }

  cancel() {
    this.form.disable();
  }

  listAllTags() {
    this.dropDownTag = [];
    this.tagService.index().subscribe(tags => {
      this.dropDownTag = tags.map(item => { return { value: item.id, label: item.descricao } });
    })
  }

  listAllGatilhos() {
    this.gatilhoService.index().subscribe(json => {
      this.dropdownGatilho = json.map(item => { return { value: item.id, label: item.nome } });
    })
  }

  saveDadoPessoa(item) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: `${item.campo} salvo com sucesso` });
  }
}
