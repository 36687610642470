<p-table *ngIf="page == 'LIST' && !loading" #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines"
  [scrollable]="true" [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both"
  [autoLayout]="true" [globalFilterFields]="['nome']" [rowHover]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>


      </div>

      <div class="p-toolbar-group-right d-flex">



        <button *ngIf="direitoService.can('INSERIR_GATILHOS_CHAT')" type="button" class="btn btn-primary btn mr-2"
          (click)="adicionar()" ngbTooltip="Adicionar"><i class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr c>
      <th class="th-codigo">Código</th>
      <th>Nome</th>
      <th style="max-width:100px;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td class="th-codigo">{{item.id}}</td>
      <td>{{item.nome}}</td>
      <td style="max-width:100px;">
        <button *ngIf="direitoService.can('ALTERAR_GATILHOS_CHAT')" type="button" class="btn btn-primary btn-sm"
          (click)="show(item.id)" ngbTooltip="Editar" style="margin-right: 5px;"><i class="pi pi-pencil"></i></button>
        <button type="button" class="btn btn-primary btn-sm" (click)="duplicate(item.id)" ngbTooltip="Duplicar"><i
            class="pi pi-copy"></i></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>


<div *ngIf="page == 'FORM' && !loading" class="p-fluid  row mt-2">

  <div class="col-lg-12">
    <div class="card">
      <div class="card-body row" style="background-color: #edeff1;">
        <div class="d-flex justify-content-start ofset-6 col-md-12 mb-12">
          <div class="text-md-start mt-3 mt-md-0">

            <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="page = 'LIST'"
              ngbTooltip="Fechar"><i class="pi pi-arrow-left"></i></button>

            <button *ngIf="form.get('id').value !== null" (click)="duplicate(form.get('id').value)" type="button"
              class="btn btn-primary btn mr-2" style="margin-right: 5px;" (click)="page = 'LIST'"
              ngbTooltip="Duplicar"><i class="pi pi-copy"></i></button>

            <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
                class="pi pi-save"></i></button>



          </div>
        </div>
      </div>
    </div>
  </div>



  <form [formGroup]="form" class="row g-2" style="margin-bottom: 10px;">

    <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
        <label class="form-check-label" for="customCheck2">Ativo</label>
      </div>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Nome</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="nome" />
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Departamento</label>
      <p-multiSelect appendTo="body" optionLabel="nome" [options]="dropdownDepartamento" formControlName="departamentos"
        class="p-inputtext-sm">
      </p-multiSelect>
      <!-- <p-dropdown appendTo="body" placeholder="Selecione um departamento" [options]="dropdownDepartamento"
        formControlName="departamento_id"></p-dropdown> -->
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div>

  </form>

  <div class="alert alert-primary" role="alert">
    <b>Gatilhos:</b> Os gatilhos permitem que você execute ações quando determinadas condições são cumpridas. Essas
    condições podem ser acionadas por ações de usuários ou rotinas automatizadas da plataforma
  </div>

  <div class="alert alert-success" role="alert">
    <b>Condições:</b> Defina as condições para o gatilho ser exectuado.
  </div>

  <div class="alert alert-warning" role="alert">
    <b>Ações:</b> Defina qual ação será executada, quando as condições de uma conversa forem atendidas.
  </div>

  <p-tabView>

    <p-tabPanel header="Gatilhos">

      <div class="col-md-1">
        <button type="button" class="btn btn-primary mb-2" ngbTooltip="Adicionar" (click)="addGatilho()"><i
            class="pi pi-plus"></i></button>
      </div>

      <div *ngFor="let item of gatilhoList" class="p-fluid row" style="margin-bottom: 10px;">

        <div class="col-md-6">
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.tipo" [options]="dropdownGatilhos">
          </p-dropdown>
        </div>

        <div class="col-md-1">


          <button type="button" class="btn btn-danger ml-2" ngbTooltip="Remover" (click)="removerGatilho(item)"><i
              class="pi pi-times"></i></button>
        </div>

      </div>

    </p-tabPanel>

    <p-tabPanel header="Condições">
      <div class="col-md-1" style="margin-top: 25px;">
        <button type="button" class="btn btn-primary mb-2" ngbTooltip="Adicionar" (click)="addCondicao()"><i
            class="pi pi-plus"></i></button>
      </div>

      <small class="badge badge-soft-danger me-1 rounded-pill" style="margin-bottom: 20px;">As estapas desse dialogo só
        serão executadas se o
        atendimento respeitar as seguintes condições</small>

      <h6>Atende se TODAS as condições a seguir forem respeitadas</h6>

      <div *ngFor="let item of condicoesList" class="p-fluid row">

        <div class="col-md-3">
          <label>Campo</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.campo" [options]="dropdownCampo"
            (onChange)="changeCampo(item.campo)">
          </p-dropdown>
        </div>

        <div
          *ngIf="item.campo != 'ultima_mensagem_recebida' && item.campo != 'ultima_mensagem_enviada' && item.campo != 'ultima_atualizacao_status'"
          class="col-md-3">
          <label>Condição</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item .condicao" [options]="dropdownCondicao">
          </p-dropdown>
        </div>

        <div
          *ngIf="item.campo == 'ultima_mensagem_recebida' || item.campo == 'ultima_mensagem_enviada' || item.campo == 'ultima_atualizacao_status'"
          class="col-md-3">
          <label>Condição</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.condicao"
            [options]="dropdownCondicaoNumerico">
          </p-dropdown>
        </div>

        <div *ngIf="item.campo == 'chat_contato.canal_atendimento_id'" class="col-md-3">
          <label>Instancia de whatsapp</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.valor"
            [options]="dropdownCanalAtendimentoGatilho"></p-dropdown>
        </div>

        <div *ngIf="item.campo == 'negociacao.etapa_funil_id'" class="col-md-3">
          <label>Funil</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.valor" [options]="dropdownFunil">
          </p-dropdown>
        </div>

        <div
          *ngIf="item.campo == 'ultima_mensagem_recebida' || item.campo == 'ultima_mensagem_enviada' || item.campo == 'ultima_atualizacao_status'"
          class="col-md-3">
          <label>Horas</label>
          <p-inputNumber [(ngModel)]="item.valor" mode="decimal" [min]="0" [step]="0.25" [showButtons]="true"
            suffix=" h" class="p-inputtext-sm"></p-inputNumber>
        </div>

        <div *ngIf="item.campo == 'chat_contato.situacao'" class="col-md-3">
          <label>Situação</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.valor" [options]="dropdownStatus">
          </p-dropdown>
        </div>

        <div *ngIf="item.campo == 'chat_contato.novo_contato'" class="col-md-3">
          <label>Valor</label>
          <p-dropdown placeholder="Selecione" [(ngModel)]="item.valor" [options]="dropdownBoolean"></p-dropdown>
        </div>

        <div *ngIf="item.campo == 'chat_contato_mensagem.conteudo'" class="col-md-3">
          <label>Texto</label>
          <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="item.valor" class="p-inputtext-sm"></textarea>
        </div>

        <div *ngIf="item.campo == 'campo_personalizado.id'" class="col-md-3">
          <label>Valor</label>
          <input pInputText class="form-control" [(ngModel)]="item.valor" />
        </div>




        <div class="col-md-1" style="margin-top: 25px;">
          <button type="button" class="btn btn-danger ml-2" ngbTooltip="Remover"
            (click)="removerCondicao(item, 'AND')"><i class="pi pi-times"></i></button>
        </div>

      </div>

      <hr>


      <div class="col-md-1" style="margin-top: 25px;">
        <button type="button" class="btn btn-primary mb-2" ngbTooltip="Adicionar" (click)="addOrCondicao()"><i
            class="pi pi-plus"></i></button>
      </div>

      <h6>Atende se QUALQUER uma das condições a seguir forem respeitadas</h6>
      <div *ngFor="let item of condicoesOrList" class="p-fluid row">

        <div class="col-md-3">
          <label>Campo</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.campo" [options]="dropdownCampo">
          </p-dropdown>
        </div>

        <div
          *ngIf="item.campo != 'ultima_mensagem_recebida' && item.campo != 'ultima_mensagem_enviada' && item.campo != 'ultima_atualizacao_status'"
          class="col-md-3">
          <label>Condição</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item .condicao" [options]="dropdownCondicao">
          </p-dropdown>
        </div>

        <div
          *ngIf="item.campo == 'ultima_mensagem_recebida' || item.campo == 'ultima_mensagem_enviada' || item.campo == 'ultima_atualizacao_status'"
          class="col-md-3">
          <label>Condição</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.condicao"
            [options]="dropdownCondicaoNumerico">
          </p-dropdown>
        </div>

        <div *ngIf="item.campo == 'chat_contato.canal_atendimento_id'" class="col-md-3">
          <label>Instancia de whatsapp</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.valor"
            [options]="dropdownCanalAtendimentoGatilho"></p-dropdown>
        </div>

        <div *ngIf="item.campo == 'negociacao.etapa_funil_id'" class="col-md-3">
          <label>Funil</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.valor" [options]="dropdownFunil">
          </p-dropdown>
        </div>

        <div
          *ngIf="item.campo == 'ultima_mensagem_recebida' || item.campo == 'ultima_mensagem_enviada' || item.campo == 'ultima_atualizacao_status'"
          class="col-md-3">
          <label>Horas</label>
          <p-inputNumber [(ngModel)]="item.valor" mode="decimal" [min]="0" [step]="0.25" [showButtons]="true"
            suffix=" h" class="p-inputtext-sm"></p-inputNumber>
        </div>

        <div *ngIf="item.campo == 'chat_contato.situacao'" class="col-md-3">
          <label>Situação</label>
          <p-dropdown placeholder="Selecione" filter="true" [(ngModel)]="item.valor" [options]="dropdownStatus">
          </p-dropdown>
        </div>

        <div *ngIf="item.campo == 'chat_contato.novo_contato'" class="col-md-3">
          <label>Valor</label>
          <p-dropdown placeholder="Selecione" [(ngModel)]="item.valor" [options]="dropdownBoolean"></p-dropdown>
        </div>

        <div *ngIf="item.campo == 'chat_contato_mensagem.conteudo'" class="col-md-3">
          <label>Texto</label>
          <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="item.valor" class="p-inputtext-sm"></textarea>
        </div>

        <div *ngIf="item.campo == 'campo_personalizado.id'" class="col-md-3">
          <label>Valor</label>
          <input pInputText class="form-control" [(ngModel)]="item.valor" />
        </div>


        <div class="col-md-1" style="margin-top: 25px;">
          <button type="button" class="btn btn-danger ml-2" ngbTooltip="Remover"
            (click)="removerCondicao(item, 'OR')"><i class="pi pi-times"></i></button>
        </div>

      </div>
    </p-tabPanel>


    <p-tabPanel header="Ações">

      <div style="overflow: auto; height: calc(100vh - 270px);">
        <form [formGroup]="form" class="row g-2">



          <div class="col-sm-6 mt-4">
            <label for="lastname2">Selecione uma ação</label>

            <div class="p-inputgroup">
              <p-dropdown filter="true" placeholder="Selecione" appendTo="body" [options]="items"
                formControlName="acao"></p-dropdown>
              <button type="button" pButton pRipple icon="pi pi-plus " styleClass="p-button-success p-button-sm"
                (click)="addAcao()"></button>
            </div>

            <!-- <p-splitButton appendTo="body" label="Adicionar ação" [model]="items" styleClass="splitbutton p-button-raised mr-2 mb-2">
            </p-splitButton> -->
          </div>

        </form>

        <div *ngFor="let item of acaoList" class="mt-4 row acoes">
          <div class="col-sm-10 row">
            <label>{{item['descricao']}}</label>

            <textarea *ngIf="item.tipo == 1" rows="5" cols="30" pInputTextarea [(ngModel)]="item.valor"
              class="p-inputtext-sm"></textarea>

            <small *ngIf="item.tipo == 1" class="badge badge-soft-danger">Para enviar um campo personalizado informe:
              [cp:id_campo] <b>Ex: Segue o numero do protocolo: [cp:1] (onde 1 é o ID do campo cadastrado no
                sistema)</b></small>

            <small *ngIf="item.tipo == 1" class="badge badge-soft-danger">Para enviar a etapa de um determinado funil de
              atendimento em que o contato se encontra, informe:
              [fn:id_campo] <b>Ex: [cp:1] (onde id_campo é o ID do funil no sistema)</b></small>

            <p-dropdown appendTo="body" [filter]="true" placeholder="Selecione um usuario" [disabled]="!item.habilitado"
              *ngIf="item.tipo == 2" [options]="dropdownUsuario" [(ngModel)]="item.valor"></p-dropdown>

            <p-multiSelect *ngIf="item.tipo == 3" [options]="dropdownTag" [(ngModel)]="item.valor"
              defaultLabel="Selecione as tags" display="chip"></p-multiSelect>

            <p-dropdown appendTo="body" [filter]="true" placeholder="Selecione um departamento"
              *ngIf="item.tipo == 4 || item.tipo == 9" [options]="dropdownDepartamentoEtapa"
              (onChange)="getUsuarioDepartamento(item.valor, item)" [(ngModel)]="item.valor" (onChange)="changeUsuarioDepartamento(item.valor, item)"></p-dropdown>

            <label *ngIf="item.tipo == 4" class="form-check-label" for="customCheck2">Ativar rodizio
              (Automatico) entre os usuários do departamento (essa opção só é acionada caso o atendimento ainda não
              esteja
              vinculado a nenhum atendente)</label>


            <p-checkbox *ngIf="item.tipo == 4" [(ngModel)]="item.rodizio" [binary]="true" inputId="binary" ></p-checkbox>

            <p-multiSelect *ngIf="item.tipo == 4 && item.rodizio" [options]="item.usuarioRodizioList"
              [(ngModel)]="item.usuarioRodizioValues" defaultLabel="Selecione os usuarios"></p-multiSelect>

            <p-dropdown appendTo="body" [filter]="true" placeholder="Selecione um status" *ngIf="item.tipo == 5"
              [options]="dropdownStatus" [(ngModel)]="item.valor"></p-dropdown>

            <p-dropdown *ngIf="item.tipo == 11" [filter]="true" appendTo="body" placeholder="Selecione uma etapa"
              [options]="dropdownFunil" [(ngModel)]="item.valor"></p-dropdown>

            <p-fileUpload *ngIf="item.tipo == 6" #myFile name="myFile" chooseLabel="Selecione um arquivo" [auto]="true"
              [customUpload]="true" (uploadHandler)="uploadHandlerDoc($event, item)">

              <ng-template pTemplate="content">
                <ul *ngIf="item.files">

                  <div *ngFor="let file of item.files" class="d-flex justify-content-between">
                    <div>
                      <li>{{file.name || file.original_name}} </li>
                    </div>

                    <div>
                      <button type="button" class="btn btn-danger ml-2" ngbTooltip="Remover"
                        (click)="deleteFile(item)"><i class="pi pi-times"></i></button>
                    </div>
                  </div>


                </ul>
              </ng-template>
            </p-fileUpload>

            <p-dropdown appendTo="body" placeholder="Selecione um canal de atendimento"
              *ngIf="item.tipo == 7 || item.tipo == 10" [options]="dropdownCanalAtendimento" [(ngModel)]="item.valor">
            </p-dropdown>

            <div class="col-sm-6" *ngIf="item.tipo == 8">
              <p-dropdown appendTo="body" placeholder="Selecione um campo persnalizado"
                [options]="dropdownCamposPersonalizados" [(ngModel)]="item.objeto"></p-dropdown>
            </div>

            <div class="col-sm-6" *ngIf="item.tipo == 8">
              <input pInputText class="form-control" [(ngModel)]="item.valor" />
              <small *ngIf="item.tipo == 8" class="badge badge-soft-danger">Para salvar o nome do usuario logado informe
                <b>[usuario@sessao] </b></small>
            </div>


            <div class="row mt-4" *ngIf="item.tipo == 12">

              <div class="col-sm-12 mb-2">
                <label for="lastname2">Informe o departamento</label>
                <p-dropdown appendTo="body" placeholder="Selecione um departamento"
                  [options]="dropdownDepartamentoEtapa" [(ngModel)]="item.valor"></p-dropdown>
              </div>

              <div class="p-fluid col-2">
                <p-checkbox [(ngModel)]="item.encerrarBot" [binary]="true" inputId="binary"
                  label="Finalizar o bot após a validação do horário"></p-checkbox>
              </div>

              <div class="col-sm-12 mb-2">
                <label for="lastname2">Informe o departamento a ser direcionado caso o departamento selecionado pelo
                  cliente, esteja fora do horário de
                  funcionamento</label>
                <p-dropdown appendTo="body" placeholder="Selecione um departamento"
                  [options]="dropdownDepartamentoEtapa" [(ngModel)]="item.valorAuxiliar"></p-dropdown>
              </div>

              <div class="col-sm-12 mb-2">
                <label for="lastname2">Digite uma mensagem a ser enviada caso o departamento selecionado pelo
                  cliente, esteja fora do horário de
                  funcionamento</label>
                <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="item.mensagemAuxiliar"
                  class="p-inputtext-sm"></textarea>
              </div>

              <div *ngFor="let horario of item.horarios" class="row">

                <div class="col-md-3">
                  <input pInputText disabled class="p-inputtext-sm" [(ngModel)]="horario.diaSemana" />
                </div>

                <div class="p-fluid col-2">
                  <input pInputText class="p-inputtext-sm" [(ngModel)]="horario.inicio" />
                  <!-- <p-calendar appendTo="body" [(ngModel)]="horario.inicio" [showTime]="true" [showSeconds]="true"
                    class="p-inputtext-sm" [showSeconds]="false" [timeOnly]="true" inputId="time"></p-calendar> -->
                </div>

                <div class="col-1">
                  Até.
                </div>

                <div class="p-fluid col-2">
                  <input pInputText class="p-inputtext-sm" [(ngModel)]="horario.fim" />
                  <!-- <p-calendar appendTo="body" [(ngModel)]="horario.fim" [showTime]="true" [showSeconds]="true"
                    class="p-inputtext-sm" dateFormat="hh:mm:ss" [showSeconds]="false" [timeOnly]="true" inputId="time"></p-calendar> -->
                </div>

                <div class="p-fluid col-2">
                  <p-checkbox [(ngModel)]="horario.validar" [binary]="true" inputId="binary"></p-checkbox>
                </div>

                <div class="col-12 mt-2">
                  <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="horario.mensagem"
                    class="p-inputtext-sm"></textarea>
                </div>



              </div>

            </div>


            <textarea *ngIf="item.tipo == 14" rows="5" cols="30" pInputTextarea [(ngModel)]="item.valor"
              class="p-inputtext-sm"></textarea>
            <small *ngIf="item.tipo == 14" class="badge badge-soft-danger">{{palavrasChaves}}</small>
          </div>

          <div class="col-sm-2 btns">

            <button *ngIf="item.tipo == 1" pTooltip="Dicionário
            de palavras chaves" pButton pRipple type="button" icon="pi pi-info-circle"
              class="p-button-rounded p-button-text mr-2" (click)="ag.toggle($event)"></button>


            <button type="button" class="btn btn-danger ml-2" ngbTooltip="Remover" (click)="deleteItem(item)"><i
                class="pi pi-times"></i></button>


            <p-overlayPanel #ag [showCloseIcon]="true" [style]="{width: '450px'}">
              <ng-template pTemplate>
                <p-table [value]="dicionario" responsiveLayout="scroll">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Dica</th>
                      <th>Descrição</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-item>
                    <tr>
                      <td>{{item.dica}}</td>
                      <td>{{item.descricao}}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </ng-template>
            </p-overlayPanel>
          </div>
          <hr>
        </div>
      </div>
    </p-tabPanel>




  </p-tabView>



</div>

<div *ngIf="loading" class="text-center">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
    fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div>
