<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
    [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" [loading]="loading" scrolldirection="both"
    [autoLayout]="true" [globalFilterFields]="['nome']" [rowHover]="true">

    <ng-template pTemplate="caption">
        <p-toolbar>
            <div class="p-toolbar-group-left">
                <div class="p-input-icon-left p-fluid p-field p-mr-2">
                    <input pInputText type="text" class="p-inputtext-sm ml-4"
                        (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
                </div>


            </div>

            <div class="p-toolbar-group-right d-flex">

                <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2"
                    style="margin-right: 5px;" (click)="exportExcel()" ngbTooltip="Exportar para excel"><i
                        class="pi pi-file-export"></i></button>

                <button type="button" class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
                        class="pi pi-plus"></i></button>
            </div>
        </p-toolbar>
    </ng-template>


    <ng-template pTemplate="header">
        <tr c>
            <th>Código</th>
            <th>Nome</th>
            <th>Ativo</th>
            <th style="max-width:100px;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr class="row-test">
            <td>{{item.id}}</td>
            <td>{{item.nome}}</td>
            <td>{{item.ativo ? 'Sim' : 'Não'}}</td>
            <td style="max-width:100px;">
                <button type="button" class="btn btn-primary btn-sm mr-2" (click)="showForm(item.id)"
                    ngbTooltip="Editar"><i class="pi pi-pencil"></i></button>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
        <tr>
            <td>
                Total de {{dataSource ? dataSource.length : 0 }} registros.
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog header="Funil de atendimento" [(visible)]="modal" [style]="{width: '50vw'}">
    <div *ngIf="!loading">
        <form [formGroup]="form" class="row p-fluid p-grid p-formgrid mb-4">

            <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
                    <label class="form-check-label" for="customCheck2">Ativo</label>
                </div>
            </div>


            <div class="col-sm-12">
                <label for="lastname2">Nome</label>
                <input pInputText class="form-control p-inputtext-sm" formControlName="nome" />
                <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
            </div>

            <div class="p-col-12 p-col p-field">
                <label for="lastname2">Departamentos</label>
                <p-multiSelect [options]="departamentoList" formControlName="departamentos" optionLabel="nome"></p-multiSelect>
            </div>
        </form>

        <p-panel>
            <ng-template pTemplate="header">
                <div class="d-flex align-items-center ">
                    <h5 class="mr-2" style="margin-right: 20px; margin-top: 5px;">Etapas do funil</h5>
                    <button type="button" class="btn btn-primary btn-sm ml-4 mr-2" (click)="addEtapa()"
                        ngbTooltip="Editar">
                        <i class="pi pi-plus"></i>
                    </button>
                </div>
            </ng-template>


            <div *ngFor="let item of etapasList" class="row mt-4">

                <div class="col-md-1 mb-12 p-fluid" style="margin-top: 30px;">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck2" [(ngModel)]="item.ativo">
                        <!-- <label class="form-check-label" for="customCheck2">Ativo</label> -->
                    </div>
                </div>

                <div class="col-sm-4">
                    <label for="lastname2">Nome</label>
                    <input pInputText class="form-control p-inputtext-sm" [(ngModel)]="item.nome" />
                </div>

                <div class="col-sm-2">
                    <label for="lastname2">ordem</label>
                    <input pInputText class="form-control p-inputtext-sm" [(ngModel)]="item.ordem" />
                </div>


            </div>

            <!-- <ng-template pTemplate="footer">
                Footer content here
            </ng-template> -->
        </p-panel>
    </div>

    <div class="text-center">
        <p-progressSpinner *ngIf="loading" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
            strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>

    <ng-template pTemplate="footer">

        <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
            ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

        <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
                class="pi pi-save"></i></button>
    </ng-template>
</p-dialog>