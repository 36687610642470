import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import * as FileSaver from 'file-saver';
import { DireitoService } from 'src/app/core/services/direito.service';
import moment from 'moment';
import { IntegracaoWhatsappService } from 'src/app/core/services/integracao-whatsapp.service';
import Quill from 'quill';

@Component({
  selector: 'app-departamentos',
  templateUrl: './departamentos.component.html',
  styleUrls: ['./departamentos.component.scss']
})
export class DepartamentosComponent {
  dataSource = [];
  modal = false;
  form: FormGroup;
  superiores = [];
  arrayHorarioFuncionamento = [];

  arrayDiasSemana = [
    { value: 1, label: 'Segunda' },
    { value: 2, label: 'Terça' },
    { value: 3, label: 'Quarta' },
    { value: 4, label: 'Quinta' },
    { value: 5, label: 'Sexta' },
    { value: 6, label: 'Sabado' },
    { value: 7, label: 'Domingo' },
  ]

  dropdownCanalAtendimento = [];
  configuracao;


  constructor(
    private departamentoService: DepartamentoService,
    private formBuilder: FormBuilder,
    public direitoService: DireitoService,
    public canalAtendimentoService: IntegracaoWhatsappService,
  ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      ativo: [true],
      departamento_superior_id: [null],
      disponivel_bot: [true],
      habilitar_horario_funcionamento: [false],
      canal_atendimento_id: [null],
    });

    this.index();

    this.listCanalAtendimentos();

    this.addHorarioFuncionamento();

    this.configuracao = JSON.parse(localStorage.getItem('configuracao'));

   
  }

  addHorarioFuncionamento() {
    this.arrayHorarioFuncionamento.push({
      dia_semana: null,
      abertura: null,
      fechamento: null
    })
  }

  index() {
    this.departamentoService.index().subscribe(departamentos => {
      this.dataSource = departamentos;

      this.superiores = [];
      this.superiores.push({ value: '', label: 'Selecione' });

      departamentos.forEach(departamento => {
        if (departamento.ativo) {
          this.superiores.push({
            value: departamento.id,
            label: departamento.nome,
          })
        }
      });
    })
  }

  listCanalAtendimentos() {
    this.canalAtendimentoService.index().subscribe(json => {
      this.dropdownCanalAtendimento = json.map(item => { return { value: item.id, label: `${item.whatsapp} - ${item.identificador}` } });
    })
  }

  showForm(id = null) {
    this.form.reset();
    this.form.get('ativo').setValue(true);
    this.form.get('disponivel_bot').setValue(true);
    this.modal = true;

    if (id != null) {
      this.form.disable();
      this.departamentoService.show(id).subscribe(departamento => {
        this.form.enable();
        this.form.patchValue(departamento);

        departamento.horario_funcionamento.forEach(element => {
          var timeStr = element.abertura;
          var timeParts = timeStr.split(':');
          var hours = parseInt(timeParts[0], 10);
          var minutes = parseInt(timeParts[1], 10);

          var date = new Date();
          date.setHours(hours);
          date.setMinutes(minutes);
          element.abertura = date;

          var timeStr = element.fechamento;
          var timeParts = timeStr.split(':');
          var hours = parseInt(timeParts[0], 10);
          var minutes = parseInt(timeParts[1], 10);

          var date = new Date();
          date.setHours(hours);
          date.setMinutes(minutes);
          element.fechamento = date;
        });
        this.arrayHorarioFuncionamento = departamento.horario_funcionamento
      })
    }

  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "departamentos");
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  save() {

    if (this.form.get('habilitar_horario_funcionamento').value && this.arrayHorarioFuncionamento.length > 0) {
      this.arrayHorarioFuncionamento.map(item => {
        item.abertura = moment(item.abertura).format("HH:mm");
        item.fechamento = moment(item.fechamento).format("HH:mm");
      })
    }

    let body: any = {
      departamento: this.form.value,
      horario_funcionamento: this.arrayHorarioFuncionamento
    }

    this.departamentoService.save(body, body.departamento.id).subscribe(json => {
      this.modal = false;
      this.index();
    })
  }

  removeHorarioFuncionamento(item) {
    let horarios = this.arrayHorarioFuncionamento.filter(h => { return h != item });
    this.arrayHorarioFuncionamento = horarios;
  }
}
