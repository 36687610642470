import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GrupoUsuarioService } from 'src/app/core/services/grupo-usuario.service';

@Component({
  selector: 'app-grupo-usuario',
  templateUrl: './grupo-usuario.component.html',
  styleUrls: ['./grupo-usuario.component.css']
})
export class GrupoUsuarioComponent implements OnInit {
  dataSource = [];

  form: FormGroup;

  modal = false;

  constructor(
    public formBuilder: FormBuilder,
    public grupoUsuarioService: GrupoUsuarioService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required]
    });

    this.listAll();
  }

  listAll() {
    this.grupoUsuarioService.index().subscribe(json => {
      this.dataSource = json;
    })
  }


  showForm(id = null) {
    this.modal = true;
    this.form.reset();
    if (id) {
      this.grupoUsuarioService.show(id).subscribe(json => {
        this.form.patchValue(json);
      })
    }
  }

  save() {
    
    let body = {
      nome: this.form.get('nome').value
    }

    this.grupoUsuarioService.save(body, this.form.get('id').value).subscribe(json => {
      this.listAll();
      this.modal = false;
    })
  }

}
