<div class="row">
    <div class="card">
        <div class="card-body">
            <div class="row justify-content-between">
                <div class="col-md-3">
                    <div class="text-md-start mt-3 mt-md-0">

                        <button [disabled]="form.invalid || direitoAlterar == undefined || !validaUsuario()"
                            type="button" class="btn btn-success waves-effect waves-light me-1" (click)="save()"><i
                                class="fa fa-save"></i> Salvar</button>
                        <button *ngIf="form.get('id').value" type="button"
                            class="btn btn-success waves-effect waves-light me-1" (click)="qrCode()"><i></i> Ler QR
                            Code</button>

                        <button *ngIf="form.get('id').value" type="button"
                            class="btn btn-danger waves-effect waves-light me-1" (click)="resetInstance()"><i></i>
                            Reiniciar Instância</button>

                        <!-- <a class="btn btn-danger waves-effect waves-light me-1"
                            [routerLink]="['/apps/canal-atendimento/lista']"><i class="fa fa-times"></i> Voltar</a> -->
                    </div>
                </div><!-- end col-->
            </div> <!-- end row -->
        </div>
    </div> <!-- end card -->
</div>

<div *ngIf="!loading" class="row">
    <div class="card">
        <h5>Dados do canal</h5>
        <div class="card-body">
            <form [formGroup]="form">
                <div *ngIf="desconectado == false && form.get('id').value != null" class="alert alert-success mb-4"
                    role="alert">
                    Online
                </div>

                <div *ngIf="desconectado == true && form.get('id').value != null" class="alert alert-danger mb-4"
                    role="alert">
                    Offline
                </div>

                <div class="p-fluid row">

                    <div class="col col-md-12 mb-4">
                        <span class="p-float-label">
                            <input pInputText formControlName="nome" class="p-inputtext-sm" />
                            <label for="lastname2">Nome</label>
                        </span>
                    </div>

                    <div class="col col-md-3 mb-4">
                        <span class="p-float-label">
                            <input pInputText formControlName="identificador" class="p-inputtext-sm" />
                            <label for="lastname2">Identificador</label>
                        </span>
                    </div>

                    <div class="col col-md-3 mb-4">
                        <span class="p-float-label">
                            <input pInputText formControlName="token" class="p-inputtext-sm" />
                            <label for="lastname2">Token</label>
                        </span>
                    </div>

                    <div class="col col-md-3 mb-4">
                        <span class="p-float-label">
                            <input readonly pInputText formControlName="whatsapp" class="p-inputtext-sm" />
                            <label for="lastname2">N° whatsapp</label>
                        </span>
                    </div>

                    <div class="col col-md-3 mb-4 form-check">
                        <input class="form-check-input" type="checkbox" binary="true" value="true" id="flexCheckDefault"
                            formControlName="habilitar_bot" (change)="enableBot()">
                        <label class="form-check-label" for="flexCheckDefault">
                            Habilitar BOT
                        </label>
                    </div>

                    <div class="col col-md-6 mb-4">
                        <span class="p-float-label">
                            <input pInputText formControlName="url_typebot" class="p-inputtext-sm" />
                            <label for="lastname2">URL Typebot</label>
                        </span>
                    </div>

                    <div class="col col-md-6 mb-4">
                        <span class="p-float-label">
                            <input pInputText formControlName="codigo_typebot" class="p-inputtext-sm" />
                            <label for="lastname2">Código Typebot</label>
                        </span>
                    </div>

                    <div *ngIf="configuracao.permite_enviar_mensagem_externa == true" class="col col-md-12 mb-4">
                        <label for="lastname2">Departamento a ser vinculado quando for enviado uma mensagem fora da
                            plataforma!</label>
                        <p-dropdown filter="true" placeholder="Selecione" [options]="dropdownDepartamentos"
                            formControlName="departamento_id"></p-dropdown>
                    </div>

                </div>
            </form>
        </div>


        <div *ngIf="qrcode" class="col col-md-12 d-flex justify-content-center" style="display: flex;
                            align-items: center;">
            <div class="_2WuPw">
                <div class="landing-title _3-XoE">Para usar o Whatsapp no Bkchat:</div>
                <ol class="_2A31C">
                    <li class="QtrYx">Abra o Whatsapp em seu aparelho</li>
                    <li class="QtrYx"><span dir="ltr" class="i0jNr">Toque <strong><span dir="ltr" class="i0jNr">Menu
                                    <span class="_30yMe"><svg height="24px" viewBox="0 0 24 24" width="24px">
                                            <rect fill="#f2f2f2" height="24" rx="3" width="24">
                                            </rect>
                                            <path
                                                d="m12 15.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0-2c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm0-5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5z"
                                                fill="#818b90"></path>
                                        </svg></span></span></strong> ou <strong><span dir="ltr"
                                    class="i0jNr">Configurações <span class="_30yMe"><svg width="24" height="24"
                                            viewBox="0 0 24 24">
                                            <rect fill="#F2F2F2" width="24" height="24" rx="3">
                                            </rect>
                                            <path
                                                d="M12 18.69c-1.08 0-2.1-.25-2.99-.71L11.43 14c.24.06.4.08.56.08.92 0 1.67-.59 1.99-1.59h4.62c-.26 3.49-3.05 6.2-6.6 6.2zm-1.04-6.67c0-.57.48-1.02 1.03-1.02.57 0 1.05.45 1.05 1.02 0 .57-.47 1.03-1.05 1.03-.54.01-1.03-.46-1.03-1.03zM5.4 12c0-2.29 1.08-4.28 2.78-5.49l2.39 4.08c-.42.42-.64.91-.64 1.44 0 .52.21 1 .65 1.44l-2.44 4C6.47 16.26 5.4 14.27 5.4 12zm8.57-.49c-.33-.97-1.08-1.54-1.99-1.54-.16 0-.32.02-.57.08L9.04 5.99c.89-.44 1.89-.69 2.96-.69 3.56 0 6.36 2.72 6.59 6.21h-4.62zM12 19.8c.22 0 .42-.02.65-.04l.44.84c.08.18.25.27.47.24.21-.03.33-.17.36-.38l.14-.93c.41-.11.82-.27 1.21-.44l.69.61c.15.15.33.17.54.07.17-.1.24-.27.2-.48l-.2-.92c.35-.24.69-.52.99-.82l.86.36c.2.08.37.05.53-.14.14-.15.15-.34.03-.52l-.5-.8c.25-.35.45-.73.63-1.12l.95.05c.21.01.37-.09.44-.29.07-.2.01-.38-.16-.51l-.73-.58c.1-.4.19-.83.22-1.27l.89-.28c.2-.07.31-.22.31-.43s-.11-.35-.31-.42l-.89-.28c-.03-.44-.12-.86-.22-1.27l.73-.59c.16-.12.22-.29.16-.5-.07-.2-.23-.31-.44-.29l-.95.04c-.18-.4-.39-.77-.63-1.12l.5-.8c.12-.17.1-.36-.03-.51-.16-.18-.33-.22-.53-.14l-.86.35c-.31-.3-.65-.58-.99-.82l.2-.91c.03-.22-.03-.4-.2-.49-.18-.1-.34-.09-.48.01l-.74.66c-.39-.18-.8-.32-1.21-.43l-.14-.93a.426.426 0 00-.36-.39c-.22-.03-.39.05-.47.22l-.44.84-.43-.02h-.22c-.22 0-.42.01-.65.03l-.44-.84c-.08-.17-.25-.25-.48-.22-.2.03-.33.17-.36.39l-.13.88c-.42.12-.83.26-1.22.44l-.69-.61c-.15-.15-.33-.17-.53-.06-.18.09-.24.26-.2.49l.2.91c-.36.24-.7.52-1 .82l-.86-.35c-.19-.09-.37-.05-.52.13-.14.15-.16.34-.04.51l.5.8c-.25.35-.45.72-.64 1.12l-.94-.04c-.21-.01-.37.1-.44.3-.07.2-.02.38.16.5l.73.59c-.1.41-.19.83-.22 1.27l-.89.29c-.21.07-.31.21-.31.42 0 .22.1.36.31.43l.89.28c.03.44.1.87.22 1.27l-.73.58c-.17.12-.22.31-.16.51.07.2.23.31.44.29l.94-.05c.18.39.39.77.63 1.12l-.5.8c-.12.18-.1.37.04.52.16.18.33.22.52.14l.86-.36c.3.31.64.58.99.82l-.2.92c-.04.22.03.39.2.49.2.1.38.08.54-.07l.69-.61c.39.17.8.33 1.21.44l.13.93c.03.21.16.35.37.39.22.03.39-.06.47-.24l.44-.84c.23.02.44.04.66.04z"
                                                fill="#818b90"></path>
                                        </svg></span></span></strong> e selecione
                            <strong>Aparelhos
                                conectados</strong></span></li>

                    <li class="QtrYx"><span dir="ltr" class="i0jNr">Toque em <strong>Aparelhos
                                conectados</strong></span></li>

                    <li class="QtrYx">Aponte seu telefone para esta tela para capturar o código
                    </li>
                </ol>
            </div>
            <img *ngIf="qrcode != 'change'" width="264" height="264" src="{{qrcode}}">
            <i *ngIf="qrcode == 'change'" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>

        </div>



    </div>
</div>