import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ChatService } from 'src/app/core/services/chat.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import { DireitoService } from 'src/app/core/services/direito.service';
import { PessoaService } from 'src/app/core/services/pessoa.service';
import { TagService } from 'src/app/core/services/tag.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-info-chat',
  templateUrl: './info-chat.component.html',
  styleUrls: ['./info-chat.component.scss'],
  providers: [MessageService]
})
export class InfoChatComponent {
  @Input() chatSelected:any = null;
  @Input() camposPessoaChat: any[];
  @Input() outros_departamentos: any[];
  @Input() outros_responsaveis: any[];

  @Output() onTagUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onDepartamentoUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onUsuarioUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onSaveDadoPessoa: EventEmitter<any> = new EventEmitter();



  dropdownTags = [];
  tagsSelected = [];
  dropdownUsers = [];
  dropdownOtherUsers = [];
  dropdownDepartamentos = [];
  load = false;
  loadingUsers = false;
  tag;
  departamentoId;
  usuarioId;

  dropdownOutrosDepartamentos =[];
  dropdownOutrosResponsaveis = [];

  usuarioLogado = JSON.parse(localStorage.getItem('user'));

  @Input() codigoCliente = null;

  constructor(
    private tagService: TagService,
    private pessoaService: PessoaService,
    private usuarioService: UsuarioService,
    private chatService: ChatService,
    public direitoService: DireitoService,
    public departamentoService: DepartamentoService,
    protected messageService: MessageService
  ) {

  }


  ngOnInit(): void {
    this.camposPessoaChat = [];
    // this.listCamposContato();   
    this.listTags(); 
    this.listDepartamentos();
  }



  listDepartamentos() {
    if (!this.chatSelected) {
      return;
    }

    this.dropdownDepartamentos = [{ value: null, label: 'Nenhum departamento vinculado' }];

    let direitos = JSON.parse(localStorage.getItem('direito'));
    let usuario = JSON.parse(localStorage.getItem("user"));
    let requests = [];

    let visualizarTodosChat = direitos.find(d => {return d.flag == 'VISUALIZAR_TODOS_CHAT'});
    if (visualizarTodosChat != undefined) {
      requests.push(this.departamentoService.index());
    } else {
      requests.push(this.usuarioService.departamentos(usuario.id));
    }

    forkJoin(requests).subscribe(json => {      
      let departamentos = json[0];
      departamentos.map(item => {
        let departamentoIn = this.dropdownDepartamentos.find(d => {return d.value == item.id});
        if (departamentoIn == undefined) {
          this.dropdownDepartamentos.push({ value: item.id, label: `${item.nome}` });        
        }
      });
      this.departamentoId = this.chatSelected?.departamento_id;
      
      requests = [];
      let alterarAtendente = direitos.find(d => {return d.flag == 'ALTERAR_ATENDENTE_CHAT'}); 
      if (alterarAtendente != undefined) {
        requests.push(this.departamentoService.index());
      } else {
        requests.push(this.usuarioService.departamentos(usuario.id));
      }

      forkJoin(requests).subscribe(json => {
        this.dropdownOutrosDepartamentos = [];
        json[0].map(item => {
          let departamentoIn = this.dropdownOutrosDepartamentos.find(d => {return d.value == item.id});
          if (departamentoIn == undefined) {
            this.dropdownOutrosDepartamentos.push({ value: item.id, label: `${item.nome}` });        
          }
        });

        this.outros_departamentos = [];        
        this.chatService.getOthersDep(this.chatSelected.id).subscribe(json => {
          json.map(item => {
            this.outros_departamentos = [...this.outros_departamentos, item.departamento_id];
            this.listOutrosUsuarios(item.departamento_id);
          });
        })
      })      

      this.listUsers();
    });
  }


  listCamposContato() {
    this.camposPessoaChat = [];

    if (!this.codigoCliente) {
      return;
    } 

    this.pessoaService.listCamposChat(this.codigoCliente, this.usuarioLogado.departamento_id).subscribe(json => {

      json.map(item => {

        let personalizado = true;

        if (item.campo == 'CPF' || item.campo == 'Nome' || item.campo == 'Email') {
          personalizado = false;
        }

        this.camposPessoaChat.push({
          tipo: item.tipo,
          campo: item.campo,
          campo_personalizado_id: item.campo_personalizado_id,
          value: item.value,
          personalizado: personalizado,
          opcoes: item?.opcoes || [],
          editavel: item?.editavel || true
        });
      })

      console.log(this.camposPessoaChat);
    })
  }

  listUsers() {
    this.loadingUsers = true;

    this.dropdownUsers = [];
    this.dropdownUsers = [{ value: null, label: 'Nenhum responsável informado' }]

    if (this.departamentoId == null) {
      return;
    }

    this.usuarioService.listByDepartamento(this.departamentoId).subscribe(users => {
      
      this.loadingUsers = false;

      if (users.length > 0) {

        users.map(user => {
          this.dropdownUsers.push({ value: user.id, label: `${user.name}` });
        });

         this.usuarioId = this.chatSelected.usuario_atendimento_atual_id;
      }
    })
  }

  listOutrosUsuarios(departamentoId = null) {
    this.dropdownOtherUsers = [];

    this.usuarioService.listByDepartamento(departamentoId).subscribe(users => {
      if (users.length > 0) {
        users.map(user => {
          this.dropdownOtherUsers.push({ value: user.id, label: `${user.name}` });
        });

        this.outros_responsaveis = []
        this.chatService.getOthersRes(this.chatSelected.id).subscribe(json => {
          json.map(item => {
            let teste = this.outros_responsaveis.find(x => {return x == item.usuario_responsavel_id});
            if (teste == undefined) {
              this.outros_responsaveis = [...this.outros_responsaveis, item.usuario_responsavel_id];
            }
          });
        })
      }
    })
  }

  listTags() {
    this.tagService.index().subscribe(json => {
      this.dropdownTags = json;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.camposPessoaChat = [];

    if (this.chatSelected != null) {
      this.codigoCliente =  this.chatSelected['codigoCliente'];
    }

    this.listCamposContato();  
    this.getTagsPerson();
    this.listDepartamentos();
  }

  getTagsPerson() {
    if (!this.chatSelected) {
      return;
    }

    this.pessoaService.show(this.chatSelected.codigoCliente).subscribe(json => {
      this.tagsSelected = json.tags;
    })
  }

  selectTag() {
    let isExists = this.tagsSelected.find(t => { return t.id == this.tag?.id });
    if (isExists == undefined) {
      this.load = true;

      this.tagsSelected.push(this.tag);


      let body = {
        pessoa: {
          id: this.chatSelected.codigoCliente,
        },
        chatId: this.chatSelected.id,
        tags: [this.tag.id]
      }

      this.tag = null;

      this.pessoaService.linkTag(body).subscribe(json => {
        this.onTagUpdate.emit();
        this.load = false;
      })
    }
  }

  removeTag(tag) {
    this.load = true;

    let tags = this.tagsSelected.filter(t => { return t.id != tag.id });
    this.tagsSelected = tags;

    this.pessoaService.removeTag(this.chatSelected.id, this.chatSelected.codigoCliente, tag.id).subscribe(json => {
      this.load = false;
      this.onTagUpdate.emit()
    })
  }

  updateUsuario() {
    this.load = true;

    // let body = {
    //   chat_id: this.chatSelected.id,
    //   usuarioId: this.chatSelected.usuario_atendimento_atual_id,
    //   situacao: 0,
    //   changeUser: true
    // }

    this.chatService.updateAtendente(this.chatSelected.id, this.usuarioId).subscribe(json => {
      this.load = false;
      this.onUsuarioUpdate.emit();
    }, err => {

    })
  }

  updateDepartamentoChat() {
    this.chatService.linkDepartamento(this.chatSelected['id'], this.departamentoId).subscribe(json => {
      this.load = false;
      this.listUsers();
      this.onDepartamentoUpdate.emit();
    }, err => {

    })
  }

  saveDadoPessoa(item) {
    let body:any = {
      pessoaId: this.codigoCliente,
      campo: item['campo'],
      campoId: item['campo_personalizado_id'],
      value: item['value'],
      personalizado: item['personalizado'],
    }

    if (this.chatSelected != null) {
      body = {...body, chatId: this.chatSelected.id}
    }

    this.pessoaService.saveDadosChat(body).subscribe(json => {
      this.onSaveDadoPessoa.emit(item);
    });
  }

  saveOpcaoValuePessoa(item) {

    let body = {
      pessoaId: this.chatSelected['codigoCliente'],
      opcaoId: item['id'],
      opcaoValue: item['opcaoValorPessoa']['value'],
    }

    this.pessoaService.saveOpcaoCampoValue(body).subscribe(json => {
      this.onSaveDadoPessoa.emit({campo: item.label});
    });
  }

  updateResponsaveisChat() {

    if (this.outros_responsaveis.length == 0) {
      return;
    }

    let responsaveis: any[] = [];

    this.outros_responsaveis.map(item => {
      responsaveis.push(item)
    })

    let params = {
      responsaveis: responsaveis
    }

    this.chatService.saveOthersRes(this.chatSelected.id, params).subscribe()
  }

  updateDepartamentosChat() {

    let departamentos: any[] = []
    this.outros_departamentos.map(item => {
      departamentos.push(item)
    })

    let params = {
      departamentos: departamentos
    }
    this.chatService.saveOthersDep(this.chatSelected.id, params).subscribe(json => {
      this.outros_departamentos.map(item => {
        this.listOutrosUsuarios(item);
      })
      
    })


  }
}
