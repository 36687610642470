import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CarteiraClienteService } from 'src/app/core/services/carteira-cliente.service';
import { DireitoService } from 'src/app/core/services/direito.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-carteira-cliente',
  templateUrl: './carteira-cliente.component.html',
  styleUrls: ['./carteira-cliente.component.css']
})
export class CarteiraClienteComponent implements OnInit {
  dataSource: [] = [];

  form: FormGroup;

  modal: boolean = false;

  dropdownUsuarios = [];

  loading = false;

  constructor(
    public direitoService: DireitoService,
    public carteiraClienteService: CarteiraClienteService,
    public formBuilder: FormBuilder,
    public usuarioService: UsuarioService,
  ) { }

  ngOnInit() {
    this.findAll();
    this.createForm();
    this.findAllUsuarios();
  }

  createForm() {
    if (!this.form) {
      this.form = this.formBuilder.group({
        id: [null],
        nome: [null, Validators.required],
        observacao: [null],
        ativo: [null],
        responsaveis: [null],
      });
    }

    this.form.reset();
  }

  showForm(id = null) {
    this.modal = true;
    this.createForm();
    this.findAllUsuarios();

    if (id) {
      this.loading = true;
      this.carteiraClienteService.show(id).subscribe(json => {
        this.loading = false;

        this.form.patchValue({
          ...json,
          responsaveis: json.responsaveis.length > 0 ? json.responsaveis[0]['id'] : null // Assumindo que você quer apenas os IDs
        });
      })
    }
  }

  exportExcel() {

  }

  findAll() {
    this.loading = true;
    this.carteiraClienteService.index().subscribe(json => {
      this.loading = false;
      this.dataSource = json;
    })
  }

  findAllUsuarios() {
    this.usuarioService.index().subscribe(json => {
      this.dropdownUsuarios =  json.map((usuario: any) => ({value: usuario.id, label: usuario.name}));
    })
  }

  save() {
    this.loading = true;

    let body = {
      nome: this.form.get('nome').value,
      observacao: this.form.get('observacao').value,
      responsaveis: [
        {usuario_id: this.form.get('responsaveis').value}
      ]
    }

    this.carteiraClienteService.save(body, this.form.get('id').value).subscribe(json => {
      this.loading = false;
      this.findAll();
      this.modal = false;
    })
  }
}
