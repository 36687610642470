import { Component, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from '../core/services/usuario.service';
import { IntegracaoWhatsappService } from '../core/services/integracao-whatsapp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OportunidadeEtapaService } from '../core/services/oportunidade-etapa.service';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { DepartamentoService } from '../core/services/departamento.service';

@Component({
  selector: 'app-instancia-whatsapp',
  templateUrl: './instancia-whatsapp.component.html',
  styleUrls: ['./instancia-whatsapp.component.scss']
})
export class InstanciaWhatsappComponent {
  interval: any;
  form!: FormGroup;
  qrcode: any
  desconectado: boolean = false
  @Input() instancia;
  @Output() onClose: EventEmitter<any> = new EventEmitter();


  dropdownTipoDistribuicaoLead: any[] = [
    { value: 0, label: 'Automaticamente para os consultores' },
    { value: 1, label: 'Para o usuário vinculado ao canal de atendimento' },
    { value: 2, label: 'Para um usuário específico' },
    { value: 3, label: 'Ir para a fila de novos contatos sem vinculo' },
  ];

  dropdownUsuarios: any[] = [
    { value: '', label: 'Selecione' },
  ];

  dropdownEtapas: any[] = [
    { value: '', label: 'Selecione' },
  ];
  direitoAlterar: any[] = [];

  loading: boolean = true;

  intervalUpdate;

  dropdownDepartamentos = [];

  configuracao;

  dropdownProvedorWebsocke = [
    {value: 0, label: 'Piesocket'},
    {value: 1, label: 'Bksoft'},
  ]

  usuarioLogado = JSON.parse(localStorage.getItem('user'));

  constructor(
    private _formBuilder: FormBuilder,
    private _integracaoWhatsappService: IntegracaoWhatsappService,
    private departamentoService: DepartamentoService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    let direitos = JSON.parse(localStorage.getItem('direito'));

    this.direitoAlterar = direitos.find(item => item.flag == 'CANAL_ATENDIMENTO_ALTERAR');

    this.configuracao = JSON.parse(localStorage.getItem('configuracao'));

    this.form = this._formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      token: [null, Validators.required],
      identificador: [null, Validators.required],
      whatsapp: [null],
      tipo_distribuicao_lead: ['0'],
      usuario_distribuicao_id: [null],
      etapa_negociacao_novo_atendimento: [null],
      tipo: ['ZAPI'],
      online: [null],
      habilitar_bot: [null],
      url_typebot: [null],
      codigo_typebot: [null],
      departamento_id: [null],
      provedor_websocket: [null],
    });

    if (this.desconectado == true && this.form.get('id').value != null) {
      this.qrCode();
    }

    this.listDepartamentos(); 
    this.websocket();
  }

  listDepartamentos() {
    this.departamentoService.index().subscribe(json => {
      this.dropdownDepartamentos = json.map(item => { return { value: item.id, label: item.nome } });
    })
  }

  websocket() {
    const window = this;
    let confWebsocket = JSON.parse(localStorage.getItem('confWebsocket')!);
    var apiKey = confWebsocket.api_key;
    var channelId = confWebsocket.canal;
    var piesocket = new WebSocket(`wss://${confWebsocket.cloud_id}.piesocket.com/v3/${channelId}?api_key=${apiKey}&notify_self`);

    var res = piesocket.onmessage = function (message) {
      try {
        let wss = JSON.parse(message.data);

        let subdominio;
        try {
          subdominio = localStorage.getItem('subdominio');
        } catch (error) {
          subdominio = null;
        }



        var userLogado = JSON.parse(localStorage.getItem('user'));

        if (subdominio !== null && wss.data?.subdominio == subdominio) {
          if (wss.data?.tipo && wss.data?.tipo == 'CONNECTED_QRCODE') {
            window.qrcode = null;
            window.desconectado = false
            clearInterval(window.interval);

            window.show();

          } else {
            window.desconectado = true
          }
        }

      } catch (error) {

      }
    }
  }

  validaUsuario() {
    let usuario = this.form.get('usuario_distribuicao_id')?.value;
    let tipoDistribuicao = this.form.get('tipo_distribuicao_lead')?.value;
    if (tipoDistribuicao == '2' && (usuario == null || usuario == '' || usuario == undefined)) {
      return false;
    } else {
      return true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.show();
  }

  show() {
    if (!this.instancia) {
      return;
    }

    this._integracaoWhatsappService.show(this.instancia.id).subscribe(json => {
      this.form.patchValue(json);
      this.loading = false;
      this._integracaoWhatsappService.connect(this.form.get('id').value).subscribe(json => {
        this.desconectado = !json.connected;
        this.qrcode = json.base64;

        if (this.desconectado) {
          this.form.get('whatsapp').setValue(null);
        } else {
          this.updateInstancia();
        }

        // this.updateInstancia();

        if (this.desconectado) {
          this.refreshData();
        }
      })
    })
  }

  qrCode() {
    this.qrcode = 'change';

    this._integracaoWhatsappService.connect(this.form.get('id').value).subscribe(json => {
      this.desconectado = !json.connected;
      this.qrcode = json.base64;

      if (this.desconectado) {
        this.form.get('whatsapp').setValue(null);
      } else {
        this.updateInstancia();
      }

      if (this.desconectado && !this.interval) {
        this.refreshData();
      }
    })
  }

  updateInstancia() {

    if (!this.desconectado) {

      this._integracaoWhatsappService.findInstacia(this.form.get('id').value).subscribe(json => {
        let owner;
        if (json?.ownerJid) {
          owner = json.ownerJid.split("@");
        } else {
          owner = json.instance.owner.split("@");
        }
        this.form.get('whatsapp').setValue(owner[0]);
        this._integracaoWhatsappService.save(this.form.value, this.form.get('id').value).subscribe();

      }, err => {

      })
    }



  }

  refreshData() {
    this.interval = setInterval(() => {

      if (this.desconectado == true) {
        this.qrcode = 'change'
      }

      this._integracaoWhatsappService.connect(this.form.get('id').value).subscribe(json => {
        this.desconectado = !json.connected;
        this.qrcode = json.base64;
      }, err => {

      })
    }, 60000);


  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      clearInterval(this.intervalUpdate);
    }
  }

  save() {
    this._integracaoWhatsappService.save(this.form.value, this.form.get('id')?.value).subscribe(json => {
      this.loading = false;
      // this._route.navigate([`/apps/canal-atendimento/lista`]);
      this.onClose.emit();
    }, err => {

    });
  }

  enableBot() {
    let bot = this.form.get('habilitar_bot').value;
    let body = {
      habilitar_bot: bot
    }
    this._integracaoWhatsappService.update(body, this.form.get('id').value).subscribe(json => {
      if (bot) {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: `BOT habilitado` });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Sucesso', detail: `BOT desabilitado` });
      }
    })
  }

  resetInstance() {
    let body = {
      id: this.form.get('id').value
    }
    this.loading = true;
    this._integracaoWhatsappService.recreate(body).subscribe(json => {
      this.loading = false;
      this.qrCode();
    }, err => {

    })
  }

}
