import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ReportPageComponent } from './report-page/report-page.component';
import { ReportAtendimentoComponent } from './report-atendimento/report-atendimento.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { ToolbarModule } from 'primeng/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportConversaComponent } from './report-conversa/report-conversa.component';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RankingAvaliacaoComponent } from './ranking-avaliacao/ranking-avaliacao.component';
import { ReportAtendimentoCamposPersonalizadosComponent } from './report-atendimento-campos-personalizados/report-atendimento-campos-personalizados.component';
import { ReportContatosComponent } from './report-contatos/report-contatos.component';
import { CheckboxModule } from 'primeng/checkbox';


@NgModule({
  declarations: [ReportPageComponent, ReportAtendimentoComponent, ReportConversaComponent, RankingAvaliacaoComponent, ReportAtendimentoCamposPersonalizadosComponent, ReportContatosComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ButtonModule,
    TableModule,
    TabViewModule,
    MultiSelectModule,
    CalendarModule,
    ToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    PerfectScrollbarModule,
    CheckboxModule
  ],
  exports: [ReportPageComponent]
})
export class ReportsModule { }
